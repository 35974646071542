import store from '../store/modules/edit'
import QRCode from 'qrcodejs2'
import fontList from '@/lib/fontList'
import _ from 'lodash'
const fontInfoMap = {}
fontList.forEach(item => {
  fontInfoMap[item.fontFamily] = item.url
})

/**
 * 画布转图片 使用SVG中的foreignObject转成图片
 */
class designToImage {
  constructor () {
    this.itemListInfo = {}
    this.fontMap = {}
  }

  init () {
    store.state.postList.forEach(post => {
      this.resetBack(post.background)
      post.images.forEach(image => {
        this.resetImage(image)
      })
      post.codes.forEach(code => {
        this.resetCode(code)
      })
      post.texts.forEach(text => {
        this.resetFontList(text)
      })
    })
  }

  loadFont (index) {
    const postInfo = _.cloneDeep(store.state.postList[index])
    let fontInfoList = []
    postInfo.texts.forEach(layer => {
      if (this.fontMap[layer.fontFamily]) {
        fontInfoList.push(layer.fontFamily)
      }
    })

    fontInfoList = [...new Set(fontInfoList)]
    let fontface = ''
    for (let index = 0; index < fontInfoList.length; index++) {
      const fontFamily = fontInfoList[index]
      fontface += `@font-face {
        font-family: ${fontFamily};
        src:url("data:application/font-woff;base64,${this.fontMap[fontFamily]}")
    }`
    }

    return `<style>${fontface}</style>`
  }

  resetFontList (text) {
    if (fontInfoMap[text.fontFamily] && !this.fontMap[text.fontFamily]) {
      const done = () => {
        if (request.readyState !== 4) return
        if (request.status !== 200) {
          return
        }
        /**
         * 读取文件信息
         */
        var encoder = new FileReader()
        encoder.onloadend = () => {
          var content = encoder.result.split(/,/)[1]
          this.fontMap[text.fontFamily] = content
        }
        encoder.readAsDataURL(request.response)
      }
      const timeout = () => {

      }
      var request = new XMLHttpRequest()
      request.onreadystatechange = done
      request.ontimeout = timeout
      request.responseType = 'blob'
      request.timeout = 3000
      request.open('GET', fontInfoMap[text.fontFamily], true)
      request.send()
    }
  }

  resetBack (back) {
    if (!this.itemListInfo[back.id]) {
      this.itemListInfo[back.id] = {
        base64: undefined,
        src: undefined
      }
    }
    const item = this.itemListInfo[back.id]
    if (back.backgroundImage == '') {
      item.base64 = undefined
      item.src = undefined
    } else {
      if (item.src != back.backgroundImage) {
        const image = new Image()
        image.setAttribute('crossOrigin', 'Anonymous')
        image.src = back.backgroundImage
        image.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.setAttribute('width', image.naturalWidth)
          canvas.setAttribute('height', image.naturalHeight)
          const ctx = canvas.getContext('2d')
          ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight)
          item.base64 = canvas.toDataURL('image/png')
          if (image.src.endsWith('jpg') || image.src.endsWith('JPG')) {
            item.base64 = canvas.toDataURL('image/jpeg')
          } else {
            item.base64 = canvas.toDataURL('image/png')
          }
          item.src = image.src
        }
      }
    }
  }

  resetImage (postImage) {
    if (!this.itemListInfo[postImage.id]) {
      this.itemListInfo[postImage.id] = {
        base64: undefined
      }
    }

    const item = this.itemListInfo[postImage.id]
    if (postImage.src == '') {
      item.base64 = undefined
      item.src = undefined
    } else {
      if (item.src != postImage.src) {
        const image = new Image()
        image.setAttribute('crossOrigin', 'Anonymous')
        image.src = postImage.src
        image.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.setAttribute('width', image.naturalWidth)
          canvas.setAttribute('height', image.naturalHeight)
          const ctx = canvas.getContext('2d')
          ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight)
          if (image.src.endsWith('jpg') || image.src.endsWith('JPG')) {
            item.base64 = canvas.toDataURL('image/jpeg')
          } else {
            item.base64 = canvas.toDataURL('image/png')
          }

          item.src = image.src
        }
      }
    }
  }

  resetCode (code) {
    if (!this.itemListInfo[code.id]) {
      this.itemListInfo[code.id] = {
        base64: undefined,
        url: undefined,
        width: undefined,
        height: undefined,
        colorDark: undefined
      }
    }
    const item = this.itemListInfo[code.id]
    if (item.url != code.url || item.width != code.width || item.width != code.width || item.height != code.height || item.colorDark != code.colorDark) {
      const dom = document.createElement('div')
      // eslint-disable-next-line no-new
      new QRCode(dom, {
        text: code.url,
        width: code.width - 10 > 1000 ? 1000 : code.width - 10,
        height: code.height - 10 > 1000 ? 1000 : code.height - 10,
        colorDark: code.colorDark, // 二维码颜色
        colorLight: '#ffffff', // 二维码背景色
        correctLevel: QRCode.CorrectLevel.L// 容错率，L/M/H
      })
      item.base64 = dom.querySelector('canvas').toDataURL('image/png')
      item.url = code.url
      item.width = code.width
      item.height = code.height
      item.colorDark = code.colorDark
    }
  }

  getBackDom (back, canvas) {
    let style = ''
    let image = ''
    style += `width:${canvas.width}px;height:${canvas.height}px;overflow:hidden;position:relative`
    if (back.backgroundColor != '') {
      style += `;background-color: ${back.backgroundColor}`
    }
    if (back.backgroundImage != '') {
      const imageStyle = `position:absolute;top:${-back.imageInfo.backgroundPositionY}px;left:${-back.imageInfo.backgroundPositionX}px;width:${back.width}px;height:${back.height}px`
      image += `<img src='${this.itemListInfo[back.id].base64}' style='${imageStyle}'></img>`
    }
    const svg = `<div style='${style}'>${image}</div>`
    return svg
  }

  getImageDom (postImage) {
    function shortBorder () {
      if (postImage.width < postImage.height) {
        return postImage.width
      } else {
        return postImage.height
      }
    }
    const boxShadow = postImage.boxShadow ? `drop-shadow(${postImage.shadow.hShadow}px ${postImage.shadow.vShadow}px ${postImage.shadow.blur}px ${postImage.shadow.color})` : 'none'
    const style = `width:${postImage.width}px;height:${postImage.height}px;
            overflow:hidden;position:absolute;left:${postImage.left}px;top:${postImage.top}px;
            z-index:${postImage.ZIndex};transform:rotate(${postImage.rotate ? postImage.rotate : 0}deg);
            filter:${boxShadow};opacity:${postImage.opacity / 100};border-Radius:${postImage.borderRadius * shortBorder() / 100}px`
    const imageStyle = ` position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            width:${postImage.showInfo.showWidth}px;
            height:${postImage.showInfo.showHeight}px;
    `
    const innerImageStyle = `
            position:absolute;left:${-postImage.showInfo.backgroundPositionX}px;top:${-postImage.showInfo.backgroundPositionY}px;
            width:${postImage.showInfo.backImageWidth}px;
            height:${postImage.showInfo.backImageHeight}px
            `
    const image = `<div style='${imageStyle}'><img src='${this.itemListInfo[postImage.id].base64}' style='${innerImageStyle}'></img></div>`
    const svg = `<div style='${style}'>${image}</div>`
    return svg
  }

  getTextDom (textInfo) {
    const fontScale = (function () {
      const fontSize = textInfo.fontSize
      if (fontSize < 12) {
        return fontSize / 12
      }
      return 1
    })()
    const height = fontScale != 1 ? textInfo.height * fontScale + 'px' : 'auto'
    const style = `position: absolute;display:inline-block;box-sizing: content-box;width: ${textInfo.width}px; height:${height}px;left:${textInfo.left}px;top:${textInfo.top}px;z-index:${textInfo.ZIndex};transform:rotate(${textInfo.rotate ? textInfo.rotate : 0}deg);letter-spacing:${textInfo.letterSpacing}px;font-size:${textInfo.fontSize}px;font-weight:${textInfo.bold ? 900 : 400};font-style:${textInfo.italic ? 'italic' : 'normal'};text-align:${textInfo.align};line-height:${textInfo.lineHeight}`
    const contentStyle = `transform-origin: 0px 0px;word-break:break-word;white-space:normal;opacity:${textInfo.opacity / 100};font-family:${textInfo.fontFamily};transform:scale(${fontScale});text-decoration:${textInfo.underline ? 'underline' : 'none'};color:${textInfo.color};width: ${textInfo.width / fontScale}px`
    const svg = `<div style='${style}'><div style='${contentStyle}'>${textInfo.html}</div></div>`
    return svg
  }

  getCodeDom (code) {
    const style = `left:${code.left}px;top:${code.top}px;z-index:${code.ZIndex};transform:rotate(${code.rotate ? code.rotate : 0}deg);
                width:${code.width}px;height:${code.height}px;position: absolute;`
    const svg = `<img src='${this.itemListInfo[code.id].base64}' style='${style}'></img>`
    return svg
  }

  getSvgByIndex (index) {
    const post = store.state.postList[index]
    const canvas = post.canvas
    const backDom = this.getBackDom(post.background, post.canvas)
    let imageDom = ''
    post.images.forEach(image => {
      imageDom += this.getImageDom(image)
    })
    let textDom = ''
    post.texts.forEach(text => {
      const dom = document.createElement('div')
      dom.innerHTML = this.getTextDom(text)
      textDom += new XMLSerializer().serializeToString(dom.children[0])
    })
    let codeDom = ''
    post.codes.forEach(code => {
      codeDom += this.getCodeDom(code)
    })
    const fontStyle = this.loadFont(index)
    // console.log(fontStyle)
    const svg = `<svg  width='${canvas.width}' height='${canvas.height}' xmlns='http://www.w3.org/2000/svg'><foreignObject x='0' y='0' width='${canvas.width}' height='${canvas.height}'><div xmlns='http://www.w3.org/1999/xhtml'>${fontStyle}${backDom}${imageDom}${textDom}${codeDom}</div></foreignObject></svg>`

    return svg
  }

  getAllSvg () {
    const svgList = []
    store.state.postList.forEach((item, index) => {
      svgList.push(this.getSvgByIndex(index))
    })
    return svgList
  }
}
const PostToImage = new designToImage()
// window.PostToImage = PostToImage
export default PostToImage
