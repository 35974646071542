import Request from '@/lib/Request'
import config from '@/config'
import tool from '../lib/tool'

// const httpadmin = new Request('http://192.168.1.65:9001/', 3000)
const httpadmin = new Request(process.env.VUE_APP_ADMIN)
// const http = new Request('http://192.168.1.80:9003/', 3000)
const http = new Request(process.env.VUE_APP_URL, 60 * 1000)

// 精选
export const queryExquisite = params => {
  return http.get('/cube-client/classification/queryExquisite', params)
}
// 分页查询平面模板
export const queryPage = params => {
  if (!tool.isEmpty(params.keywords)) {
    return http.post('/cube-client/planar/queryPageKeywords', params)
  } else {
    return http.post('/cube-client/planar/queryPage', params)
  }

}
// 通过id查询标签树
export const queryTree = id => {
  return http.get(`/cube-client/classification/queryTree/${id}`)
}
// 通过id查询标签树
export const queryTreeTags = param => {
  return http.post('/cube-client/tags/queryTree', param)
}
// 查询属性列表
export const attrQuery = param => {
  return http.post('/cube-client/planar/attr/query', param)
}
// 收藏
export const followAdd = param => {
  return http.post('/cube-client/planar/follow/add', param)
}
//取消收藏
export const followCancel = param => {
  return http.post('/cube-client/planar/follow/cancel', param)
}
// 查询平面模板
export const planargetone = param => {

  if (config.admin) {

    // params.status = 0
    return httpadmin.get('/cube-platform/planar/get/one', params)
  } else {
    return http.get('/cube-client/planar/get/one', param)
  }

}
// 新增个人平面模板
export const planarmineadd = param => {

  return http.post('/cube-client/planar/mine/add', param)
}
// 新增个人平面模板
export const planarjsonupdate = param => {

  return http.post('/cube-platform/planarjson/update', param)
}
// 批量新增
export const saveBatch = param => {
  return http.post('/cube-client/planarjson/saveBatch', param)
}
// 批量修改
export const updateBatch = param => {
  return http.post('/cube-client/planarjson/updateBatch', param)
}

// 个人模板修改
export const mineupdate = param => {
  return http.post('/cube-client/planar/mine/update', param)
}
// 个人模板修改
export const planarjsonget = param => {
  return http.get('/cube-client/planarjson/get', param)
}

// 个人模板修改
export const followqueryPage = param => {
  return http.post('/cube-client/planar/follow/queryPage', param)
}

// 新增个人平面模板表
export const userplanaradd = param => {
  return http.post('/cube-client/user/planar/add', param)
}
// 通过id查询
export const userplanargetone = param => {
  if (config.admin) {
    return httpadmin.get('/cube-platform/planar/get/one', param)
  } else {
    return http.get('/cube-client/user/planar/get/one', param)
  }


}
// 修改个人平面模板表
export const userplanargetupdate = param => {
  if (config.admin) {
    // params.status = 0
    return httpadmin.get('/cube-platform/planar/save', param)
  } else {
    return http.put('/cube-client/user/planar/update', {}, param)
  }

}

//新增用户图片-背景素材表
export const materialadd = param => {
  return http.post('/cube-client/user/material/add', param)
}
//新增用户图片-背景素材表
export const materialpage = param => {
  return http.post('/cube-client/user/material/page', param)
}

//生成封面图
export const drawBase64Cover = param => {
  return http.post('/cube-client/user/planar/drawBase64Cover', param)
}
//生成封面图
export const download = param => {
  return http.get('/cube-client/user/planar/download', param)
}
// 在线编辑器——获取最新封面PDF图
export const get = params => {
  return http.get('/cube-client/planar/image/get', params)
}

// 图片分类
export const materialclassification = params => {
  return http.post('/cube-client/material/classification/list', params)
}

// 图片查询
export const sysmaterialpage = params => {
  return http.post('/cube-client/sys/material/page', params)
}
// 图片查询
export const classificationqueryTree = params => {
  return http.post('/cube-client/classification/queryTree', params)
}

// 图片查询
export const getPrice = params => {
  return http.get('/cube-client/user/planar/getPrice', params)
}
// 提交订单
export const submit = params => {
  return http.post('/cube-client/order/planar/submit', params)
}
// 支付
export const pay = params => {
  return http.post('/cube-client/order/planar/pay', params)
}
// 检查是否购买
export const checkUserHave = params => {
  return http.get('/cube-client/planar/checkUserHave', params)
}

// 订单状态
export const checkOrderStatus = params => {
  return http.get('/cube-client/order/planar/checkOrderStatus', params)
}

// 查询用户已购买模板
export const queryUserBuyPage = params => {
  return http.post('/cube-client/user/planar/queryUserBuyPage', params)
}


// 分页查询
export const orderPage = params => {
  return http.post('/cube-client/temple/base/order/page', params)

}

// 申请开票
export const applyForInvoice = params => {
  return http.put('/cube-client/temple/base/order/applyForInvoice', params)
}
// 通过订单号查询
export const byOrderNo = params => {

  return http.get('/cube-client/temple/base/order/get/one/byOrderNo', params)
}

// 获取预览图
export const queryPreview = params => {

  return http.post('/cube-client/planar/image/queryPreview', params)
}


// 获取预览图
export const holidayquery = params => {

  return http.post('/cube-client/planar/holiday/query', params)
}


// 印刷订单查询
export const printorderqueryPage = params => {

  return http.post('/cube-client/order/print/order/queryPage', params)
}

// 印刷下单
export const printordersubmit = params => {

  return http.post('/cube-client/order/print/order/submit', params)
}

// 拉取支付码
export const printorderorderPayment = params => {
  return http.post('/cube-client/order/print/order/orderPayment', params)
}
// 检测订单状态
export const printordercheckStatus = params => {
  return http.post('/cube-client/order/print/order/checkStatus', params)
}

// 分页查询
export const printcartpage = params => {
  return http.post('/cube-client/order/print/cart/page', params)
}
// 添加购物车
export const printcartadd = params => {
  return http.post('/cube-client/order/print/cart/add', params)
}
// 通过id删除
export const printcartdelete = params => {
  return http.post('/cube-client/order/print/cart/delete', params)
}

// 申请开票
export const printorderapplyForInvoice = params => {
  return http.post('/cube-client/order/print/order/applyForInvoice', params)
}
// 申请开票
export const printordertakeDelivery = params => {
  return http.post('/cube-client/order/print/order/takeDelivery', params)
}

// 申请开票
export const orderdemandcancel = params => {
  return http.post('/cube-client/order/demand/cancel', params)
}


// 获取需求订单详情
export const orderdemandqueryPage = params => {
  return http.post('/cube-client/order/demand/queryPage', params)
}

// 提交需求订单
export const orderdemandsubmit = params => {
  return http.post('/cube-client/order/demand/submit', params)
}

// 查询分页（运营人员）
export const orderdemandoperatorsQueryPage = params => {
  return http.post('/cube-client/order/demand/operatorsQueryPage', params)
}

// 获取需求订单详情
export const orderdemandqueryDetail = params => {
  return http.post('/cube-client/order/demand/queryDetail', params)
}

// 获取需求订单详情
export const orderdemandupdate = params => {
  return http.post('/cube-client/order/demand/update', params)
}

// 拒绝需求单
export const orderdemandrefuse = params => {
  return http.post('/cube-client/order/demand/refuse', params)
}

// 查询待接单列表（商机大厅）
export const printorderqueryHallPage = params => {
  return http.post('/cube-client/order/print/order/queryHallPage', params)
}

// 商家接单
export const printrecevingrecevingOrder = params => {
  return http.post('/cube-client/order/print/receving/recevingOrder', params)
}

// 分页查询待确认订单
export const printrecevingqueryToBeConfirmPage = params => {
  return http.post('/cube-client/order/print/receving/queryToBeConfirmPage', params)
}

// 分页查询已取消订单
export const printrecevingqueryCancelPage = params => {
  return http.post('/cube-client/order/print/receving/queryCancelPage', params)
}



// 取消订单
export const printrecevingcancel = params => {
  return http.post('/cube-client/order/print/receving/cancel', params)
}
// 商家确认
export const printrecevingconfirm = params => {
  return http.post('/cube-client/order/print/receving/confirm', params)
}

// 分页查询已确认订单
export const printrecevingqueryConfirmedPage = params => {
  return http.post('/cube-client/order/print/receving/queryConfirmedPage', params)
}

// 商家发货
export const printrecevingdeliver = params => {
  return http.post('/cube-client/order/print/receving/deliver', params)
}




















