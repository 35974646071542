<template>
  <div class="menu-head">
    <div class="logo-img" @click="$router.push('/')">
      <img
        v-if="!isColor"
        src="../assets/img/index/logo_white.svg"
        class="logoImg"
      />
      <img
        v-if="isColor"
        src="../assets/img/index/logo_top.svg"
        class="logoImg logoImg-color"
      />
    </div>
    <ul class="menu-tap">
      <template v-if="showIndex">
        <div class="menuItem drawDown">
          产品
          <div class="downList">
            <div class="menu" @click="Jump('home')">
              <div class="label">营销活动</div>
              <div class="desc">轻松策划一场爆款活动</div>
            </div>
<!--            <div class="menu" @click="Jump('OnlineDesign')">-->
<!--              <div class="label">在线设计</div>-->
<!--              <div class="desc">快速输出个性化精美设计稿</div>-->
<!--            </div>-->
<!--            <div class="menu" @click="Jump('OnlinePrinting')">-->
<!--              <div class="label">在线印刷</div>-->
<!--              <div class="desc">设计稿在线一键下单</div>-->
<!--            </div>-->
            <div class="menu" @click="Jump('InfiniteQRCode')">
              <div class="label">二维码</div>
              <div class="desc">让创意与目的更有效地传播</div>
            </div>
          </div>
        </div>
        <div class="menuItem" style="cursor: not-allowed;">
          合作加盟
        </div>
        <div class="menuItem" style="cursor: not-allowed;">
          关于我们
        </div>
      </template>
      <template v-else>
        <router-link to="/home" tag="li" exact>首页</router-link>
        <router-link
          to="/templates"
          tag="li"

          @click.native="searchMarketingActivities"
        >模板</router-link>
        <router-link
          to="/ModalPrice"
          tag="li"

          @click.native="searchMarketingActivities"
        >价格</router-link>
      </template>
<!--      <router-link to="/" tag="li" exact>首页</router-link>-->
<!--      <router-link-->
<!--        to="/OnlineDesign"-->
<!--        tag="li"-->
<!--        v-if="!userInfo.roleIds.includes(3)"-->
<!--        @click.native="searchDesignClassAll"-->
<!--        >在线设计</router-link-->
<!--      >-->
<!--      <router-link-->
<!--        to="/MarketingActivities"-->
<!--        tag="li"-->
<!--        v-if="!userInfo.roleIds.includes(3)"-->
<!--        @click.native="searchMarketingActivities"-->
<!--        >营销活动</router-link>-->

<!--      <router-link-->
<!--        to="/OnlinePrinting"-->
<!--        tag="li"-->
<!--        v-if="!userInfo.roleIds.includes(3)"-->
<!--        @click.native="searchDispalyAll"-->
<!--        >在线印刷</router-link-->
<!--      >-->
<!--      <router-link-->
<!--        to="/InfiniteQRCode"-->
<!--        tag="li"-->
<!--        v-if="!userInfo.roleIds.includes(3) && !isProduction"-->
<!--        >无限二维码</router-link-->
<!--      >-->
<!--      <router-link to="/business" tag="li" v-if="userInfo.roleIds.includes(2)"-->
<!--        >商机大厅</router-link-->
<!--      >-->
    </ul>
    <div class="search-content">
      <div class="content" v-if="userInfo.roleIds && !userInfo.roleIds.includes(3)">
        <div
          class="search-class"
          @mouseenter="showDataList = true"
          @mouseleave="showDataList = false"
        >
          {{ searchPageDesc }}
          <i class="el-icon-caret-bottom"></i>
          <ul class="selecteList" v-show="showDataList">
<!--            <li class="selectitem" @click="searchDesignClassAll">在线设计</li>-->
            <li class="selectitem" @click="searchMarketingActivities">
              营销活动
            </li>
<!--            <li class="selectitem" @click="searchDispalyAll">在线印刷</li>-->
          </ul>
        </div>
        <div class="search-input">
          <input v-model="searchContent" />
          <i
            v-show="searchContent !== ''"
            class="el-icon-circle-close close-button"
            @click="searchContent = ''"
          ></i>
        </div>
        <div class="seachbutton" @click="search">
          <i class="el-icon-search"></i>
        </div>
      </div>
      <div class="user-info-list">
        <ul class="oper-list" v-if="isLogin">
          <li
            class="oper-list-item"
            @click="toPage( systemCode == 'tai_woo_wxys' ? '/marketingActivity/OwnActivity' : '/OwnWorkbench')"
            v-if="userInfo.roleIds.includes(1)"
          >
            <img class="pic" src="../assets/img/index/icon_workbench.png" />
            <img
              class="pic pic-color"
              src="../assets/img/index/icon_workbench_02.png"
              :class="{ active: isColor }"
            />
            我的工作台
          </li>

          <li class="oper-list-item" v-if="userInfo.roleIds.includes(2)">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link setColor">
                <img class="pic" src="../assets/img/index/icon_workbench.png" />
                <img
                  class="pic pic-color"
                  src="../assets/img/index/icon_workbench_02.png"
                  :class="{ active: isColor }"
                />
                我的工作台
                <i class="el-icon-arrow-down down"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="toPage('/OwnWorkbench')"
                  >个人工作台</el-dropdown-item
                >
                <el-dropdown-item
                  @click.native="toPage('/plantWorkbench/orderConfirm')"
                  >印刷厂工作台</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li
            class="oper-list-item"
            @click="toPage('/OperationWorkbench/DemandOrderList')"
            v-if="userInfo.roleIds.includes(3)"
          >
            <img class="pic" src="../assets/img/index/icon_workbench.png" />
            <img
              class="pic pic-color"
              src="../assets/img/index/icon_workbench_02.png"
              :class="{ active: isColor }"
            />
            我的工作台
          </li>
          <li
            class="oper-list-item"
            v-if="!userInfo.roleIds.includes(3)"
            @click="$router.push({ name: 'ShoppingCart' })"
          >
            <img class="pic" src="../assets/img/index/icon_shopping.png" />
            <img
              class="pic pic-color"
              src="../assets/img/index/icon_shopping_02.png"
              :class="{ active: isColor }"
            />
            购物车
          </li>
          <li
            class="oper-list-item myNews"
            @mouseenter="queryData"
            @click="toPage('/newsShow')"
          >
            <img class="pic" src="../assets/img/index/icon_message.png" />
            <img
              class="pic pic-color"
              src="../assets/img/index/icon_message_02.png"
              :class="{ active: isColor }"
            />
            消息
            <div class="list-box">
              <div class="news-list">
                <div class="list-title">
                  <el-badge :is-dot="noRead" class="item">站内信</el-badge>
                  <el-button
                    v-if="newsList.length > 0"
                    type="primary"
                    plain
                    size="mini"
                    @click="allRead"
                    >全部消息</el-button
                  >
                </div>
                <template v-if="newsList.length > 0">
                  <div
                    class="news-item"
                    :class="{
                      noRead: news.typeStatus == 0,
                      currentClick: currentClick == index,
                    }"
                    v-for="(news, index) of newsList"
                    :key="index"
                    @click="toPage('/newsShow')"
                  >
                    <div class="news-title">
                      <i class="el-icon-message"></i>
                      {{ JSON.parse(news.remark).title }}
                    </div>
                    <div class="news-time">
                      {{ JSON.parse(news.remark).issueTime | fTime }}
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div style="height: calc(100% - 30px); overflow: auto">
                    <listNull text="还没有消息呢~" />
                  </div>
                </template>
              </div>
            </div>
          </li>
        </ul>
        <div
          class="user-info-area"
          @mouseenter="showOwnInfo = true"
          @mouseleave="showOwnInfo = false"
          v-if="isLogin"
        >
          <img
            :src="userInfo.headImgUrl"
            alt=""
            class="headImgUrl"
            v-if="userInfo.headImgUrl"
          />
          <img
            src="https://imgtest-1257418739.cos.ap-guangzhou.myqcloud.com/userFile/512/2020-12-26/cc563b3e-ecb4-4b44-a1d8-b88a3164c4be.jpg"
            alt=""
            class="headImgUrl"
            v-else
          />
          <ul
            class="selecteList"
            v-show="showOwnInfo"
            v-if="!userInfo.roleIds.includes(3)"
          >
            <li class="userInfoShow">
              <div class="userImg">
                <img
                  :src="userInfo.headImgUrl"
                  alt=""
                  class="userHeadImg"
                  v-if="userInfo.headImgUrl"
                />
                <img
                  src="https://imgtest-1257418739.cos.ap-guangzhou.myqcloud.com/userFile/512/2020-12-26/cc563b3e-ecb4-4b44-a1d8-b88a3164c4be.jpg"
                  alt=""
                  class="userHeadImg"
                  v-else
                />
              </div>
              <div class="username">
                <h3>{{ userInfo.nickName }}</h3>
                <p v-if="userInfo.roleIds.includes(1)" class="userTypeText">
                  普通用户
                </p>
                <p v-if="userInfo.roleIds.includes(2)" class="userTypeText">
                  印刷厂用户
                </p>
                <p v-if="userInfo.roleIds.includes(3)" class="userTypeText">
                  运营用户
                </p>
              </div>
            </li>
            <li
              @click="toPage('/PersonalCenter/AccountInfo')"
              class="selectitem"
            >
              账号信息
            </li>
            <li
              @click="toPage('/PersonalCenter/CompanyInfo')"
              class="selectitem"
              v-if="userInfo.roleIds.includes(2)"
            >
              企业信息
            </li>
            <li
              @click="toPage('/PersonalCenter/PrintOrder')"
              class="selectitem"
            >
              我的订单
            </li>
            <li
              @click="toPage('/PersonalCenter/ReceivingAddress')"
              class="selectitem"
            >
              我的地址
            </li>
            <li
              @click="toPage('/PersonalCenter/InvioceInfo')"
              class="selectitem line"
            >
              发票信息
            </li>
            <li @click="logout" class="selectitem">退出登录</li>
          </ul>
          <ul class="selecteList" v-show="showOwnInfo" v-else>
            <li @click="logout" class="selectitem">退出登录</li>
          </ul>
        </div>
        <div v-else>
          <el-button :type="isColor ? 'primary' : ''" round @click="login"
            >登录/注册</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import listNull from '@/components/listNull.vue'
import { getLocal, delLocal } from '@/lib/local'
import { queryMessageByUser, markMessageAsRead } from '@/api/news'
import { rTime } from '@/components/tableCom/js/filter.js'
import { mapMutations, mapState } from 'vuex'

export default {
  filters: {
    fTime (time) {
      return rTime(time, 3)
    }
  },
  components: { listNull },
  computed: {
    ...mapState(['userInfo'])
  },
  mounted () {
    this.userType()
    if (window.location.hostname == 'wx.taiheiot.com') {
      this.isProduction = true
    }
    window.addEventListener("scroll", this.handleScroll)
    if (this.$route.name != 'Index') {
      document.getElementsByTagName('body')[0].style.setProperty('--indexColor', '#000');
      document.getElementsByTagName('body')[0].style.setProperty('--bgcColor', '#fff');
      this.isColor = true
      this.showIndex = false
    } else {
      document.getElementsByTagName('body')[0].style.setProperty('--indexColor', '#fff');
      document.getElementsByTagName('body')[0].style.setProperty('--bgcColor', '#5699f3');
      this.isColor = false
      this.showIndex = true
      document.documentElement.scrollTop = 0
    }
		this.systemCode = getLocal('systemCode')
  },
  data () {
    return {
      showDataList: false,
      showOwnInfo: false,
      showOwnWorkSpace: false,
      isLogin: false,
      noRead: false,
      currentClick: -1, // 左侧当前选中
      newsList: [], // 消息列表
      searchContent: '', // 搜索内容，
      searchPageDesc: '营销活动',
      pageId: 1,
      isProduction: false,
      isColor: false,
			systemCode: '',
      showIndex: true
    }
  },
  watch: {
    '$route.name': {
      handler (newVal, oldVal) {
        this.userType()
        // console.log('newVal=', newVal)
        if (newVal != 'Index') {
          document.getElementsByTagName('body')[0].style.setProperty('--indexColor', '#000');
          document.getElementsByTagName('body')[0].style.setProperty('--bgcColor', '#fff');
          this.isColor = true
          this.showIndex = false
        } else {
          document.getElementsByTagName('body')[0].style.setProperty('--indexColor', '#fff');
          document.getElementsByTagName('body')[0].style.setProperty('--bgcColor', '#5699f3');
          this.isColor = false
          this.showIndex = true
          document.documentElement.scrollTop = 0
        }
      }
    }
  },
  methods: {
    handleScroll () {
      if (document.documentElement.scrollTop > 50) {
        document.getElementsByTagName('body')[0].style.setProperty('--indexColor', '#000');
        document.getElementsByTagName('body')[0].style.setProperty('--bgcColor', '#fff');
        this.isColor = true
        this.$bus.$emit('backShow', true)
      } else if (document.documentElement.scrollTop < 50 && this.$route.name == 'Index') {
        document.getElementsByTagName('body')[0].style.setProperty('--indexColor', '#fff');
        document.getElementsByTagName('body')[0].style.setProperty('--bgcColor', '#5699f3');
        this.isColor = false
        this.$bus.$emit('backShow', false)
      }
    },
    search () {
      switch (this.pageId) {
        case 1:
          if (this.$route.name != `DesignClassAll`) {
            this.$router.push({
              name: 'DesignClassAll',
              params: {
                searchContent: this.searchContent
              }
            })
          } else {
            this.$nextTick(() => {
              this.$bus.$emit('searchDesign', this.searchContent)
            })
          }
          break
        case 2:
          this.$router.push({
            name: 'MarketingActivities',
            params: {
              templeName: this.searchContent
            }
          })
          this.$nextTick(() => {
            this.$bus.$emit('searchActive', this.searchContent)
          })

          break
        case 3:
          this.$router.push({
            name: 'DispalyAll',
            params: {
              searchPrint: this.searchContent
            }
          })
          this.$nextTick(() => {
            this.$bus.$emit('searchPrint', this.searchContent)
          })

          break
        default:
      }
    },
    ...mapMutations(['setUserInfo']),
    searchDesignClassAll () {
      this.searchPageDesc = '在线设计'
      this.pageId = 1
    },
    searchMarketingActivities () {
      this.searchPageDesc = '营销活动'
      this.pageId = 2
    },
    searchDispalyAll () {
      this.searchPageDesc = '在线印刷'
      this.pageId = 3
    },
    queryData () {
      queryMessageByUser({
        page: 1,
        limit: 5
      }).then(res => {
        if (res.code === 0) {
          if (res.data.list.length > 0) {
            this.newsList = res.data.list
          }
        } else if (res.code == 401) {
          this.$message({
            type: 'error',
            message: '登录失效，请重新登录'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    toPage (url) {
      this.$router.push(url)
    },
    logout () {
      if (this.isLogin) {
        delLocal()
      }
	    localStorage.clear()
      location.reload()
    },
    login () {
      delLocal()
      this.$Login()
    },
    allRead () {
      markAllMessageAsRead().then(res => {
        if (res.code == 0) {
          this.newsList = []
          this.page = 1
          this.queryData()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    // 判断登录用户类型
    userType () {
      this.isLogin = !!getLocal()
      if (this.isLogin) {
        this.setUserInfo()
      }
    },
    Jump (path) {
      this.$router.push(path)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../common/sass/index.scss";
.menu-head {
  position: fixed;
  z-index: 200;
  width: 100vw;
  height: 80px;
  background: linear-gradient(90deg, $index-bgc, $index-bgc);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12);
  .logo-img {
    position: relative;
    width: 210px;
    height: 100%;
    // line-height: 100px;
    // line-height: 120px;
    // text-align: center;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .logoImg {
    // width: 148px;
    height: 45px;
  }
  .logoImg-color {
    height: 45px;
    left: 40px;
    top: 9px;
  }
  .active {
    display: inline-block;
  }

  .menu-tap {
    float: left;
    // display: inline-block;
    list-style-type: none;
    padding: 0;
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
    .menuItem{
      margin-left: 40px;
      color: $index-text;
      cursor: pointer;
      position: relative;
      height: 100%;
      line-height: 80px;
      &.drawDown{
        &:after{
          content: '';
          width: 10px;
          height: 10px;
          border-bottom: 1px solid $index-text;
          border-right: 1px solid $index-text;
          display: inline-block;
          transform: rotate(45deg);
          margin-left: 4px;
          position: relative;
          top: -2px;
        }
        &:hover{
          .downList{
            display: block;
          }
        }
      }
      .downList{
        width: 250px;
        height: auto;
        background-color: #fff;
        border-radius: 4px;
        position: absolute;
        left: -70px;
        top: 90%;
        box-shadow: 2px 2px 4px #81afd6;
        overflow: hidden;
        display: none;
        .menu{
          height: 70px;
          margin: 20px;
          border-bottom: 1px solid #ccc;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          flex-direction: column;
          cursor: pointer;
          line-height: initial;
          &:hover{
            background-color: #f6f6f6;
          }
          .label{
            color: #559cf0;
            font-size: 16px;
            font-weight: bold;
          }
          .desc{
            color: #999;
            font-size: 14px;
          }
        }
      }
    }
    li {
      position: relative;
      display: flex;
      align-items: center;
      height: 42px;
      margin-left: 60px;
      font-size: 14px;
      float: left;
      cursor: pointer;
      color: $index-text;
      &:after {
        content: "";
        width: 0;
        height: 4px;
        background-color: $index-text;
        border-radius: 2px;
        position: absolute;
        left: 0;
        bottom: 0;
        transition: 0.3s;
      }
      &:hover {
        &::after {
          width: 100%;
        }
      }
      &.router-link-active {
        &::after {
          width: 100%;
        }
      }
    }
  }
  .search-content {
    // line-height: 100px;

    height: 100%;
    display: flex;
    align-items: center;
    .content {
      margin-left: 100px;
      position: relative;
      width: 280px;
      background-color: #f4f4fb;
      border-radius: 18px;
      // overflow: hidden;
      height: 36px;
      .search-class {
        width: 90px;
        height: 36px;
        font-size: 14px;
        font-weight: 400;
        color: #191c3d;
        float: left;
        line-height: 36px;
        text-align: center;
        position: relative;
        cursor: pointer;
        .selecteList {
          position: absolute;
          left: 0;
          top: 100%;
          z-index: 9999;
          list-style-type: none;
          box-shadow: 1px 1px 5px #888888;
          background: white;
          border-radius: 2px;
          .selectitem {
            height: 30px;
            line-height: 30px;
            padding-left: 5px;
            padding-right: 5px;
            cursor: pointer;
            &:hover {
              background-color: rgb(0, 110, 255);
              color: white;
            }
          }
        }
      }
      .search-input {
        float: left;
        width: 100px;
        position: relative;
        input {
          width: 100px;
          height: 36px;
          border: 0;
          background: rgba(0, 0, 0, 0);
          &:focus {
            outline: none;
          }
        }
        .close-button {
          position: absolute;
          right: -20px;
          top: 10px;
          color: rgb(122, 122, 122);
          cursor: pointer;
        }
      }
      .seachbutton {
        position: absolute;
        right: 0;
        top: 0;
        width: 64px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        color: white;
        background: #2468f2;
        border-radius: 0px 18px 18px 0px;
        cursor: pointer;
      }
    }
  }
  .user-info-list {
    position: absolute;
    height: 78px;
    right: 20px;
    display: flex;
    align-items: center;
    .oper-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      // line-height: 100px;
      height: 78px;
      display: flex;
      align-items: center;
      .oper-list-item {
        //   width: 30px;
        position: relative;
        margin-right: 10px;
        float: left;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #191c3d;
        display: flex;
        align-items: center;
        height: 42px;
        border-radius: 2px;
        padding: 5px;
        cursor: pointer;
        color: $index-text;
        .setColor {
          color: $index-text;
          display: flex;
          align-items: center;
        }
        .down {
          margin-left: 5px;
        }

        &:hover {
          // background: #f4f4fb;
        }
        .pic {
          width: 13px;
          height: 13px;
          margin-right: 5px;
        }
        .pic-color {
          display: none;
          position: absolute;
        }
        .active {
          display: inline-block;
        }
        &.myNews {
          position: relative;
          .list-box {
            display: none;
            width: 300px;
            height: 400px;
            background-color: #fff;
            box-shadow: 2px 2px 4px #c0c4cc, -2px -2px 4px #c0c4cc;
            border-radius: 6px;
            padding: 20px 30px;
            position: absolute;
            top: 100%;
            right: 0;
            z-index: 200;
            .news-list {
              height: 100%;
              overflow: auto;
              &::-webkit-scrollbar {
                /**垂直滚动条的宽**/
                width: 6px;
              }
              &::-webkit-scrollbar-track-piece {
                /**修改滚动条的背景和圆角**/
                background: #fff;
                -webkit-border-radius: 3px;
              }
              /*设置垂直滚动条的样式*/
              &::-webkit-scrollbar-thumb:vertical {
                background-color: #ccc;
                -webkit-border-radius: 3px;
              }
              .list-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 20px 10px;
                border-bottom: 1px solid #cccccc;
              }
              .news-item {
                height: 60px;
                line-height: 30px;
                border-bottom: 1px solid #e9e9eb;
                display: flex;
                justify-content: space-between;
                padding: 10px 20px 0;
                font-size: 14px;
                &.noRead {
                  position: relative;
                  &:before {
                    content: "";
                    width: 6px;
                    height: 6px;
                    background-color: red;
                    border-radius: 3px;
                    position: absolute;
                    left: 6px;
                    top: 20px;
                  }
                }
                &.currentClick {
                  background-color: #ecf5ff;
                }
                &:hover {
                  background-color: #edf2fc;
                  cursor: pointer;
                }
                .news-title {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  font-weight: bold;
                  color: #000000;
                }
                .news-time {
                  margin-left: 30px;
                  white-space: nowrap;
                  color: #666;
                }
              }
            }
          }
          &:hover {
            .list-box {
              display: block;
            }
          }
        }
        .selecteList {
          position: absolute;
          right: 0;
          top: 100%;
          z-index: 9999;
          list-style-type: none;
          box-shadow: 1px 1px 5px #888888;
          background: white;
          border-radius: 2px;
          width: 100px;
          .selectitem {
            height: 30px;
            line-height: 30px;
            padding-left: 5px;
            padding-right: 5px;
            cursor: pointer;
            &:hover {
              background-color: rgb(202, 202, 202);
            }
          }
        }
      }
    }
    .user-info-area {
      // width: 40px;
      // height: 40px;
      // background: red;
      position: relative;
      float: left;
      cursor: pointer;
      .line {
        border-bottom: 1px solid #ccc;
      }
      .headImgUrl {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      .selecteList {
        position: absolute;
        right: 0;
        top: 100%;
        z-index: 9999;
        list-style-type: none;
        box-shadow: 1px 1px 5px #888888;
        background: white;
        border-radius: 2px;
        width: 120px;
        padding: 10px;
        .selectitem {
          height: 30px;
          line-height: 30px;
          // padding-right: 5px;
          text-align: left;
          font-size: 14px;
          &:hover {
            background-color: rgb(202, 202, 202);
          }
        }
        .userInfoShow {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #ccc;
          padding: 5px 0;
          .userImg {
            .userHeadImg {
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
          }
          .username {
            margin-left: 5px;
            overflow: hidden;

            h3 {
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .userTypeText {
              font-size: 12px;
              color: #999;
            }
          }
        }
      }
    }
  }
}
</style>
