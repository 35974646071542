var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "menu-head" }, [
    _c(
      "div",
      {
        staticClass: "logo-img",
        on: {
          click: function($event) {
            return _vm.$router.push("/")
          }
        }
      },
      [
        !_vm.isColor
          ? _c("img", {
              staticClass: "logoImg",
              attrs: { src: require("../assets/img/index/logo_white.svg") }
            })
          : _vm._e(),
        _vm.isColor
          ? _c("img", {
              staticClass: "logoImg logoImg-color",
              attrs: { src: require("../assets/img/index/logo_top.svg") }
            })
          : _vm._e()
      ]
    ),
    _c(
      "ul",
      { staticClass: "menu-tap" },
      [
        _vm.showIndex
          ? [
              _c("div", { staticClass: "menuItem drawDown" }, [
                _vm._v(" 产品 "),
                _c("div", { staticClass: "downList" }, [
                  _c(
                    "div",
                    {
                      staticClass: "menu",
                      on: {
                        click: function($event) {
                          return _vm.Jump("home")
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "label" }, [_vm._v("营销活动")]),
                      _c("div", { staticClass: "desc" }, [
                        _vm._v("轻松策划一场爆款活动")
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "menu",
                      on: {
                        click: function($event) {
                          return _vm.Jump("InfiniteQRCode")
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "label" }, [_vm._v("二维码")]),
                      _c("div", { staticClass: "desc" }, [
                        _vm._v("让创意与目的更有效地传播")
                      ])
                    ]
                  )
                ])
              ]),
              _c(
                "div",
                {
                  staticClass: "menuItem",
                  staticStyle: { cursor: "not-allowed" }
                },
                [_vm._v(" 合作加盟 ")]
              ),
              _c(
                "div",
                {
                  staticClass: "menuItem",
                  staticStyle: { cursor: "not-allowed" }
                },
                [_vm._v(" 关于我们 ")]
              )
            ]
          : [
              _c(
                "router-link",
                { attrs: { to: "/home", tag: "li", exact: "" } },
                [_vm._v("首页")]
              ),
              _c(
                "router-link",
                {
                  attrs: { to: "/templates", tag: "li" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.searchMarketingActivities($event)
                    }
                  }
                },
                [_vm._v("模板")]
              ),
              _c(
                "router-link",
                {
                  attrs: { to: "/ModalPrice", tag: "li" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.searchMarketingActivities($event)
                    }
                  }
                },
                [_vm._v("价格")]
              )
            ]
      ],
      2
    ),
    _c("div", { staticClass: "search-content" }, [
      _vm.userInfo.roleIds && !_vm.userInfo.roleIds.includes(3)
        ? _c("div", { staticClass: "content" }, [
            _c(
              "div",
              {
                staticClass: "search-class",
                on: {
                  mouseenter: function($event) {
                    _vm.showDataList = true
                  },
                  mouseleave: function($event) {
                    _vm.showDataList = false
                  }
                }
              },
              [
                _vm._v(" " + _vm._s(_vm.searchPageDesc) + " "),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
                _c(
                  "ul",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showDataList,
                        expression: "showDataList"
                      }
                    ],
                    staticClass: "selecteList"
                  },
                  [
                    _c(
                      "li",
                      {
                        staticClass: "selectitem",
                        on: { click: _vm.searchMarketingActivities }
                      },
                      [_vm._v(" 营销活动 ")]
                    )
                  ]
                )
              ]
            ),
            _c("div", { staticClass: "search-input" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchContent,
                    expression: "searchContent"
                  }
                ],
                domProps: { value: _vm.searchContent },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchContent = $event.target.value
                  }
                }
              }),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.searchContent !== "",
                    expression: "searchContent !== ''"
                  }
                ],
                staticClass: "el-icon-circle-close close-button",
                on: {
                  click: function($event) {
                    _vm.searchContent = ""
                  }
                }
              })
            ]),
            _c(
              "div",
              { staticClass: "seachbutton", on: { click: _vm.search } },
              [_c("i", { staticClass: "el-icon-search" })]
            )
          ])
        : _vm._e(),
      _c("div", { staticClass: "user-info-list" }, [
        _vm.isLogin
          ? _c("ul", { staticClass: "oper-list" }, [
              _vm.userInfo.roleIds.includes(1)
                ? _c(
                    "li",
                    {
                      staticClass: "oper-list-item",
                      on: {
                        click: function($event) {
                          return _vm.toPage(
                            _vm.systemCode == "tai_woo_wxys"
                              ? "/marketingActivity/OwnActivity"
                              : "/OwnWorkbench"
                          )
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "pic",
                        attrs: {
                          src: require("../assets/img/index/icon_workbench.png")
                        }
                      }),
                      _c("img", {
                        staticClass: "pic pic-color",
                        class: { active: _vm.isColor },
                        attrs: {
                          src: require("../assets/img/index/icon_workbench_02.png")
                        }
                      }),
                      _vm._v(" 我的工作台 ")
                    ]
                  )
                : _vm._e(),
              _vm.userInfo.roleIds.includes(2)
                ? _c(
                    "li",
                    { staticClass: "oper-list-item" },
                    [
                      _c(
                        "el-dropdown",
                        { attrs: { trigger: "click" } },
                        [
                          _c(
                            "span",
                            { staticClass: "el-dropdown-link setColor" },
                            [
                              _c("img", {
                                staticClass: "pic",
                                attrs: {
                                  src: require("../assets/img/index/icon_workbench.png")
                                }
                              }),
                              _c("img", {
                                staticClass: "pic pic-color",
                                class: { active: _vm.isColor },
                                attrs: {
                                  src: require("../assets/img/index/icon_workbench_02.png")
                                }
                              }),
                              _vm._v(" 我的工作台 "),
                              _c("i", {
                                staticClass: "el-icon-arrow-down down"
                              })
                            ]
                          ),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c(
                                "el-dropdown-item",
                                {
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.toPage("/OwnWorkbench")
                                    }
                                  }
                                },
                                [_vm._v("个人工作台")]
                              ),
                              _c(
                                "el-dropdown-item",
                                {
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.toPage(
                                        "/plantWorkbench/orderConfirm"
                                      )
                                    }
                                  }
                                },
                                [_vm._v("印刷厂工作台")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.userInfo.roleIds.includes(3)
                ? _c(
                    "li",
                    {
                      staticClass: "oper-list-item",
                      on: {
                        click: function($event) {
                          return _vm.toPage(
                            "/OperationWorkbench/DemandOrderList"
                          )
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "pic",
                        attrs: {
                          src: require("../assets/img/index/icon_workbench.png")
                        }
                      }),
                      _c("img", {
                        staticClass: "pic pic-color",
                        class: { active: _vm.isColor },
                        attrs: {
                          src: require("../assets/img/index/icon_workbench_02.png")
                        }
                      }),
                      _vm._v(" 我的工作台 ")
                    ]
                  )
                : _vm._e(),
              !_vm.userInfo.roleIds.includes(3)
                ? _c(
                    "li",
                    {
                      staticClass: "oper-list-item",
                      on: {
                        click: function($event) {
                          return _vm.$router.push({ name: "ShoppingCart" })
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "pic",
                        attrs: {
                          src: require("../assets/img/index/icon_shopping.png")
                        }
                      }),
                      _c("img", {
                        staticClass: "pic pic-color",
                        class: { active: _vm.isColor },
                        attrs: {
                          src: require("../assets/img/index/icon_shopping_02.png")
                        }
                      }),
                      _vm._v(" 购物车 ")
                    ]
                  )
                : _vm._e(),
              _c(
                "li",
                {
                  staticClass: "oper-list-item myNews",
                  on: {
                    mouseenter: _vm.queryData,
                    click: function($event) {
                      return _vm.toPage("/newsShow")
                    }
                  }
                },
                [
                  _c("img", {
                    staticClass: "pic",
                    attrs: {
                      src: require("../assets/img/index/icon_message.png")
                    }
                  }),
                  _c("img", {
                    staticClass: "pic pic-color",
                    class: { active: _vm.isColor },
                    attrs: {
                      src: require("../assets/img/index/icon_message_02.png")
                    }
                  }),
                  _vm._v(" 消息 "),
                  _c("div", { staticClass: "list-box" }, [
                    _c(
                      "div",
                      { staticClass: "news-list" },
                      [
                        _c(
                          "div",
                          { staticClass: "list-title" },
                          [
                            _c(
                              "el-badge",
                              {
                                staticClass: "item",
                                attrs: { "is-dot": _vm.noRead }
                              },
                              [_vm._v("站内信")]
                            ),
                            _vm.newsList.length > 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      plain: "",
                                      size: "mini"
                                    },
                                    on: { click: _vm.allRead }
                                  },
                                  [_vm._v("全部消息")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm.newsList.length > 0
                          ? _vm._l(_vm.newsList, function(news, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "news-item",
                                  class: {
                                    noRead: news.typeStatus == 0,
                                    currentClick: _vm.currentClick == index
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.toPage("/newsShow")
                                    }
                                  }
                                },
                                [
                                  _c("div", { staticClass: "news-title" }, [
                                    _c("i", { staticClass: "el-icon-message" }),
                                    _vm._v(
                                      " " +
                                        _vm._s(JSON.parse(news.remark).title) +
                                        " "
                                    )
                                  ]),
                                  _c("div", { staticClass: "news-time" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("fTime")(
                                            JSON.parse(news.remark).issueTime
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ]
                              )
                            })
                          : [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    height: "calc(100% - 30px)",
                                    overflow: "auto"
                                  }
                                },
                                [
                                  _c("listNull", {
                                    attrs: { text: "还没有消息呢~" }
                                  })
                                ],
                                1
                              )
                            ]
                      ],
                      2
                    )
                  ])
                ]
              )
            ])
          : _vm._e(),
        _vm.isLogin
          ? _c(
              "div",
              {
                staticClass: "user-info-area",
                on: {
                  mouseenter: function($event) {
                    _vm.showOwnInfo = true
                  },
                  mouseleave: function($event) {
                    _vm.showOwnInfo = false
                  }
                }
              },
              [
                _vm.userInfo.headImgUrl
                  ? _c("img", {
                      staticClass: "headImgUrl",
                      attrs: { src: _vm.userInfo.headImgUrl, alt: "" }
                    })
                  : _c("img", {
                      staticClass: "headImgUrl",
                      attrs: {
                        src:
                          "https://imgtest-1257418739.cos.ap-guangzhou.myqcloud.com/userFile/512/2020-12-26/cc563b3e-ecb4-4b44-a1d8-b88a3164c4be.jpg",
                        alt: ""
                      }
                    }),
                !_vm.userInfo.roleIds.includes(3)
                  ? _c(
                      "ul",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showOwnInfo,
                            expression: "showOwnInfo"
                          }
                        ],
                        staticClass: "selecteList"
                      },
                      [
                        _c("li", { staticClass: "userInfoShow" }, [
                          _c("div", { staticClass: "userImg" }, [
                            _vm.userInfo.headImgUrl
                              ? _c("img", {
                                  staticClass: "userHeadImg",
                                  attrs: {
                                    src: _vm.userInfo.headImgUrl,
                                    alt: ""
                                  }
                                })
                              : _c("img", {
                                  staticClass: "userHeadImg",
                                  attrs: {
                                    src:
                                      "https://imgtest-1257418739.cos.ap-guangzhou.myqcloud.com/userFile/512/2020-12-26/cc563b3e-ecb4-4b44-a1d8-b88a3164c4be.jpg",
                                    alt: ""
                                  }
                                })
                          ]),
                          _c("div", { staticClass: "username" }, [
                            _c("h3", [_vm._v(_vm._s(_vm.userInfo.nickName))]),
                            _vm.userInfo.roleIds.includes(1)
                              ? _c("p", { staticClass: "userTypeText" }, [
                                  _vm._v(" 普通用户 ")
                                ])
                              : _vm._e(),
                            _vm.userInfo.roleIds.includes(2)
                              ? _c("p", { staticClass: "userTypeText" }, [
                                  _vm._v(" 印刷厂用户 ")
                                ])
                              : _vm._e(),
                            _vm.userInfo.roleIds.includes(3)
                              ? _c("p", { staticClass: "userTypeText" }, [
                                  _vm._v(" 运营用户 ")
                                ])
                              : _vm._e()
                          ])
                        ]),
                        _c(
                          "li",
                          {
                            staticClass: "selectitem",
                            on: {
                              click: function($event) {
                                return _vm.toPage("/PersonalCenter/AccountInfo")
                              }
                            }
                          },
                          [_vm._v(" 账号信息 ")]
                        ),
                        _vm.userInfo.roleIds.includes(2)
                          ? _c(
                              "li",
                              {
                                staticClass: "selectitem",
                                on: {
                                  click: function($event) {
                                    return _vm.toPage(
                                      "/PersonalCenter/CompanyInfo"
                                    )
                                  }
                                }
                              },
                              [_vm._v(" 企业信息 ")]
                            )
                          : _vm._e(),
                        _c(
                          "li",
                          {
                            staticClass: "selectitem",
                            on: {
                              click: function($event) {
                                return _vm.toPage("/PersonalCenter/PrintOrder")
                              }
                            }
                          },
                          [_vm._v(" 我的订单 ")]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "selectitem",
                            on: {
                              click: function($event) {
                                return _vm.toPage(
                                  "/PersonalCenter/ReceivingAddress"
                                )
                              }
                            }
                          },
                          [_vm._v(" 我的地址 ")]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "selectitem line",
                            on: {
                              click: function($event) {
                                return _vm.toPage("/PersonalCenter/InvioceInfo")
                              }
                            }
                          },
                          [_vm._v(" 发票信息 ")]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "selectitem",
                            on: { click: _vm.logout }
                          },
                          [_vm._v("退出登录")]
                        )
                      ]
                    )
                  : _c(
                      "ul",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showOwnInfo,
                            expression: "showOwnInfo"
                          }
                        ],
                        staticClass: "selecteList"
                      },
                      [
                        _c(
                          "li",
                          {
                            staticClass: "selectitem",
                            on: { click: _vm.logout }
                          },
                          [_vm._v("退出登录")]
                        )
                      ]
                    )
              ]
            )
          : _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: _vm.isColor ? "primary" : "", round: "" },
                    on: { click: _vm.login }
                  },
                  [_vm._v("登录/注册")]
                )
              ],
              1
            )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }