const baseUrl = process.env.VUE_APP_FONTS
const fontList = [
  {
    name: 'Alibaba-PuHuiTi-Bold',
    url: baseUrl + '/infinitecode/upload/fonts/Alibaba-PuHuiTi-Bold.otf',
    fontFamily: 'AlibabaPuHuiTi-Bold',
    img: baseUrl + '/infinitecode/upload/svg/Alibaba-PuHuiTi-Bold.svg',
    type: 'en'
  },
  {
    name: 'Alibaba-PuHuiTi-Heavy',
    url: baseUrl + '/infinitecode/upload/fonts/Alibaba-PuHuiTi-Heavy.otf',
    fontFamily: 'AlibabaPuHuiTi-Heavy',
    img: baseUrl + '/infinitecode/upload/svg/Alibaba-PuHuiTi-Heavy.svg',
    type: 'en'
  },
  {
    name: 'Alibaba-PuHuiTi-Light',
    url: baseUrl + '/infinitecode/upload/fonts/Alibaba-PuHuiTi-Light.otf',
    fontFamily: 'AlibabaPuHuiTi-Light',
    img: '',
    type: 'en'
  },
  {
    name: 'Alibaba-PuHuiTi-Medium',
    url: baseUrl + '/infinitecode/upload/fonts/Alibaba-PuHuiTi-Medium.otf',
    fontFamily: 'AlibabaPuHuiTi-Medium',
    img: baseUrl + '/infinitecode/upload/svg/Alibaba-PuHuiTi-Medium.svg',
    type: 'en'
  },
  {
    name: 'Alibaba-PuHuiTi-Regular',
    url: baseUrl + '/infinitecode/upload/fonts/Alibaba-PuHuiTi-Regular.otf',
    fontFamily: 'AlibabaPuHuiTi-Regular',
    img: baseUrl + '/infinitecode/upload/svg/Alibaba-PuHuiTi-Regular.svg',
    type: 'en'
  },
  {
    name: 'Bakudai-Bold',
    url: baseUrl + '/infinitecode/upload/fonts/Bakudai-Bold.ttf',
    fontFamily: 'Bakudai-Bold',
    img: '',
    type: 'en'
  },
  {
    name: 'Bakudai-ExtraLight',
    url: baseUrl + '/infinitecode/upload/fonts/Bakudai-ExtraLight.ttf',
    fontFamily: 'Bakudai-ExtraLight',
    img: '',
    type: 'en'
  },
  {
    name: 'Bakudai-Light',
    url: baseUrl + '/infinitecode/upload/fonts/Bakudai-Light.ttf',
    fontFamily: 'Bakudai-Light',
    img: '',
    type: 'en'
  },
  {
    name: 'Bakudai-Medium',
    url: baseUrl + '/infinitecode/upload/fonts/Bakudai-Medium.ttf',
    fontFamily: 'Bakudai-Medium',
    img: '',
    type: 'en'
  },
  {
    name: 'Bakudai-Regular',
    url: baseUrl + '/infinitecode/upload/fonts/Bakudai-Regular.ttf',
    fontFamily: 'Bakudai-Regular',
    img: '',
    type: 'en'
  },
  {
    name: 'FanWunMing-B',
    url: baseUrl + '/infinitecode/upload/fonts/FanWunMing-B.ttf',
    fontFamily: 'FanWunMing-Bold',
    img: '',
    type: 'en'
  },
  {
    name: 'FanWunMing-EL',
    url: baseUrl + '/infinitecode/upload/fonts/FanWunMing-EL.ttf',
    fontFamily: 'FanWunMing-ExtraLight',
    img: '',
    type: 'en'
  },
  {
    name: 'FanWunMing-H',
    url: baseUrl + '/infinitecode/upload/fonts/FanWunMing-H.ttf',
    fontFamily: 'FanWunMing-Heavy',
    img: '',
    type: 'en'
  },
  {
    name: 'FanWunMing-L',
    url: baseUrl + '/infinitecode/upload/fonts/FanWunMing-L.ttf',
    fontFamily: 'FanWunMing-Light',
    img: '',
    type: 'en'
  },
  {
    name: 'FanWunMing-M',
    url: baseUrl + '/infinitecode/upload/fonts/FanWunMing-M.ttf',
    fontFamily: 'FanWunMing-Medium',
    img: '',
    type: 'en'
  },
  {
    name: 'FanWunMing-R',
    url: baseUrl + '/infinitecode/upload/fonts/FanWunMing-R.ttf',
    fontFamily: 'FanWunMing-Regular',
    img: '',
    type: 'en'
  },
  {
    name: 'FanWunMing-SB',
    url: baseUrl + '/infinitecode/upload/fonts/FanWunMing-SB.ttf',
    fontFamily: 'FanWunMing-SemiBold',
    img: '',
    type: 'en'
  },
  {
    name: 'FanWunMing-TW-B',
    url: baseUrl + '/infinitecode/upload/fonts/FanWunMing-TW-B.ttf',
    fontFamily: 'FanWunMingTW-Bold',
    img: '',
    type: 'en'
  },
  {
    name: 'FanWunMing-TW-EL',
    url: baseUrl + '/infinitecode/upload/fonts/FanWunMing-TW-EL.ttf',
    fontFamily: 'FanWunMingTW-ExtraLight',
    img: '',
    type: 'en'
  },
  {
    name: 'FanWunMing-TW-H',
    url: baseUrl + '/infinitecode/upload/fonts/FanWunMing-TW-H.ttf',
    fontFamily: 'FanWunMingTW-Heavy',
    img: '',
    type: 'en'
  },
  {
    name: 'FanWunMing-TW-L',
    url: baseUrl + '/infinitecode/upload/fonts/FanWunMing-TW-L.ttf',
    fontFamily: 'FanWunMingTW-Light',
    img: '',
    type: 'en'
  },
  {
    name: 'FanWunMing-TW-M',
    url: baseUrl + '/infinitecode/upload/fonts/FanWunMing-TW-M.ttf',
    fontFamily: 'FanWunMingTW-Medium',
    img: '',
    type: 'en'
  },
  {
    name: 'FanWunMing-TW-R',
    url: baseUrl + '/infinitecode/upload/fonts/FanWunMing-TW-R.ttf',
    fontFamily: 'FanWunMingTW-Regular',
    img: '',
    type: 'en'
  },
  {
    name: 'FanWunMing-TW-SB',
    url: baseUrl + '/infinitecode/upload/fonts/FanWunMing-TW-SB.ttf',
    fontFamily: 'FanWunMingTW-SemiBold',
    img: '',
    type: 'en'
  },
  {
    name: 'GenJyuuGothic-Bold-2',
    url: baseUrl + '/infinitecode/upload/fonts/GenJyuuGothic-Bold-2.ttf',
    fontFamily: 'GenJyuuGothic-Bold',
    img: '',
    type: 'en'
  },
  {
    name: 'GenJyuuGothic-ExtraLight-2',
    url: baseUrl + '/infinitecode/upload/fonts/GenJyuuGothic-ExtraLight-2.ttf',
    fontFamily: 'GenJyuuGothic-ExtraLight',
    img: '',
    type: 'en'
  },
  {
    name: 'GenJyuuGothic-Heavy-2',
    url: baseUrl + '/infinitecode/upload/fonts/GenJyuuGothic-Heavy-2.ttf',
    fontFamily: 'GenJyuuGothic-Heavy',
    img: '',
    type: 'en'
  },
  {
    name: 'GenJyuuGothic-Light-2',
    url: baseUrl + '/infinitecode/upload/fonts/GenJyuuGothic-Light-2.ttf',
    fontFamily: 'GenJyuuGothic-Light',
    img: '',
    type: 'en'
  },
  {
    name: 'GenJyuuGothic-Medium-2',
    url: baseUrl + '/infinitecode/upload/fonts/GenJyuuGothic-Medium-2.ttf',
    fontFamily: 'GenJyuuGothic-Medium',
    img: '',
    type: 'en'
  },
  {
    name: 'GenJyuuGothic-Normal-2',
    url: baseUrl + '/infinitecode/upload/fonts/GenJyuuGothic-Normal-2.ttf',
    fontFamily: 'GenJyuuGothic-Normal',
    img: '',
    type: 'en'
  },
  {
    name: 'GenJyuuGothic-Regular-2',
    url: baseUrl + '/infinitecode/upload/fonts/GenJyuuGothic-Regular-2.ttf',
    fontFamily: 'GenJyuuGothic-Regular',
    img: '',
    type: 'en'
  },
  {
    name: 'Muyao-Softbrush',
    url: baseUrl + '/infinitecode/upload/fonts/Muyao-Softbrush.ttf',
    fontFamily: 'Muyao-Softbrush',
    img: '',
    type: 'en'
  },
  {
    name: 'NaniFont-Bold',
    url: baseUrl + '/infinitecode/upload/fonts/NaniFont-Bold.ttf',
    fontFamily: 'NaniFont-Bold',
    img: '',
    type: 'en'
  },
  {
    name: 'NaniFont-ExtraLight',
    url: baseUrl + '/infinitecode/upload/fonts/NaniFont-ExtraLight.ttf',
    fontFamily: 'NaniFont-ExtraLight',
    img: '',
    type: 'en'
  },
  {
    name: 'NaniFont-Light',
    url: baseUrl + '/infinitecode/upload/fonts/NaniFont-Light.ttf',
    fontFamily: 'NaniFont-Light',
    img: '',
    type: 'en'
  },
  {
    name: 'NaniFont-Regular',
    url: baseUrl + '/infinitecode/upload/fonts/NaniFont-Regular.ttf',
    fontFamily: 'NaniFont-Regular',
    img: '',
    type: 'en'
  },
  {
    name: 'NaniFont-SemiBold',
    url: baseUrl + '/infinitecode/upload/fonts/NaniFont-SemiBold.ttf',
    fontFamily: 'NaniFont-SemiBold',
    img: '',
    type: 'en'
  },
  {
    name: 'NotoSansHans-Black',
    url: baseUrl + '/infinitecode/upload/fonts/NotoSansHans-Black.otf',
    fontFamily: 'NotoSansSChinese-Black',
    img: '',
    type: 'en'
  },
  {
    name: 'NotoSansHans-Bold',
    url: baseUrl + '/infinitecode/upload/fonts/NotoSansHans-Bold.otf',
    fontFamily: 'NotoSansSChinese-Bold',
    img: '',
    type: 'en'
  },
  {
    name: 'NotoSansHans-DemiLight',
    url: baseUrl + '/infinitecode/upload/fonts/NotoSansHans-DemiLight.otf',
    fontFamily: 'NotoSansSChinese-DemiLight',
    img: '',
    type: 'en'
  },
  {
    name: 'NotoSansHans-Light',
    url: baseUrl + '/infinitecode/upload/fonts/NotoSansHans-Light.otf',
    fontFamily: 'NotoSansSChinese-Light',
    img: '',
    type: 'en'
  },
  {
    name: 'NotoSansHans-Medium',
    url: baseUrl + '/infinitecode/upload/fonts/NotoSansHans-Medium.otf',
    fontFamily: 'NotoSansSChinese-Medium',
    img: '',
    type: 'en'
  },
  {
    name: 'NotoSansHans-Regular',
    url: baseUrl + '/infinitecode/upload/fonts/NotoSansHans-Regular.otf',
    fontFamily: 'NotoSansSChinese-Regular',
    img: '',
    type: 'en'
  },
  {
    name: 'NotoSansHans-Thin-Windows',
    url: baseUrl + '/infinitecode/upload/fonts/NotoSansHans-Thin-Windows.otf',
    fontFamily: 'NotoSansSChinese-Thin',
    img: '',
    type: 'en'
  },
  {
    name: 'OPPOSans-B',
    url: baseUrl + '/infinitecode/upload/fonts/OPPOSans-B.ttf',
    fontFamily: 'OPPOSans-Bold',
    img: baseUrl + '/infinitecode/upload/svg/OPPOSans-B.svg',
    type: 'en'
  },
  {
    name: 'OPPOSans-H',
    url: baseUrl + '/infinitecode/upload/fonts/OPPOSans-H.ttf',
    fontFamily: 'OPPOSans-Heavy',
    img: baseUrl + '/infinitecode/upload/svg/OPPOSans-H.svg',
    type: 'en'
  },
  {
    name: 'OPPOSans-L',
    url: baseUrl + '/infinitecode/upload/fonts/OPPOSans-L.ttf',
    fontFamily: 'OPPOSans-Light',
    img: baseUrl + '/infinitecode/upload/svg/OPPOSans-L.svg',
    type: 'en'
  },
  {
    name: 'OPPOSans-M',
    url: baseUrl + '/infinitecode/upload/fonts/OPPOSans-M.ttf',
    fontFamily: 'OPPOSans-Medium',
    img: baseUrl + '/infinitecode/upload/svg/OPPOSans-M.svg',
    type: 'en'
  },
  {
    name: 'OPPOSans-R',
    url: baseUrl + '/infinitecode/upload/fonts/OPPOSans-R.ttf',
    fontFamily: 'OPPOSans-Regular',
    img: baseUrl + '/infinitecode/upload/svg/OPPOSans-R.svg',
    type: 'en'
  },
  {
    name: 'Slidexiaxing-Regular',
    url: baseUrl + '/infinitecode/upload/fonts/Slidexiaxing-Regular.ttf',
    fontFamily: 'Slidexiaxing-Regular',
    img: '',
    type: 'en'
  },
  {
    name: 'YuseiMagic-Regular',
    url: baseUrl + '/infinitecode/upload/fonts/YuseiMagic-Regular.ttf',
    fontFamily: 'YuseiMagic-Regular',
    img: '',
    type: 'en'
  },
  {
    name: '仓耳周珂正大榜书',
    url: baseUrl + '/infinitecode/upload/fonts/TsangerZhoukeZhengdabangshu.ttf',
    fontFamily: 'TsangerZhoukeZhengdabangshu',
    img: baseUrl + '/infinitecode/upload/svg/TsangerZhoukeZhengdabangshu.svg',
    type: 'ch'
  },
  {
    name: '仓耳舒圆体W01',
    url: baseUrl + '/infinitecode/upload/fonts/TsangerShuYuanT-W01.ttf',
    fontFamily: 'TsangerShuYuanT-W01',
    img: baseUrl + '/infinitecode/upload/svg/TsangerShuYuanT-W01.svg',
    type: 'ch'
  },
  {
    name: '仓耳舒圆体W02',
    url: baseUrl + '/infinitecode/upload/fonts/TsangerShuYuanT-W02.ttf',
    fontFamily: 'TsangerShuYuanT-W02',
    img: baseUrl + '/infinitecode/upload/svg/TsangerShuYuanT-W02.svg',
    type: 'ch'
  },
  {
    name: '仓耳舒圆体W03',
    url: baseUrl + '/infinitecode/upload/fonts/TsangerShuYuanT-W03.ttf',
    fontFamily: 'TsangerShuYuanT-W03',
    img: baseUrl + '/infinitecode/upload/svg/TsangerShuYuanT-W03.svg',
    type: 'ch'
  },
  {
    name: '仓耳舒圆体W04',
    url: baseUrl + '/infinitecode/upload/fonts/TsangerShuYuanT-W04.ttf',
    fontFamily: 'TsangerShuYuanT-W04',
    img: baseUrl + '/infinitecode/upload/svg/TsangerShuYuanT-W04.svg',
    type: 'ch'
  },
  {
    name: '仓耳舒圆体W05',
    url: baseUrl + '/infinitecode/upload/fonts/TsangerShuYuanT-W05.ttf',
    fontFamily: 'TsangerShuYuanT-W05',
    img: baseUrl + '/infinitecode/upload/svg/TsangerShuYuanT-W05.svg',
    type: 'ch'
  },
  {
    name: '仓耳非白W01',
    url: baseUrl + '/infinitecode/upload/fonts/TsangerFeiBai-W01.ttf',
    fontFamily: 'TsangerFeiBai-W01',
    img: baseUrl + '/infinitecode/upload/svg/TsangerFeiBai-W01.svg',
    type: 'ch'
  },
  {
    name: '仓耳非白W02',
    url: baseUrl + '/infinitecode/upload/fonts/TsangerFeiBai-W02.ttf',
    fontFamily: 'TsangerFeiBai-W02',
    img: baseUrl + '/infinitecode/upload/svg/TsangerFeiBai-W02.svg',
    type: 'ch'
  },
  {
    name: '仓耳非白W03',
    url: baseUrl + '/infinitecode/upload/fonts/TsangerFeiBai-W03.ttf',
    fontFamily: 'TsangerFeiBai-W03',
    img: baseUrl + '/infinitecode/upload/svg/TsangerFeiBai-W03.svg',
    type: 'ch'
  },
  {
    name: '仓耳非白W04',
    url: baseUrl + '/infinitecode/upload/fonts/TsangerFeiBai-W04.ttf',
    fontFamily: 'TsangerFeiBai-W04',
    img: baseUrl + '/infinitecode/upload/svg/TsangerFeiBai-W04.svg',
    type: 'ch'
  },
  {
    name: '仓耳非白W05',
    url: baseUrl + '/infinitecode/upload/fonts/TsangerFeiBai-W05.ttf',
    fontFamily: 'TsangerFeiBai-W05',
    img: baseUrl + '/infinitecode/upload/svg/TsangerFeiBai-W05.svg',
    type: 'ch'
  },
  {
    name: '优设标题黑',
    url: baseUrl + '/infinitecode/upload/fonts/YouSheBiaoTiHei.ttf',
    fontFamily: 'YouSheBiaoTiHei',
    img: baseUrl + '/infinitecode/upload/svg/YouSheBiaoTiHei.svg',
    type: 'ch'
  },
  {
    name: '包图小白体',
    url: baseUrl + '/infinitecode/upload/fonts/baotuxiaobaiti.ttf',
    fontFamily: 'baotuxiaobaiti',
    img: baseUrl + '/infinitecode/upload/svg/baotuxiaobaiti.svg',
    type: 'ch'
  },
  {
    name: '台北黑体常规体',
    url: baseUrl + '/infinitecode/upload/fonts/TaipeiSansTCBeta-Regular.ttf',
    fontFamily: 'TaipeiSansTCBeta-Regular',
    img: baseUrl + '/infinitecode/upload/svg/TaipeiSansTCBeta-Regular.svg',
    type: 'ch'
  },
  {
    name: '台北黑体细体',
    url: baseUrl + '/infinitecode/upload/fonts/TaipeiSansTCBeta-Light.ttf',
    fontFamily: 'TaipeiSansTCBeta-Light',
    img: baseUrl + '/infinitecode/upload/svg/TaipeiSansTCBeta-Light.svg',
    type: 'ch'
  },
  {
    name: '叶根友守中曲画',
    url: baseUrl + '/infinitecode/upload/fonts/YGYshouzhongquhua.ttf',
    fontFamily: 'YGYshouzhongquhua',
    img: baseUrl + '/infinitecode/upload/svg/YGYshouzhongquhua.svg',
    type: 'ch'
  },
  {
    name: '字体传奇特战体(正式版)',
    url: baseUrl + '/infinitecode/upload/fonts/ziticqtezhanti.ttf',
    fontFamily: 'ziticqtezhanti',
    img: baseUrl + '/infinitecode/upload/svg/ziticqtezhanti.svg',
    type: 'ch'
  },
  {
    name: '字体视界法棍体',
    url: baseUrl + '/infinitecode/upload/fonts/ZTSJ-BaguetteFont.ttf',
    fontFamily: 'ZTSJ-BaguetteFont',
    img: baseUrl + '/infinitecode/upload/svg/ZTSJ-BaguetteFont.svg',
    type: 'ch'
  },
  {
    name: '庞门正道标题体',
    url: baseUrl + '/infinitecode/upload/fonts/PangMenZhengDao.ttf',
    fontFamily: 'PangMenZhengDao',
    img: baseUrl + '/infinitecode/upload/svg/PangMenZhengDao.svg',
    type: 'ch'
  },
  {
    name: '庞门正道真贵楷体',
    url: baseUrl + '/infinitecode/upload/fonts/PangZhenGui-PMZD.ttf',
    fontFamily: 'PangZhenGui-PMZD',
    img: baseUrl + '/infinitecode/upload/svg/PangZhenGui-PMZD.svg',
    type: 'ch'
  },
  {
    name: '庞门正道粗书体',
    url: baseUrl + '/infinitecode/upload/fonts/PangMenZhengDao-Cu.ttf',
    fontFamily: 'PangMenZhengDao-Cu',
    img: baseUrl + '/infinitecode/upload/svg/PangMenZhengDao-Cu.svg',
    type: 'ch'
  },
  {
    name: '庞门正道轻松体',
    url: baseUrl + '/infinitecode/upload/fonts/Pangmenzhengdaoqingsongti.otf',
    fontFamily: 'Pangmenzhengdaoqingsongti',
    img: baseUrl + '/infinitecode/upload/svg/Pangmenzhengdaoqingsongti.svg',
    type: 'ch'
  },
  {
    name: '手书体',
    url: baseUrl + '/infinitecode/upload/fonts/ShouShuti.ttf',
    fontFamily: 'ShouShuti',
    img: baseUrl + '/infinitecode/upload/svg/ShouShuti.svg',
    type: 'ch'
  },
  {
    name: '文泉驿微米黑',
    url: baseUrl + '/infinitecode/upload/fonts/WenQuanYiMicroHei.ttf',
    fontFamily: 'WenQuanYiMicroHei',
    img: baseUrl + '/infinitecode/upload/svg/WenQuanYiMicroHei.svg',
    type: 'ch'
  },
  {
    name: '文泉驿正黑',
    url: baseUrl + '/infinitecode/upload/fonts/WenQuanYiZenHei.ttf',
    fontFamily: 'WenQuanYiZenHei',
    img: baseUrl + '/infinitecode/upload/svg/WenQuanYiZenHei.svg',
    type: 'ch'
  },
  {
    name: '方正书宋简体',
    url: baseUrl + '/infinitecode/upload/fonts/FZShuSong-Z01S.TTF',
    fontFamily: 'FZShuSong-Z01S',
    img: baseUrl + '/infinitecode/upload/svg/FZShuSong-Z01S.svg',
    type: 'ch'
  },
  {
    name: '方正仿宋简体',
    url: baseUrl + '/infinitecode/upload/fonts/FZFangSong-Z02S.TTF',
    fontFamily: 'FZFangSong-Z02S',
    img: baseUrl + '/infinitecode/upload/svg/FZFangSong-Z02S.svg',
    type: 'ch'
  },
  {
    name: '方正楷体简体',
    url: baseUrl + '/infinitecode/upload/fonts/FZKai-Z03S.TTF',
    fontFamily: 'FZKai-Z03S',
    img: baseUrl + '/infinitecode/upload/svg/FZKai-Z03S.svg',
    type: 'ch'
  },
  {
    name: '方正黑体简体',
    url: baseUrl + '/infinitecode/upload/fonts/FZHei-B01S.TTF',
    fontFamily: 'FZHei-B01S',
    img: baseUrl + '/infinitecode/upload/svg/FZHei-B01S.svg',
    type: 'ch'
  },
  {
    name: '杨任东竹石体-Bold',
    url: baseUrl + '/infinitecode/upload/fonts/YRDZST-Bold.ttf',
    fontFamily: 'YRDZST-Bold',
    img: baseUrl + '/infinitecode/upload/svg/YRDZST-Bold.svg',
    type: 'ch'
  },
  {
    name: '杨任东竹石体-Extralight',
    url: baseUrl + '/infinitecode/upload/fonts/YRDZST-Extralight.ttf',
    fontFamily: 'YRDZST-Extralight',
    img: baseUrl + '/infinitecode/upload/svg/YRDZST-Extralight.svg',
    type: 'ch'
  },
  {
    name: '杨任东竹石体-Heavy',
    url: baseUrl + '/infinitecode/upload/fonts/YRDZST-Heavy.ttf',
    fontFamily: 'YRDZST-Heavy',
    img: baseUrl + '/infinitecode/upload/svg/YRDZST-Heavy.svg',
    type: 'ch'
  },
  {
    name: '杨任东竹石体-Light',
    url: baseUrl + '/infinitecode/upload/fonts/YRDZST-Light.ttf',
    fontFamily: 'YRDZST-Light',
    img: baseUrl + '/infinitecode/upload/svg/YRDZST-Light.svg',
    type: 'ch'
  },
  {
    name: '杨任东竹石体-Medium',
    url: baseUrl + '/infinitecode/upload/fonts/YRDZST-Medium.ttf',
    fontFamily: 'YRDZST-Medium',
    img: baseUrl + '/infinitecode/upload/svg/YRDZST-Medium.svg',
    type: 'ch'
  },
  {
    name: '杨任东竹石体-Regular',
    url: baseUrl + '/infinitecode/upload/fonts/YRDZST-Regular.ttf',
    fontFamily: 'YRDZST-Regular',
    img: baseUrl + '/infinitecode/upload/svg/YRDZST-Regular.svg',
    type: 'ch'
  },
  {
    name: '杨任东竹石体-Semibold',
    url: baseUrl + '/infinitecode/upload/fonts/YRDZST-Semibold.ttf',
    fontFamily: 'YRDZST-Semibold',
    img: baseUrl + '/infinitecode/upload/svg/YRDZST-Semibold.svg',
    type: 'ch'
  },
  {
    name: '江西拙楷',
    url: baseUrl + '/infinitecode/upload/fonts/jiangxizhuokai.ttf',
    fontFamily: 'jiangxizhuokai',
    img: baseUrl + '/infinitecode/upload/svg/jiangxizhuokai.svg',
    type: 'ch'
  },
  {
    name: '演示佛系体',
    url: baseUrl + '/infinitecode/upload/fonts/Slidefu.ttf',
    fontFamily: 'Slidefu',
    img: baseUrl + '/infinitecode/upload/svg/Slidefu.svg',
    type: 'ch'
  },
  {
    name: '演示秋鸿楷2.0',
    url: baseUrl + '/infinitecode/upload/fonts/Slideqiuhong.ttf',
    fontFamily: 'Slideqiuhong',
    img: baseUrl + '/infinitecode/upload/svg/Slideqiuhong.svg',
    type: 'ch'
  },
  {
    name: '濑户字体',
    url: baseUrl + '/infinitecode/upload/fonts/SetoFont.ttf',
    fontFamily: 'SetoFont',
    img: baseUrl + '/infinitecode/upload/svg/SetoFont.svg',
    type: 'ch'
  },

  {
    name: '站酷小薇LOGO体',
    url: baseUrl + '/infinitecode/upload/fonts/xiaowei.otf',
    fontFamily: 'xiaowei',
    img: baseUrl + '/infinitecode/upload/svg/xiaowei.svg',
    type: 'ch'
  },
  {
    name: '站酷庆科黄油体',
    url: baseUrl + '/infinitecode/upload/fonts/zcoolqingkehuangyouti.ttf',
    fontFamily: 'zcoolqingkehuangyouti',
    img: baseUrl + '/infinitecode/upload/svg/zcoolqingkehuangyouti.svg',
    type: 'ch'
  },
  {
    name: '站酷快乐体2016修订版',
    url: baseUrl + '/infinitecode/upload/fonts/HappyZcool-2016.ttf',
    fontFamily: 'HappyZcool-2016',
    img: baseUrl + '/infinitecode/upload/svg/HappyZcool-2016.svg',
    type: 'ch'
  },
  {
    name: '站酷文艺体',
    url: baseUrl + '/infinitecode/upload/fonts/zcoolwenyiti.ttf',
    fontFamily: 'zcoolwenyiti',
    img: baseUrl + '/infinitecode/upload/svg/zcoolwenyiti.svg',
    type: 'ch'
  },
  {
    name: '站酷酷黑体',
    url: baseUrl + '/infinitecode/upload/fonts/ZCOOL_KuHei.ttf',
    fontFamily: 'ZCOOL_KuHei',
    img: baseUrl + '/infinitecode/upload/svg/ZCOOL_KuHei.svg',
    type: 'ch'
  },
  {
    name: '站酷高端黑修订151105',
    url: baseUrl + '/infinitecode/upload/fonts/zcool-gdh.ttf',
    fontFamily: 'zcool-gdh',
    img: baseUrl + '/infinitecode/upload/svg/zcool-gdh.svg',
    type: 'ch'
  },
  {
    name: '素材集市酷方体',
    url: baseUrl + '/infinitecode/upload/fonts/sucaijishikufangti.ttf',
    fontFamily: 'sucaijishikufangti',
    img: baseUrl + '/infinitecode/upload/svg/sucaijishikufangti.svg',
    type: 'ch'
  },
  {
    name: '联盟起艺卢帅正锐黑体',
    url: baseUrl + '/infinitecode/upload/fonts/lianmengqiyilushuaizhengruiheiti.ttf',
    fontFamily: 'lianmengqiyilushuaizhengruiheiti',
    img: baseUrl + '/infinitecode/upload/svg/lianmengqiyilushuaizhengruiheiti.svg',
    type: 'ch'
  },
  {
    name: '胡晓波男神体',
    url: baseUrl + '/infinitecode/upload/fonts/HuXiaoBo-NanShen.otf',
    fontFamily: 'HuXiaoBo-NanShen',
    img: baseUrl + '/infinitecode/upload/svg/HuXiaoBo-NanShen.svg',
    type: 'ch'
  },
  {
    name: '胡晓波真帅体',
    url: baseUrl + '/infinitecode/upload/fonts/HuXiaoBo-ZhenShuai.otf',
    fontFamily: 'HuXiaoBo-ZhenShuai',
    img: baseUrl + '/infinitecode/upload/svg/HuXiaoBo-ZhenShuai.svg',
    type: 'ch'
  },
  {
    name: '胡晓波骚包体',
    url: baseUrl + '/infinitecode/upload/fonts/HuXiaoBao-SaoBao.otf',
    fontFamily: 'HuXiaoBao-SaoBao',
    img: baseUrl + '/infinitecode/upload/svg/HuXiaoBao-SaoBao.svg',
    type: 'ch'
  },
  {
    name: '装甲明朝体',
    url: baseUrl + '/infinitecode/upload/fonts/SoukouMincho.ttf',
    fontFamily: 'SoukouMincho',
    img: baseUrl + '/infinitecode/upload/svg/SoukouMincho.svg',
    type: 'ch'
  },
  {
    name: '锐字真言体',
    url: baseUrl + '/infinitecode/upload/fonts/ZhenyanGB.ttf',
    fontFamily: 'ZhenyanGB',
    img: baseUrl + '/infinitecode/upload/svg/ZhenyanGB.svg',
    type: 'ch'
  },
  {
    name: '鸿雷板书简体-正式版',
    url: baseUrl + '/infinitecode/upload/fonts/HongLeiBanShu.ttf',
    fontFamily: 'HongLeiBanShu',
    img: baseUrl + '/infinitecode/upload/svg/HongLeiBanShu.svg',
    type: 'ch'
  },
  {
    name: 'Roboto-Black',
    url: baseUrl + '/infinitecode/upload/fonts/Roboto-Black.ttf',
    fontFamily: 'Roboto-Black',
    img: '',
    type: 'en'
  },
  {
    name: 'Roboto-BlackItalic',
    url: baseUrl + '/infinitecode/upload/fonts/Roboto-BlackItalic.ttf',
    fontFamily: 'Roboto-BlackItalic',
    img: '',
    type: 'en'
  },
  {
    name: 'Roboto-Bold',
    url: baseUrl + '/infinitecode/upload/fonts/Roboto-Bold.ttf',
    fontFamily: 'Roboto-Bold',
    img: '',
    type: 'en'
  },
  {
    name: 'Roboto-BoldItalic',
    url: baseUrl + '/infinitecode/upload/fonts/Roboto-BoldItalic.ttf',
    fontFamily: 'Roboto-BoldItalic',
    img: '',
    type: 'en'
  },
  {
    name: 'Roboto-Italic',
    url: baseUrl + '/infinitecode/upload/fonts/Roboto-Italic.ttf',
    fontFamily: 'Roboto-Italic',
    img: '',
    type: 'en'
  },
  {
    name: 'Roboto-Light',
    url: baseUrl + '/infinitecode/upload/fonts/Roboto-Light.ttf',
    fontFamily: 'Roboto-Light',
    img: '',
    type: 'en'
  },
  {
    name: 'Roboto-LightItalic',
    url: baseUrl + '/infinitecode/upload/fonts/Roboto-LightItalic.ttf',
    fontFamily: 'Roboto-LightItalic',
    img: '',
    type: 'en'
  },
  {
    name: 'Roboto-Medium',
    url: baseUrl + '/infinitecode/upload/fonts/Roboto-Medium.ttf',
    fontFamily: 'Roboto-Medium',
    img: '',
    type: 'en'
  },
  {
    name: 'Roboto-MediumItalic',
    url: baseUrl + '/infinitecode/upload/fonts/Roboto-MediumItalic.ttf',
    fontFamily: 'Roboto-MediumItalic',
    img: '',
    type: 'en'
  },
  {
    name: 'Roboto-Regular',
    url: baseUrl + '/infinitecode/upload/fonts/Roboto-Regular.ttf',
    fontFamily: 'Roboto-Regular',
    img: '',
    type: 'en'
  },
  {
    name: 'Roboto-Thin',
    url: baseUrl + '/infinitecode/upload/fonts/Roboto-Thin.ttf',
    fontFamily: 'Roboto-Thin',
    img: '',
    type: 'en'
  },
  {
    name: 'Roboto-ThinItalic',
    url: baseUrl + '/infinitecode/upload/fonts/Roboto-ThinItalic.ttf',
    fontFamily: 'Roboto-ThinItalic',
    img: '',
    type: 'en'
  },
  {
    name: 'SourceHanSansCN-Bold',
    url: baseUrl + '/infinitecode/upload/fonts/SourceHanSansCN-Bold.otf',
    fontFamily: 'SourceHanSansCN-Bold',
    img: '',
    type: 'en'
  },
  {
    name: 'SourceHanSansCN-ExtraLight',
    url: baseUrl + '/infinitecode/upload/fonts/SourceHanSansCN-ExtraLight.otf',
    fontFamily: 'SourceHanSansCN-ExtraLight',
    img: '',
    type: 'en'
  },
  {
    name: 'SourceHanSansCN-Heavy',
    url: baseUrl + '/infinitecode/upload/fonts/SourceHanSansCN-Heavy.otf',
    fontFamily: 'SourceHanSansCN-Heavy',
    img: '',
    type: 'en'
  },
  {
    name: 'SourceHanSansCN-Light',
    url: baseUrl + '/infinitecode/upload/fonts/SourceHanSansCN-Light.otf',
    fontFamily: 'SourceHanSansCN-Light',
    img: '',
    type: 'en'
  },
  {
    name: 'SourceHanSansCN-Medium',
    url: baseUrl + '/infinitecode/upload/fonts/SourceHanSansCN-Medium.otf',
    fontFamily: 'SourceHanSansCN-Medium',
    img: '',
    type: 'en'
  },
  {
    name: 'SourceHanSansCN-Normal',
    url: baseUrl + '/infinitecode/upload/fonts/SourceHanSansCN-Normal.otf',
    fontFamily: 'SourceHanSansCN-Normal',
    img: '',
    type: 'en'
  },
  {
    name: 'SourceHanSansCN-Regular',
    url: baseUrl + '/infinitecode/upload/fonts/SourceHanSansCN-Regular.otf',
    fontFamily: 'SourceHanSansCN-Regular',
    img: '',
    type: 'en'
  },
  {
    name: 'SourceHanSerifCN-Bold',
    url: baseUrl + '/infinitecode/upload/fonts/SourceHanSerifCN-Bold.otf',
    fontFamily: 'SourceHanSerifCN-Bold',
    img: '',
    type: 'en'
  },
  {
    name: 'SourceHanSerifCN-ExtraLight',
    url: baseUrl + '/infinitecode/upload/fonts/SourceHanSerifCN-ExtraLight.otf',
    fontFamily: 'SourceHanSerifCN-ExtraLight',
    img: '',
    type: 'en'
  },
  {
    name: 'SourceHanSerifCN-Heavy',
    url: baseUrl + '/infinitecode/upload/fonts/SourceHanSerifCN-Heavy.otf',
    fontFamily: 'SourceHanSerifCN-Heavy',
    img: '',
    type: 'en'
  },
  {
    name: 'SourceHanSerifCN-Light',
    url: baseUrl + '/infinitecode/upload/fonts/SourceHanSerifCN-Light.otf',
    fontFamily: 'SourceHanSerifCN-Light',
    img: '',
    type: 'en'
  },
  {
    name: 'SourceHanSerifCN-Medium',
    url: baseUrl + '/infinitecode/upload/fonts/SourceHanSerifCN-Medium.otf',
    fontFamily: 'SourceHanSerifCN-Medium',
    img: '',
    type: 'en'
  },
  {
    name: 'SourceHanSerifCN-Regular',
    url: baseUrl + '/infinitecode/upload/fonts/SourceHanSerifCN-Regular.otf',
    fontFamily: 'SourceHanSerifCN-Regular',
    img: '',
    type: 'en'
  },
  {
    name: 'SourceHanSerifCN-SemiBold',
    url: baseUrl + '/infinitecode/upload/fonts/SourceHanSerifCN-SemiBold.otf',
    fontFamily: 'SourceHanSerifCN-SemiBold',
    img: '',
    type: 'en'
  }
]


export default fontList
