import axios from 'axios'
import Vue from 'vue'
import { getLocal, delLocal } from './local'
import router from '@/router/index.js'
import { Message } from 'element-ui'
import config from '@/config'
import JSONbig from 'json-bigint'
let isLogin = false
class Request {
  constructor(baseURL, timeOut = 10000) {
    this.pending = {}
    this.config = {
      ...this.config,
      ...{
        baseURL,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Accept: 'application/json'

        },
        timeout: timeOut,
        transformResponse: [
          function (data) {
            // 对 data 进行任意转换处理
            try {
              return JSONbig.parse(data)
            } catch {
              return data
            }
          }
        ]
      }
    }
  }

  /**
         * 得到AXIOS实例
         */
  getInstance () {
    const instance = axios.create(this.config)
    this.setInterceptors(instance)
    return instance
  }

  /**
         *
         *取消重复请求
         */
  removePending (key, isRequest = false) {
    if (this.pending[key] && isRequest) {
      this.pending[key]()
    }
    delete this.pending[key]
  }

  /**
         * 设置拦截器
         */
  setInterceptors (instance) {
    // 添加请求拦截器
    instance.interceptors.request.use(config => {
      if (getLocal() != null) {
        config.headers.token = getLocal() // 请求头带上token
      }

      return config
    }, function (error) {
      // 对请求错误做些什么
      return Promise.reject(error)
    })
    // 添加响应拦截器
    instance.interceptors.response.use(response => {
      if (response.status === 200) {
        if (response.data.code === 200 || response.data.code === 0) {
          return Promise.resolve(response.data)
        } else if (response.data.code === 401) {
          if (getLocal() && !config.admin) {
            // Message.error(response.data.msg)
            delLocal()
            location.reload()
          } else {
            if (!isLogin && !config.admin) {
              isLogin = true
              setTimeout(() => {
                Vue.prototype.$Login()
                isLogin = false
              }, 0)
            }
          }
          return Promise.resolve(response.data)
        } else {
          return Promise.resolve(response.data)
        }
      }
    }, function (error) {
      // 对响应错误做点什么
      return Promise.reject(error)
    })
  }

  /**
         * GET请求
         */
  get (url, params) {
    return this.getInstance().request({
      method: 'get',
      url,
      params
    })
  }

  /**
         * POST请求
         */
  post (url, params) {
    return this.getInstance().request({
      method: 'post',
      url: url,
      data: params
    })
  }

  /**
     * PUT
     */
  put (url, param = {}, data = {}) {
    return this.getInstance().request({
      method: 'put',
      url: url,
      params: param,
      data
    })
  }

  /**
     * DELETE
     */
  delete (url, param = {}, data = {}) {
    return this.getInstance().request({
      method: 'delete',
      url,
      params: param,
      data
    })
  }

  /**
     * 加密
     * @param {} param
     */
  encryption (param) {
    return param
  }

  /**
     * 解密
     * @param {} data
     */
  decrypt (data) {
    return data
  }
}

export default Request
