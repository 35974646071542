import { getByItemCode } from '@/api/createForm.js'
export const createForm = {
  namespaced: true,
  state: {
    userForm: {
      formName: '此处输入表单名称',
      submitName: '提交'
    },

    chooseFormItem: [], // 已选择的表达项字段 - 左侧
    chooseFormItemConfig: [], // 已选中的字段详细配置  // 表单列表
    chooseItem: -1 // this.chooseFormItemConfig.length
  },
  getters: {},
  actions: {},
  mutations: {
    // 添加表单项
    ADDITEM (state, payload) {
      state.chooseFormItem.push(payload.code)
      getByItemCode({
        itemCode: payload.code
      }).then(res => {
        const { name, icon, code: itemCode, classification } = payload
          // .filter((x) => { // 过过滤掉不显示的编辑项
          //   return x.showState
          // })
        const showItemList = res.data.map((x) => { // 可以动态添加选项的添加默认选项
          if (x.optionCode === 'item') {
            x.optionValue = JSON.parse(x.optionValue)
          } else if (x.optionCode === 'require') {
            x.optionValue = x.optionValue === 'false' ? false : true
          }
          delete x.id
          return x
        })
        const item = {
          // id,
          name,
          icon,
          itemCode,
          classification,
          options: showItemList
        }
        state.chooseFormItemConfig.push(item)
        console.log('state.chooseFormItemConfig:', state.chooseFormItemConfig)
      })
    },
    // 选中表单项
    CHOOSEITEM (state, payload) {
      state.chooseItem = payload
    },
    // 设置表单项配置
    SETITEMCONFIG (state, payload) {
      state.chooseFormItemConfig[payload.index].msg = payload.msg
      state.chooseFormItemConfig[payload.index].options = payload.options
    },
    // 复制表单项
    COPYITEM (state, payload) {
      const item = JSON.parse(JSON.stringify(state.chooseFormItemConfig[payload]))
      state.chooseFormItemConfig.splice(payload + 1, 0, item)
    },
    // 删除表单项
    DELETEITEM (state, payload) {
      state.chooseFormItem.splice(payload, 1)
      state.chooseFormItemConfig.splice(payload, 1)
    },
    // 表单排序
    SETFORMSORT (state, payload) {
      const { chooseIndex, targetIndex } = payload
      const chooseItem = state.chooseFormItemConfig.splice(chooseIndex, 1)
      state.chooseFormItemConfig.splice(targetIndex, 0, ...chooseItem)
      const chooseType = state.chooseFormItem.splice(chooseIndex, 1)
      state.chooseFormItem.splice(targetIndex, 0, ...chooseType)
      // console.log('state.chooseFormItemConfig:', state.chooseFormItemConfig)
      // console.log('state.chooseFormItem:', state.chooseFormItem)
    },
    // 编辑表单下回显数据
    SETFORMDETAIL (state, payload) {
      state.userForm = payload.userForm
      state.chooseFormItemConfig = payload.formItemOptionDTO.userFormItem.map((item) => {
        item.options.map((i) => {
          if (i.optionCode === 'require') {
            i.optionValue = i.optionValue === 'false' ? false : true
          }
          if (i.optionCode === 'item') {
            i.optionValue = JSON.parse(i.optionValue)
          }
          return i
        })
        return item
      })
      const chooseList = payload.formItemOptionDTO.userFormItem.map((item) => {
        return item.itemCode
      })
      state.chooseFormItem = Array.from(new Set(chooseList))
    },
    SETSTATERESET (state) { // 离开时清空数据
      state.userForm = {
        formName: '此处输入表单名称',
        submitName: '提交'
      }
      state.chooseFormItem = [] // 已选择的表达项字段 - 左侧
      state.chooseFormItemConfig = [] // 已选中的字段详细配置  // 表单列表
      state.chooseItem = -1
    }
  }
}
