<template>
  <div class="null-box">
    <div style="margin-bottom: 20px"><img :src="follow" alt=""></div>
    <div style="color: #dbdbdb">{{ text }}</div>
  </div>
</template>

<script>
import follow from '@/assets/img/OwnWorkbench/follow.png'
export default {
  name: 'listNull',
  props: ['text'],
  data () {
    return {
      follow
    }
  }
}
</script>

<style scoped lang="scss">
.null-box{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
