import Vue from 'vue'
// 针对 el-input做的限制，只能输入正整数
Vue.directive('Int', {
  bind: function (el) {
    const input = el.getElementsByTagName('input')[0]
    input.onkeyup = function (e) {
      if (input.value.length === 1) {
        input.value = input.value.replace(/[^0-9]/g, '')
      } else {
        input.value = input.value.replace(/[^\d]/g, '')
      }
      trigger(input, 'input')
    }
    input.onblur = function (e) {
      if (input.value.length === 1) {
        input.value = input.value.replace(/[^0-9]/g, '')
      } else {
        input.value = input.value.replace(/[^\d]/g, '')
      }
      trigger(input, 'input')
    }
  }
})
const trigger = (el, type) => {
  const e = document.createEvent('HTMLEvents')
  e.initEvent(type, true, true)
  el.dispatchEvent(e)
}
