
import Big from 'big.js'
/**
 * 数学计算工具类
 */
class MathUtil {
  static sin (angle) {
    return Math.sin(angle / 180 * Math.PI)
  }

  static asin (sinValue) {
    return Math.asin(sinValue) / Math.PI * 180
  }

  static cos (angle) {
    return Math.cos(angle / 180 * Math.PI)
  }

  static tan (angle) {
    return Math.tan(angle / 180 * Math.PI)
  }

  static atan (tanvalue) {
    return Math.atan(tanvalue) / Math.PI * 180
  }

  /**
   * 得到直角三角形斜边
   * @param width
   * @param height
   * @returns
   */
  static getHypotenuse (width, height) {
    return Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2))
  }

  static getFullSize (width, height, rate) {
    if (width / height > rate) {
      return {
        width: width,
        height: width / rate
      }
    }
    return {
      width: height * rate,
      height: height
    }
  }

  static Plus (x, y) {
    return new Big(x) + new Big(y)
  }
}
export default MathUtil
