import { getLocal } from '@/lib/local'
let systemCode = localStorage.getItem('systemCode')
function getmarketingActivityData () {
  return {
    activity: {
      name: '',
      beginTime: '',
      brandExposure: {
        isShow: false,
        logo: '',
        slogan: ''
      },
      themeId: '', // 集卡模板选主题用
      description: '',
      custom: { // 个性化设置json
        url: '',
        inviteNum: 2,
        chance: 1,
        rules: '',
        tips: '',
        winShowNum: 10,
        userAnswerChance: 0,
        userDayAnswerChance: 0,
        rightAnswerChance: 1,
        questionNum: 1,
        // 2022-04-12
        productId: '',
        productName: '',
        thirdSystemCode: ''
      },
      endTime: '',
      functionButton: {
        isShow: 0, // 0：隐藏 1：公众号 2：页面跳转
        pageButton: '',
        pageButtonUrl: '',
        weChatButton: '',
        weChat: ''

      },
      isJoinPeopleShow: false,
      isGetPhone: false,
      companyName: '',
      shareData: {
        isShare: false,
        isDefaultContent: true,
        defaultContent: '',
        customContent: '',
        isDefaultIcon: true,
        defaultIcon: '',
        customIcon: ''
      },
      telephone: '',
      templeId: '',
      templeName: '',
      templePage: '',
      templeType: ''
    },
    monitors: [],
    monitor: { // 监控与阀值 // 监控

      isOpenMonitor: 0, // 是否开启监控
      amountCountWarn: null, // 监控总金额
      amountDayCountWarn: null, // 监控单日总金额
      monitorPrize: [], // 需监控的奖品配置
      monitorIndex: [], // 需监控的奖品下标
      // 阀值
      isOpenValve: 0, // 是否开启阀值处理
      handle: [1], // 触发阀值处理方式
      amountCountAlarm: null, // 阀值总金额
      amountDayCountAlarm: null, // 阀值单日总金额
      valvePrize: [], // 需控制阀值的奖品配置
      valveIndex: [], // 需控制阀值的奖品下标
      // 邮箱
      noticeEmail: '' // 邮箱
    },
    posterDTOList: [], // 海报信息列表
    limit: {
      totalChance: -1, // 活动总抽奖机会（-1表示无限制）
      userChance: -1, // 每人总抽奖机会（-1表示无限制）
      userDayChance: 0, // 每人每日抽奖机会（-1表示无限制）
      userDayWin: -1, // 每人每日中奖机会（-1表示无限制）
      userJoin: -1, // 用户参加人数（-1表示无限制）
      userWin: 0, // 每人中奖次数（-1表示无限制）
      barcodeChance: systemCode === 'tai_woo_wxys' ? 1 : 0 // 扫码抽奖次数（至少为1）
    },
    map: {
      mapJson: ''
    },
    prize: [
      {
        exchangeAddress: '',
        exchangeBeginTime: '',
        exchangeEndTime: '',
        exchangeNotice: 0,
        exchangeTips: '',
        productId: '',
        isFirstWin: false,
        isPrecious: false,
        operationTips: '',
        poolRule: {
          intervalTime: '',
          quantity: ''
        },
        //   [
        //   {
        //     beginTime: '',
        //     endTime: '',
        //     intervalTime: '',
        //     qty: 0,
        //     quantity: '',
        //     ruleId: ''
        //   }
        // ],
        rate: 0,
        exchangeType: 0,
        name: '',
        quantity: 1,
        quantityDay: '', //  每日中奖数量
        productName: '', // 物资名称
        productType: '', // 物资类型
        imgUrl: {
          defaultImg: '',
          productImg: '',
          type: 'default', // 奖品图片类型（default默认,product奖品,upload上传）
          uploadImg: ''
        },
        incChange: 1,
        incQty: 0,
        rank: 0,
        type: 0,
        wechatUrl: '',
        rules: '',
        tips: '',
        winShowNum: 10
      }
    ],
    // 额外的奖品
    // extPrize: {
    //   name: '',
    //   productId: '',
    //   productName: '',
    //   productType: ''
    // }
  }
}

export default {
  namespaced: true,
  state: {
    marketingActivityData: getmarketingActivityData(),
    systemCode: localStorage.getItem('systemCode'),
    themeList: [], // 集卡主题列表
    basicValidate: false, // 基础设置验证
    awardValidate: false, // 奖项设置验证
    reportValidate: false, // 海报上传验证
  },
  mutations: {
    setSystemCode (state, payload) {
      state.systemCode = payload
    },
    setCustom: (state, custom) => {
      state.marketingActivityData.activity.custom = custom
    },
    // // 参与地区
    setActivityMapCodeParamDTO: (state, mapCodeDTO) => {
      state.marketingActivityData.map.mapJson = JSON.stringify(mapCodeDTO)
    },
    // 是否开启功能按钮
    setFunctionButton: (state, isShow) => {
      if (isShow == 0) {
        state.marketingActivityData.activity.functionButton.pageButton = ''
        state.marketingActivityData.activity.functionButton.pageButtonUrl = ''
        state.marketingActivityData.activity.functionButton.weChatButton = ''
        state.marketingActivityData.activity.functionButton.weChat = ''
      } else if (isShow == 1) {
        state.marketingActivityData.activity.functionButton.pageButton = ''
        state.marketingActivityData.activity.functionButton.pageButtonUrl = ''
      } else if (isShow == 2) {
        state.marketingActivityData.activity.functionButton.weChatButton = ''
        state.marketingActivityData.activity.functionButton.weChat = ''
      }
    },
    // 设置微信公众号二维码
    setWeChatDTOPictureUrl: (state, pictureUrl) => {
      state.marketingActivityData.activity.functionButton.weChat = pictureUrl
    },
    // 设置企业logo
    setBrandPictureUrl: (state, pictureUrl) => {
      state.marketingActivityData.activity.brandExposure.logo = pictureUrl
    },
    // 重置品牌曝光数据
    resetBrand: (state, data) => {
      state.marketingActivityData.activity.brandExposure.logo = ''
      state.marketingActivityData.activity.brandExposure.slogan = ''
    },
    // 设置奖品图片
    // setPrizePictureUrl: (state, picture) => {
    //   state.marketingActivityData.prize[picture.index].prizePictureUrl = JSON.stringify({ type: picture.type, url: picture.pictureUrl })
    // },
    // 设置奖品名称/productId
    setPrizedata: (state, prizeData) => {
      state.marketingActivityData.prize[prizeData.index].name = prizeData.name
      state.marketingActivityData.prize[prizeData.index].productId = prizeData.id
      state.marketingActivityData.prize[prizeData.index].productName = prizeData.name
      state.marketingActivityData.prize[prizeData.index].productType = prizeData.type
      // state.marketingActivityData.prize[prizeData.index].stock = prizeData.stock
    },
    // 设置奖品图片类型及图片
    setPrizeImg: (state, prizeImg) => {
      if (prizeImg.type == 'default') {
        state.marketingActivityData.prize[prizeImg.index].imgUrl.defaultImg = prizeImg.imgUrl
      } else if (prizeImg.type == 'product') {
        state.marketingActivityData.prize[prizeImg.index].imgUrl.productImg = prizeImg.imgUrl
      } else if (prizeImg.type == 'upload') {
        state.marketingActivityData.prize[prizeImg.index].imgUrl.uploadImg = prizeImg.imgUrl
      }
      state.marketingActivityData.prize[prizeImg.index].imgUrl.type = prizeImg.type
    },

    // 增加奖项
    addPrize: (state, prize) => {
      state.marketingActivityData.prize.push(prize)
    },
    // 减少奖项
    subPrize: (state) => {
      state.marketingActivityData.prize.pop()
    },
    // 清空奖项
    resetPrize: (state) => {
      state.marketingActivityData.prize = []
    },
    // 获取模板创建活动数据
    setTemplate: (state, templateData) => {
      state.marketingActivityData.activity.templeId = templateData.templeId
      state.marketingActivityData.activity.templePage = templateData.templePage
      state.marketingActivityData.activity.templeType = templateData.templeType
      state.marketingActivityData.activity.name = templateData.name
      state.marketingActivityData.activity.description = templateData.description
    },
    // 设置奖项等级
    // setPrizeLevel: (state, level) => {
    //   state.marketingActivityData.prize[level].prizeRank = level
    // },
    // 设置分享图片
    setDefaultSharePictureUrl: (state, pictureUrl) => {
      if (state.marketingActivityData.activity.shareData.isDefaultIcon) {
        state.marketingActivityData.activity.shareData.defaultIcon = pictureUrl
      } else {
        state.marketingActivityData.activity.shareData.customIcon = pictureUrl
      }
    },
    // 设置分享内容
    setDefaultShareContent: (state, content) => {
      if (state.marketingActivityData.activity.shareData.isDefaultContent) {
        state.marketingActivityData.activity.shareData.defaultContent = content
      } else {
        state.marketingActivityData.activity.shareData.customContent = content
      }
    },
    // 设置是否开启
    setShare: (state, isShare) => {
      state.marketingActivityData.activity.shareData.isShare = isShare
    },
    // 设置客服微信
    setServiceWeChat: (state, picture) => {
      state.marketingActivityData.prize[picture.index].wechatUrl = picture.pictureUrl
    },
    // 重置数据
    resetData: (state) => {
      state.marketingActivityData = getmarketingActivityData()
    },
    // 设置编辑活动数据
    editActiveInit: (state, data) => {
      state.marketingActivityData = data
    },
    // 设置首抽必中
    setFirstWin: (state, num) => {
      state.marketingActivityData.prize.forEach((item, index) => {
        if (index !== num) {
          item.isFirstWin = false
        }
      })
    },
    // 设置 额外的奖品
    setExtPrize: (state, prizeData) => {
      // prize data
      // state.marketingActivityData.extPrize.name = prizeData.name
      // state.marketingActivityData.extPrize.productId = prizeData.id
      // state.marketingActivityData.extPrize.productName = prizeData.name
      // state.marketingActivityData.extPrize.productType = prizeData.type

      // custom
      state.marketingActivityData.activity.custom.productId = prizeData.id
      state.marketingActivityData.activity.custom.productName = prizeData.name
    },
    // 设置奖品数量报警设置
    setPrizeMonitorConfig (state, payload) {
      state.marketingActivityData.monitor.monitorPrize = payload
      // state.marketingActivityData.monitors = payload
    },
    setPrizeValveConfig (state, payload) {
      state.marketingActivityData.monitor.valvePrize = payload
      // state.marketingActivityData.monitors = payload
    },
    setMonitors (state, payload) {
      state.marketingActivityData.monitors = payload
    },
    setThemeList (state, payload) { // 设置列表
      state.themeList = payload
    },
    setBasicValidate (state, payload) {
      state.basicValidate = payload
    },
    setAwardValidate (state, payload) {
      state.awardValidate = payload
    },
    addReport (state, payload) { // 增加海报
      state.marketingActivityData.posterDTOList.push(payload)
    },
    subReport (state) { // 删除海报
      state.marketingActivityData.posterDTOList.pop()
    },
    setReportImg (state, payload) {
      state.marketingActivityData.posterDTOList[payload.index].posterUrl = payload.fileUrl
    },
    setUploadReport (state, payload) {
      state.reportValidate = payload
    },
    setDefaultLimit (state) {
      state.marketingActivityData.limit.userChance = 1
      state.marketingActivityData.limit.userDayChance = 1
      state.marketingActivityData.limit.userWin = 1
    },
    setQuantityDay (state) {
      state.marketingActivityData.prize[0].quantityDay = state.marketingActivityData.prize[0].quantity
      state.marketingActivityData.prize[0].rate = 100
      // console.log('prize[0]=',state.marketingActivityData.prize[0])
    }
  }
}
