import Vue from 'vue'
import VueRouter from 'vue-router'

import infinitecode from './infinitecode'
import admin from './admin'
import jwtDecode from 'jwt-decode'
import config from '../config'
import { getLocal } from '@/lib/local'
import { getRoleIds } from '@/lib/util.js'
const routes = config.admin ? admin : infinitecode

Vue.use(VueRouter)
const router = new VueRouter({
  routes
})
if (!config.admin) {
  router.beforeEach((to, from, next) => {

    if (to.meta.isLogin !== false && !getLocal() && from.name === null) {
      router.push('/')
    } else if (to.meta.isLogin !== false && !getLocal()) {
      Vue.prototype.$Login()
    } else if (getLocal() && to.meta.roles && !getRoleIds().roleIds.includes(2)) {
      router.push('/')
    } else {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
      window.pageYOffset = 0
      next()
    }
  })
  router.afterEach((to, from) => {
    if (to.meta && to.meta.title) {
      document.title = to.meta.title
    } else {
      document.title = '无限立方'
    }
  })
}

// vue router连续点击多次路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
