function setLocal (val, key = 'token') {
  localStorage.setItem(key, val)
}
function getLocal (key = 'token') {
  return localStorage.getItem(key)
}
function delLocal (key = 'token') {
  localStorage.removeItem(key)
}
export { setLocal, getLocal, delLocal }
