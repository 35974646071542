import Request from '@/lib/Request'
// const http = new Request('https://cubewebapi.51ylk.com', 10000)
// const http = new Request('http://192.168.1.65:8090', 3000)
const http = new Request(process.env.VUE_APP_URL)

// 查询所有消息
export const queryMessageByUser = params => {
  return http.get('/cube_web_api/message/queryMessageByUser', params)
}
// 消息标位已读
export const markMessageAsRead = params => {
  return http.get('/cube_web_api/message/markMessageAsRead', params)
}

// 删除消息
export const deleteMessageByUserAndMsgId = params => {
  return http.get('/cube_web_api/message/deleteMessageByUserAndMsgId', params)
}
// 所有消息标位已读
export const markAllMessageAsRead = params => {
  return http.get('/cube_web_api/message/markAllMessageAsRead',params)
} 