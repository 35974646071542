import jwtDecode from 'jwt-decode'
import { getLocal } from '@/lib/local'
import axios from 'axios'
import {
  Loading
} from 'element-ui'

// 获取用户信息
function getRoleIds () {
  const userInfo = getLocal() == null ? {} : JSON.parse(jwtDecode(getLocal()).payload)
  return userInfo
}
function downLoadFile (url, type) {
  const loading = Loading.service({
    lock: true,
    text: '下载中...',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  axios.get(url, {
    responseType: 'arraybuffer'
  }).then(res => {
    const blob = new Blob([res.data])
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    console.log(reader)
    reader.onload = (e) => {
      const a = document.createElement('a')
      a.download = `${new Date().getTime()}.${String(type)}`
      a.href = e.target.result
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      setTimeout(() => {
        loading.close()
      }, 3000)
    }
  })
}

export { getRoleIds, downLoadFile }
export default {
  getRoleIds,
  downLoadFile
}
