import Request from '@/lib/Request'
// const http = new Request('http://192.168.1.144:9003', 3000) // 吴辉
const http = new Request(process.env.VUE_APP_URL)

// 查询组件列表
export const allItems = params => {
  return http.get('/cube-client/form/item/allItems', params)
}
// 查询组件配置
export const getByItemCode = params => {
  return http.get('/cube-client/form/item/option/getByItemCode', params)
}
// 查询二维码
export const getFormAppletCode = params => {
  return http.get('/cube-client/applet/user/getFormAppletCode', params)
}
// 创建表单
export const createOrUpdate = params => {
  return http.post('/cube-client/form/user/createOrUpdate', params)
}
// 查询表单列表
export const forms = params => {
  return http.post('/cube-client/form/user/forms', params)
}
// 查询表单详情
export const queryUserForm = params => {
  return http.get('/cube-client/form/user/queryUserForm', params)
}
// 删除表单
export const remove = params => {
  return http.get('/cube-client/form/user/remove', params)
}
// 表单数据查询 - 字段统计
export const fieldCount = params => {
  return http.post('/cube-client/form/user/formRecord/getRecordCountOfFieldValue', params)
}
// 查询表单字段详情 /cube-client/form/user/getRecordInfo
export const getRecordInfo = params => {
  return http.get('/cube-client/form/user/getRecordInfo', params)
}
// 表单数据查询 - 高级报表
export const getPeriodRecordCount = params => {
  return http.post('/cube-client/form/user/getPeriodRecordCount', params)
}
