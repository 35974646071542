import Vue from 'vue'
import login from './login.vue'

let LoginConstructor = Vue.extend(login)
const Login = function() {
  let instance = new LoginConstructor()
  instance.$mount()
  document.body.appendChild(instance.$el)
  instance.visible = true
  return instance
}
export default Login