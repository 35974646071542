import MathUtil from './MathUtil'

class PositionUtil {
  /**
     * 计算四个顶点的的位置
     * @param x 中心坐标X
     * @param y 中心坐标Y
     * @param w 宽
     * @param h 高
     * @param angle 角度
     * @returns
     */
  static getPosition (x, y, w, h, angle) {
    const centerPoint = {
      top: y,
      left: x
    }

    const r = PositionUtil.getLengthToOrigin(w / 2, h / 2)
    const innerAngle = MathUtil.asin(h / 2 / r)
    const leftTop = {
      left: centerPoint.left - r * MathUtil.cos(angle + innerAngle),
      top: centerPoint.top - r * MathUtil.sin(angle + innerAngle)
    }
    const rightTop = {
      left: centerPoint.left + r * MathUtil.cos(innerAngle - angle),
      top: centerPoint.top - r * MathUtil.sin(innerAngle - angle)
    }
    const leftBottom = {
      left: centerPoint.left - r * MathUtil.cos(innerAngle - angle),
      top: centerPoint.top + r * MathUtil.sin(innerAngle - angle)
    }
    const rightBottom = {
      left: centerPoint.left + r * MathUtil.cos(angle + innerAngle),
      top: centerPoint.top + r * MathUtil.sin(angle + innerAngle)
    }
    let minLeft = leftTop.left
    let minTop = leftTop.top
    let maxLeft = leftTop.left
    let maxTop = leftTop.top
    const positionArray = []
    positionArray.push(rightTop)
    positionArray.push(leftBottom)
    positionArray.push(rightBottom)
    positionArray.forEach(posi => {
      if (posi.left > maxLeft) {
        maxLeft = posi.left
      }
      if (posi.left < minLeft) {
        minLeft = posi.left
      }
      if (posi.top > maxTop) {
        maxTop = posi.top
      }
      if (posi.top < minTop) {
        minTop = posi.top
      }
    })
    return {
      leftTop,
      rightTop,
      leftBottom,
      rightBottom,
      most: {
        minLeft,
        minTop,
        maxLeft,
        maxTop
      }
    }
  }

  /**
     * 得到中心点坐标
     */
  static getCenterPosition (x, y, w, h) {
    return {
      top: y + h / 2,
      left: x + w / 2
    }
  }

  /**
   * 根据已知点，斜边长度，角度算自身坐标
   * @param angle
   * @param Hypotenuse
   * @param posi
   * @returns
   */
  static getPositionbyOther (angle, Hypotenuse, posi) {
    return {
      left: posi.left - Hypotenuse * MathUtil.cos(angle),
      top: posi.top + Hypotenuse * MathUtil.sin(angle)
    }
  }

  /*
  * 根据自身坐标，斜边长度，角度对应点坐标
  * @param angle
  * @param Hypotenuse
  * @param posi
  * @returns
  */
  static getPositionbyCenter (angle, Hypotenuse, posi) {
    return {
      left: posi.left - Hypotenuse * MathUtil.cos(angle),
      top: posi.top + Hypotenuse * MathUtil.sin(angle)
    }
  }

  /**
   * 通过中心点得到坐标位置
   * @param x
   * @param y
   * @param w
   * @param h
   * @returns
   */
  static getPositionByCenter (x, y, w, h) {
    return {
      left: x - w / 2,
      top: y - h / 2
    }
  }

  static getClipInfo (width, height, clip) {
    const fullSize = MathUtil.getFullSize(width, height, clip.width / clip.height)
    const scale = fullSize.width / clip.width
    return {
      left: clip.left + (fullSize.width - width) / 2 / scale,
      top: clip.top + (fullSize.height - height) / 2 / scale,
      width: width / scale,
      height: height / scale
    }
  }

  static beContent (point, position) {
    if (point.left > position.left && point.left < position.left + position.width && point.top > position.top && point.left < position.top + position.height) {
      return true
    }
    return false
  }

  static getSelectedByPosition (item, position) {
    // console.log(position)
    const minTop = item.top < position.top ? item.top : position.top
    const maxTop = (item.top + item.height) > (position.top + position.height) ? (item.top + item.height) : (position.top + position.height)
    const minleft = item.left < position.left ? item.left : position.left
    const maxLeft = (item.left + item.width) > (position.left + position.width) ? (item.left + item.width) : (position.left + position.width)
    // && (maxLeft-minleft) < (item.width + position.width)
    if ((maxTop - minTop) < (item.height + position.height) && (maxLeft - minleft) < (item.width + position.width)) {
      return true
    }

    return false
  }

  /**
   *
   * @param x 获得点到坐标系原点的距离
   * @param y
   * @returns
   */
  static getLengthToOrigin (x, y) {
    return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2))
  }

  /**
   * 通过定点信息获得组合信息
   * @param point
   * @param coordinateAngle
   */
  static getGroupPositionInfo (points, coordinateAngle) {
    let minLeft = Number.MAX_SAFE_INTEGER
    let maxLeft = Number.MIN_SAFE_INTEGER
    let minTop = Number.MAX_SAFE_INTEGER
    let maxTop = Number.MIN_SAFE_INTEGER
    points.forEach(point => {
      const newposition = PositionUtil.getPositionAtAngleCoordinate(point.left, point.top, coordinateAngle)
      if (newposition.left < minLeft) {
        minLeft = newposition.left
      }
      if (newposition.left > maxLeft) {
        maxLeft = newposition.left
      }
      if (newposition.top < minTop) {
        minTop = newposition.top
      }
      if (newposition.top > maxTop) {
        maxTop = newposition.top
      }
    })
    const centerLeft = (minLeft + maxLeft) / 2
    const centerTop = (minTop + maxTop) / 2
    const positon = PositionUtil.getPositionAtAngleCoordinate(centerLeft, centerTop, -coordinateAngle)
    positon.left -= (maxLeft - minLeft) / 2
    positon.top -= (maxTop - minTop) / 2
    return {
      ...positon,
      width: maxLeft - minLeft,
      height: maxTop - minTop,
      rotate: coordinateAngle
    }
  }

  /**
  * 计算坐标点在指定角度的坐标系的位置
  * @param x 中心坐标X
  * @param y 中心坐标Y
  * @param w 宽
  * @param h 高
  * @param angle 角度
  * @returns
  */
  static getPositionAtAngleCoordinate (x, y, coordinateAngle) {
    const r = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2))
    const sin = Math.abs(y / r)
    let angle = MathUtil.asin(sin)
    if (y > 0 && x > 0) {
      // eslint-disable-next-line no-self-assign
      angle = angle
    } else if (y > 0 && x < 0) {
      angle = 180 - angle
    } else if (y < 0 && x < 0) {
      angle = angle + 180
    } else if (y < 0 && x > 0) {
      angle = 360 - angle
    }

    const realAngle = angle - coordinateAngle
    return {
      left: r * MathUtil.cos(realAngle),
      top: r * MathUtil.sin(realAngle)
    }
  }

  /**
   * 获得两点之间的的信息
   */
  static getPositionInfoByTwoPoint (pointone, pointtwo) {
    const x = pointtwo.left - pointone.left
    const y = pointtwo.top - pointone.top
    const length = PositionUtil.getLengthToOrigin(x, y)
    const sin = Math.abs(y / length)

    let angle = MathUtil.asin(sin)
    if (y > 0 && x > 0) {
      // eslint-disable-next-line no-self-assign
      angle = angle
    } else if (y > 0 && x < 0) {
      angle = 180 - angle
    } else if (y < 0 && x < 0) {
      angle = angle + 180
    } else if (y < 0 && x > 0) {
      angle = 360 - angle
    }
    if (length === 0) {
      angle = 0
    }
    return {
      length,
      angle
    }
  }
}

export default PositionUtil
