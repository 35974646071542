<template>
  <div>
    <el-dialog :visible.sync="visible" width="25%">
      <div class="main">
        <div v-if="loginType" class="login-type">
          <h2 class="title title-wx">
            <!-- <img src="../assets/img/login/微信@2x.png" alt="" class="wx-icon"> -->
            微信扫码登录注册
          </h2>
          <p>首次登录后自动注册</p>
          <div class="code">
            <img :src="qrcodeUrl" alt="" class="qrcode" v-if="qrcodeUrl" @click="refresh">
          </div>
        </div>
        <div v-else class="login-type">
          <div class="title-text">
            <h2 class="title">
              手机验证码登录
            </h2>
            <p>首次登录后自动注册</p>
          </div>
          <el-form :model="form" ref="form" :rules="rules">
            <el-form-item prop="phone">
              <el-input v-model="form.phone" placeholder="请输入手机号码"></el-input>
            </el-form-item>
            <el-form-item>
              <el-col :span="16">
                <el-form-item prop="code">
                  <el-input v-model="form.code" placeholder="请输入短信验证码" @keydown.enter.native="toPhoneLogin"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item>
                  <el-button type="primary" @click="sendCode" v-if="isSend" class="sendCode">发送验证码</el-button>
                  <el-button type="primary" class="sendCode" disabled v-else>{{this.countdown + 's'}}</el-button>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" class="toPhoneLogin" @click="toPhoneLogin">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
        <ul class="other">
          <li></li>
          <li class="other-text">其他方式登录</li>
          <li></li>
        </ul>
        <ul class="login-way">
          <li @click="toLogin" v-if="loginType">
            <img src="../assets/img/login/手机1@2x.png" alt="" class="wx-icon">
            <img src="../assets/img/login/手机2@2x.png" alt="" class="wx-icon phone">
            <p>手机登录</p>
          </li>
          <li @click="loginType = true" v-else>
            <img src="../assets/img/login/微信1@2x.png" alt="" class="wx-icon">
            <img src="../assets/img/login/微信2@2x.png" alt="" class="wx-icon wx">
            <p>微信登录</p>
          </li>
          <li>
            <i @click="qqLogin">
              <img src="../assets/img/login/QQ1@2x.png" alt="" class="qq-icon">
              <img src="../assets/img/login/QQ2@2x.png" alt="" class="qq-icon qq">
            </i>
            <p>QQ登录</p>
          </li>
        </ul>
        <p class="agreement">
          <input type="checkbox" v-model="isAgreen">
          我阅读并同意
          <a :href="`${$url}/#/UserAgreement`"  target="_blank">《无限立方用户协议》</a>和
          <a :href="`${$url}/#/PrivacyAgreement`"  target="_blank">《无限立方隐私策略》</a>
        </p>
        <p class="tips" v-show="!isAgreen">请阅读并同意《无限立方用户协议》及《无限立方隐私策略》</p>
      </div>
    </el-dialog>
    <!-- <el-dialog :visible.sync="bindVisible" width="25%">
      <div class="main bind">
        <div class="login-type">
          <div class="title-text">
            <h2 class="title">
              绑定手机号码
            </h2>
            <p>根据国家相关规定，您需要绑定手机号码</p>
          </div>
          <el-form :model="form" ref="form" :rules="rules">
            <el-form-item prop="phone" label="手机号码">
              <el-input v-model="form.phone" placeholder="请输入手机号码"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
              <el-col :span="16">
                <el-input v-model="form.code" placeholder="请输入短信验证码" @keydown.enter.native="toPhoneLogin"></el-input>
              </el-col>
              <el-col :span="7">
                <el-button type="primary" @click="sendCode" v-if="isSend" class="sendCode">发送验证码</el-button>
                <el-button type="primary" class="sendCode" disabled v-else>{{this.countdown + 's'}}</el-button>
              </el-col>
            </el-form-item>
            <el-form-item>
              <div class="btn">
                <el-button type="primary" style="width: 40%" @click="bind">确认</el-button>
                <el-button type="primary" style="width: 40%">取消</el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog> -->
    <bind></bind>
  </div>
</template>

<script>
import { getPublicUrl, login, getPhoneCode, phoneLogin } from '@/api/login'
import { setLocal } from '@/lib/local'
import { getRoleIds } from '@/lib/util.js'
import bind from './bind.vue'
export default {
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else {
        var reg=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (reg.test(value)) {
            callback();
        } else {
            callback(new Error('请输入正确的手机号'));
        }
      }
    }
    var validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入短信验证码'));
      } else {
        var reg=/^\d{6}$/;
        if (reg.test(value)) {
            callback();
        } else {
            callback(new Error('请输入正确的短信验证码'));
        }
      }
    }
    return {
      isUrl:false,
      isAgreen: true,
      loginType: true,
      isSend: true,
      visible: false,
      uuid: '',
      // imgUuid: '',
      qrcodeUrl: '',
      expireSeconds: '',
      code: '',
      timeId: '',
      countdown: '',
      // imgUrl: '',
      form: {
        phone: '',
        code: '',
        verificationCode: '',
      },
      rules: {
        phone: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        code: [
          { required: true, validator: validateCode, trigger: 'blur' }
        ],
      }
    }
  },
  created () {
    this.getQrcode()
  },
  components: {
    bind,
  },
  beforeDestroy () {
    this.$bus.$off('getInfo')
  },
  watch: {
    code(val,oldval){
      if(val === 0) {
        this.$message.success('登录成功')
        this.visible = false
        this.$bus.$emit('getInfo')
        // location.reload()
      } else if(val === 666) {
        this.$message('该二维码已过期，请点击二维码刷新！')
      }
      clearInterval(this.timeId)
    },
    loginType(val,oldval) {
      if(val) {
        this.getQrcode()
      }else {
        if(this.timeId) {
          clearInterval(this.timeId)
        }
      }
    },
    isAgreen(val) {
      if(!val && this.timeId) {
        clearInterval(this.timeId)
      }else if(val && this.loginType) {
        this.getQrcode()
      }
    },
    visible(val) {
      if(!val) {
        clearInterval(this.timeId)
        this.timeId = null
      }
    },
  },
  methods: {
    // 获取uuid
    getUuid (uuid, isWeb) {
      let idArr = [];
      let hexDigits = '0123456789abcdef';
      for (let i = 0; i < 32; i++) {
        idArr[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      if(isWeb) {
        return uuid = idArr.join('');
      }else {
        return uuid = 'web_' + idArr.join('');
      }
    },
    // 获取二维码
    async getQrcode () {
      this.uuid = this.getUuid(this.uuid)
      const res = await getPublicUrl({'sceneId': this.uuid})
      if(res && res.code === 0) {
        this.expireSeconds = new Date().getTime() + res.data.expireSeconds * 1000
        this.qrcodeUrl = res.data.qrCode
        this.sweepCode()
      }
    },
    // 扫码
    async sweepCode () {
      this.timeId = setInterval(async () => {
        const res = await login({'sceneId': this.uuid})
        if(res.data && res.code === 0) {
          this.code = res.code
          setLocal(res.data.token)
          // setLocal(JSON.stringify(res.data.roleIds), 'roleIds')
        } else {
          if(new Date().getTime() > this.expireSeconds) {
            this.code = 666
          }
          if(res.code == 6004) {
            this.$message('你已被禁用,请联系客服人员!!!')
          }
        }
      }, 5000)
    },
    // 刷新二维码
    refresh () {
      clearInterval(this.timeId)
      this.getQrcode()
    },
    // 手机号登录入口
    toLogin () {
      this.loginType = false
    },
    // 发送验证码
    async sendCode () {
      let params = JSON.stringify(this.form)
      if(!this.form.phone) {
        this.$message('请输入正确的手机号')
      }else {
        const res = await getPhoneCode(params)
        if(res && res.code === 0) {
          this.isSend = false
          this.countdown = 60
          let codeId = setInterval(() => {
            if(this.countdown === 0) {
              clearInterval(codeId)
              this.isSend = true
            }
            this.countdown--
          }, 1000);
        }
      }
    },
    // 手机登录
    toPhoneLogin () {
      if(this.isAgreen) {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            let params = JSON.stringify(this.form)
            const res = await phoneLogin(params)
            if(res && res.code === 0) {
              this.$message.success('登录成功')
              setLocal(res.data.token)
              if(getRoleIds().roleIds.includes(3)) {
                window.location.href = ''
              }else {
                location.reload()
              }
            } else {
              this.$message.error(res.msg)
              this.form.code = ''
            }
          }
        })
      }
    },
    // QQ登录
    qqLogin () {
      // 直接弹出授权页面，授权过后跳转到回调页面进行登录处理
      // let url = "https://graph.qq.com/oauth2.0/authorize?response_type=code&client_id=" + "101927618" + "&redirect_uri=" + process.env.VUE_APP_QQLOGIN
      // window.location.href = url
      this.$message('暂不支持QQ登录！')
    }
  }
}
</script>

<style lang="scss" scoped>
  .main {
    // width: 500px;
    // height: 500px;
    border-radius: 10px;
    background-color: #fff;
    text-align: center;
    padding: 10px 10px;
    .imgCode {
      height: 100%;
    }
    /deep/ .el-form-item__content {
      height: 40px;
    }
    .login-type {
      height: 66%;
    }
    .title-text {
      text-align: left;
    }
    .title {
      font-weight: 400;
      .wx-icon {
        width: 30px;
        height: 30px;
        margin-right: 5px;
      }
    }
    .title-wx {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    p {
      margin: 10px 0 20px;
      color: #666;
    }
    .qrcode {
      width: 170px;
      height: 170px;
    }
    .code {
      height: 170px;
      text-align: center;
      cursor: pointer;
    }
    .other {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0;
      li:first-child,
      li:last-child {
        width: 125px;
        height: 1px;
        border-bottom: 1px solid #ccc;
      }
      .other-text {
        font-size: 14px;
        color: #666;
        margin: 0 10px;
      }
    }
    .login-way {
      display: flex;
      justify-content: center;
      li {
        text-align: center;
        cursor: pointer;
        position: relative;
        &:first-child {
          margin-right: 50px;
        }
        &:hover p {
          color: #ff6700;
        }
        &:hover .phone,
        &:hover .wx,
        &:hover .qq {
          display: inline-block;
        }
        i {
          display: inline-block;
          width: 40px;
          height: 40px;
          text-align: 40px;
          line-height: 40px;
          border: 1px solid #ccc;
          border-radius: 50%;
        }
      }
    }
    .agreement {
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      input {
        margin-right: 3px;
      }
    }
    .tips {
      color: red;
      font-size: 12px;
      margin: 0;
      margin-left: 56px;
      text-align: left;
    }
    .toPhoneLogin {
      width: 100%;
    }
    .sendCode {
      width: 100%;
      margin-left: 12px;
    }
  }
  .wx-icon,
  .qq-icon {
    width: 40px;
    height: 40px;
  }
  .phone,
  .wx,
  .qq {
    position: absolute;
    top: 0;
    left: 8px;
    display: none;
  }
  .qq {
    left: 6px;
  }
  .bind {
    .title-text {
      text-align: center;
      margin-bottom: 40px;
    }
    /deep/ .el-form-item__content {
      margin-left: 80px;
    }
    .btn {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
  }
  /deep/ .el-dialog {
    border-radius: 16px;
  }
</style>