<template>
  <div class="home">
      <div class='header'>
          <menu-head></menu-head>
      </div>
      <router-view></router-view>
  </div>
</template>

<script>
// @ is an alias to /src
import MenuHead from '@/components/MenuHead.vue'

export default {
  name: 'Home',
  components: {
    MenuHead
  },
}
</script>
<style lang="scss" scoped>
.home{
   width: 100%;
   height: 100%;
  margin:auto;
  position: absolute;
  left: 0;
  right: 0;
  top:0;
  bottom: 0;
}

.header{
  height: 80px !important;
  text-align: center;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid rgb(219, 219, 219);
}

</style>
