var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "el-color-predefine" }, [
    _c(
      "div",
      { staticClass: "el-color-predefine__colors" },
      _vm._l(_vm.rgbaColors, function(item, index) {
        return _c(
          "div",
          {
            key: _vm.colors[index],
            staticClass: "el-color-predefine__color-selector",
            class: { selected: item.selected, "is-alpha": item._alpha < 100 },
            on: {
              click: function($event) {
                return _vm.handleSelect(index)
              }
            }
          },
          [_c("div", { style: { "background-color": item.value } })]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }