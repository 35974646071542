import Home from '../views/Home.vue'
export default [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: 'PrintingJoin',
        name: 'PrintingJoin',
        component: () => import(/* webpackChunkName: "PrintingJoin" */ '../views/PrintingJoin/PrintingJoin.vue'),
        meta: {
          isLogin: false
        }
      },
      {
        path: '',
        name: 'Index',
        component: () => import(/* webpackChunkName: "Index" */ '../views/Index/Index.vue'),
        meta: {
          isLogin: false
        }
      },
      // {
      //   path: 'OnlineDesign',
      //   name: 'OnlineDesign',
      //   component: () => import(/* webpackChunkName: "OnlineDesign" */ '../views/OnlineDesign/OnlineDesign.vue'),
      //   children: [
      //     {
      //       path: 'Selection',
      //       name: 'Selection',
      //       component: () => import(/* webpackChunkName: "OnlineDesign" */ '../views/OnlineDesign/Selection/Selection.vue'),
      //       meta: {
      //         isLogin: false
      //       }
      //     },
      //     {
      //       path: 'DesignClass/:id',
      //       name: 'DesignClass',
      //       component: () => import(/* webpackChunkName: "OnlineDesign" */ '../views/OnlineDesign/DesignClass/DesignClass.vue'),
      //       meta: {
      //         isLogin: false
      //       }
      //     },
      //     {
      //       path: 'DesignClassAll',
      //       name: 'DesignClassAll',
      //       component: () => import(/* webpackChunkName: "OnlineDesign" */ '../views/OnlineDesign/DesignClass/DesignClass.vue'),
      //       meta: {
      //         isLogin: false
      //       }
      //     },
      //     {
      //       path: 'topic',
      //       name: 'topic',
      //       component: () => import(/* webpackChunkName: "OnlineDesign" */ '../views/OnlineDesign/topic/topic.vue'),
      //       meta: {
      //         isLogin: false
      //       }
      //     },
      //     {
      //       path: '/',
      //       redirect: { name: 'Selection' }
      //     },
      //     {
      //       path: '*',
      //       redirect: { name: 'Selection' }
      //     }
      //   ]
      // },
      {
        path: 'home',
        name: 'home',
        component: () => import('../views/home/home.vue'),
        meta: {
          isLogin: false
        }
      },
      {
        path: 'templates',
        name: 'templates',
        component: () => import('../views/template/template.vue'),
        meta: {
          isLogin: false
        }
      },
      {
        path: 'MarketingActivities',
        name: 'MarketingActivities',
        component: () => import(/* webpackChunkName: "MarketingActivities" */ '../views/MarketingActivities/MarketingActivities.vue'),
        meta: {
          isLogin: false
        }
      },
      {
        path: 'ModalPrice',
        name: 'ModalPrice',
        component: () => import('../views/ModalPrice/ModalPrice.vue'),
        meta: {
          isLogin: false
        }
      },
      {
        path: 'OnlinePrinting',
        name: 'OnlinePrinting',
        component: () => import(/* webpackChunkName: "OnlinePrinting" */ '../views/OnlinePrinting/OnlinePrinting.vue'),
        meta: {
          isLogin: false
        },
        children: [
          {
            path: 'DisplayPoduct/:id',
            name: 'DisplayPoduct',
            component: () => import(/* webpackChunkName: "OnlinePrinting" */ '../views/OnlinePrinting/DisplayPoduct/DisplayPoduct.vue'),
            meta: {
              isLogin: false
            }
          },
          {
            path: 'DispalyAll',
            name: 'DispalyAll',
            component: () => import(/* webpackChunkName: "OnlinePrinting" */ '../views/OnlinePrinting/DispalyAll/DispalyAll.vue'),
            meta: {
              isLogin: false
            }
          },

          {
            path: 'TakeOrder',
            name: 'TakeOrder',
            component: () => import(/* webpackChunkName: "OnlinePrinting" */ '../views/OnlinePrinting/TakeOrder/TakeOrder.vue'),
            meta: {
              isLogin: false
            }
          }
        ]
      },
      {
        path: 'InfiniteQRCode',
        name: 'InfiniteQRCode',
        component: () => import(/* webpackChunkName: "InfiniteQRCode" */ '../views/InfiniteQRCode/InfiniteQRCode.vue'),
        meta: {
          isLogin: false
        }
      },
      // {
      //   path: 'business',
      //   name: 'business',
      //   component: () => import('../views/business/business.vue'),
      //   meta: {
      //     roles: [2]
      //   }
      // },
      {
        path: 'PersonalCenter',
        name: 'PersonalCenter',
        component: () => import(/* webpackChunkName: "OwnAddress" */ '../views/PersonalCenter/PersonalCenter.vue'),
        // meta: {
        //   roles: [1, 2]
        // },
        children: [
          {
            path: 'PrintOrder',
            name: 'PrintOrder',
            component: () => import(/* webpackChunkName: "PrintOrder" */ '../views/PersonalCenter/PrintOrder/PrintOrder.vue')
            // meta: {
            //   roles: [1, 2]
            // },
          },
          {
            path: 'OrderForMe',
            name: 'OrderForMe',
            component: () => import(/* webpackChunkName: "OrderForMe" */ '../views/PersonalCenter/OrderForMe/OrderForMe.vue')
            // meta: {
            //   roles: [1, 2]
            // },
          },
          {
            path: 'PrizeOrder',
            name: 'PrizeOrder',
            component: () => import(/* webpackChunkName: "PrizeOrder" */ '../views/PersonalCenter/PrizeOrder/PrizeOrder.vue')
            // meta: {
            //   roles: [1, 2]
            // },
          },
          {
            path: 'TemplateOrder',
            name: 'TemplateOrder',
            component: () => import(/* webpackChunkName: "TemplateOrder" */ '../views/PersonalCenter/TemplateOrder/TemplateOrder.vue')
            // meta: {
            //   roles: [1, 2]
            // },
          },
          {
            path: 'AccountInfo',
            name: 'AccountInfo',
            component: () => import(/* webpackChunkName: "AccountInfo" */ '../views/PersonalCenter/AccountInfo/AccountInfo.vue')
            // meta: {
            //   roles: [1, 2]
            // },
          },
          {
            path: 'CompanyInfo',
            name: 'CompanyInfo',
            component: () => import(/* webpackChunkName: "CompanyInfo" */ '../views/PersonalCenter/CompanyInfo/CompanyInfo.vue')
            // meta: {
            //   roles: [1, 2]
            // },
          },
          {
            path: 'ReceivingAddress',
            name: 'ReceivingAddress',
            component: () => import(/* webpackChunkName: "ReceivingAddress" */ '../views/PersonalCenter/ReceivingAddress/ReceivingAddress.vue')
            // meta: {
            //   roles: [1, 2]
            // },
          },
          {
            path: 'InvioceInfo',
            name: 'InvioceInfo',
            component: () => import(/* webpackChunkName: "InvioceInfo" */ '../views/PersonalCenter/InvioceInfo/InvioceInfo.vue')
            // meta: {
            //   roles: [1, 2]
            // },
          }
        ]
      },
      {
        path: 'OperationWorkbench',
        name: 'OperationWorkbench',
        component: () => import(/* webpackChunkName: "OperationWorkbench" */ '../views/OperationWorkbench/OperationWorkbench.vue'),
        // meta: {
        //   roles: [3]
        // },
        children: [
          {
            path: 'DemandOrderList',
            name: 'DemandOrderList',
            component: () => import(/* webpackChunkName: "DemandOrderList" */ '../views/OperationWorkbench/DemandOrderList.vue')
            // meta: {
            //   roles: [3]
            // },
          },
          {
            path: 'SetActive',
            name: 'SetActive',
            component: () => import(/* webpackChunkName: "DemandOrderList" */ '../views/OperationWorkbench/SetActive.vue')
            // meta: {
            //   roles: [3]
            // },
          }
        ]
      },
      {
        path: 'OwnWorkbench',
        name: 'OwnWorkbench',
        component: () => import(/* webpackChunkName: "OwnWorkbench" */ '../views/OwnWorkbench/OwnWorkbench.vue'),
        redirect: 'OwnWorkbench/OwnActivity',
        // meta: {
        //   roles: [1, 2, 3]
        // },
        children: [
          // {
          //   path: 'OwnDesign',
          //   name: 'OwnDesign',
          //   component: () => import(/* webpackChunkName: "OwnDesign" */ '../views/OwnWorkbench/OwnDesign/OwnDesign.vue')
          //   // meta: {
          //   //   roles: [1, 2]
          //   // },
          // },
          // {
          //   path: 'OwnTemplate',
          //   name: 'OwnTemplate',
          //   component: () => import(/* webpackChunkName: "OwnTemplate" */ '../views/OwnWorkbench/OwnTemplate/OwnTemplate.vue')
          //   // meta: {
          //   //   roles: [1, 2]
          //   // },
          // },
          // {
          //   path: 'OwnMaterial',
          //   name: 'OwnMaterial',
          //   component: () => import(/* webpackChunkName: "OwnMaterial" */ '../views/OwnWorkbench/OwnMaterial/OwnMaterial.vue')
          //   // meta: {
          //   //   roles: [1, 2]
          //   // },
          // },
          {
            path: 'OwnActivity',
            name: 'OwnActivity',
            component: () => import(/* webpackChunkName: "OwnActivity" */ '../views/OwnWorkbench/OwnActivity/OwnActivity.vue')
            // meta: {
            //   roles: [1, 2]
            // },
          },
          {
            path: 'CollectedTemp',
            name: 'CollectedTemp',
            component: () => import(/* webpackChunkName: "CollectedTemp" */ '../views/OwnWorkbench/CollectedTemp/CollectedTemp.vue')
            // meta: {
            //   roles: [1, 2]
            // },
          },
          {
            path: 'PrizeResources',
            name: 'PrizeResources',
            component: () => import(/* webpackChunkName: "PrizeResources" */ '../views/OwnWorkbench/PrizeResources/PrizeResources.vue')
            // meta: {
            //   roles: [1, 2]
            // },
          },
          {
            path: 'QRManagement',
            name: 'QRManagement',
            component: () => import(/* webpackChunkName: "QRManagement" */ '../views/OwnWorkbench/QRManagement/QRManagement.vue')
            // meta: {
            //   roles: [1, 2, 3]
            // },
          },
          {
            path: 'form',
            name: 'form',
            redirect: 'form/formCode',
            component: () => import('../views/OwnWorkbench/form/form.vue'),
            children: [
              {
                path: 'formCode',
                name: 'formCode',
                component: () => import('../views/OwnWorkbench/formCode/formCode.vue')
              },
              {
                path: 'formData',
                name: 'formData',
                component: () => import('../views/OwnWorkbench/formData/formData.vue')
              },
              {
                path: 'createform',
                name: 'createform',
                component: () => import('../views/createform/createform.vue')
              },
              {
                path: 'editform',
                name: 'editform',
                component: () => import('../views/createform/createform.vue')
              }
            ]
          }
        ]
      },
      {
        path: 'newsShow',
        name: 'newsShow',
        component: () => import('../views/myNews/newsShow.vue')
        // meta: {
        //   roles: [1, 2, 3]
        // },
      },
      {
        path: 'plantWorkbench',
        name: 'plantWorkbench',
        component: () => import('../views/plantWorkbench/plantWorkbench.vue'),
        // meta: {
        //   roles: [2]
        // },
        children: [
          {
            path: 'orderConfirm',
            name: 'orderConfirm',
            component: () => import('../views/plantWorkbench/orderConfirm/orderConfirm.vue')
            // meta: {
            //   roles: [2]
            // },
          },
          {
            path: 'myOrder',
            name: 'myOrder',
            component: () => import('../views/plantWorkbench/myOrder/myOrder.vue')
            // meta: {
            //   roles: [2]
            // },
          }
        ]
      },
      // {
      //   path: 'PoductDetail',
      //   name: 'PoductDetail',
      //   component: () => import(/* webpackChunkName: "PoductDetail" */ '../views/PoductDetail/PoductDetail.vue'),
      //   meta: {
      //     isLogin: false
      //   }
      //   // meta: {
      //   //   roles: [1, 2]
      //   // },
      // },
      // {
      //   path: 'DemandOrder',
      //   name: 'DemandOrder',
      //   component: () => import(/* webpackChunkName: "DemandOrder" */ '../views/DemandOrder/DemandOrder.vue')
      //   // meta: {
      //   //   roles: [1, 2]
      //   // },
      // },
      // {
      //   path: '/Order',
      //   name: 'OrderItem',
      //   component: () => import(/* webpackChunkName: "OrderItem" */ '../views/Order/OrderItem.vue')
      //   // meta: {
      //   //   roles: [1, 2]
      //   // },
      // },
      // {
      //   path: '/OrderItemList',
      //   name: 'OrderItemList',
      //   component: () => import(/* webpackChunkName: "OrderItemList" */ '../views/Order/OrderItemList.vue')
      // },

      {
        path: '/Payment',
        name: 'Payment',
        component: () => import(/* webpackChunkName: "Payment" */ '../views/Order/Payment.vue')
        // meta: {
        //   roles: [1, 2]
        // },
      },
      {
        path: '/ShoppingCart',
        name: 'ShoppingCart',
        component: () => import(/* webpackChunkName: "ShoppingCart" */ '../views/Order/ShoppingCart.vue')
        // meta: {
        //   roles: [1, 2]
        // },
      }
    ]
  },
  // {
  //   path: '/PostersEdit',
  //   name: 'PostersEdit',
  //   component: () => import(/* webpackChunkName: "PostersEdit" */ '../views/DesignEditor/PostersEdit.vue'),
  //   meta: {
  //     isLogin: false
  //   }
  //   // meta: {
  //   //   roles: [1, 2]
  //   // },
  // },
  {
    path: '/MarketingActivityEdit',
    name: 'MarketingActivityEdit',
    component: () => import(/* webpackChunkName: "MarketingActivityEdit" */ '../views/MarketingActivityEdit/MarketingActivityEdit.vue'),
    meta: {
      isLogin: false
    }
    // meta: {
    //   roles: [1, 2]
    // },
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import(/* webpackChunkName: "login" */ '../views/login/login.vue'),
  //   meta: {
  //     title: '登录',
  //   }
  // },
  {
    path: '/callback/qqCallback',
    name: 'qqCallback',
    component: () => import(/* webpackChunkName: "qqCallback" */ '../views/callback/qqCallback.vue'),
    meta: {
      isLogin: false
    }
  },
  {
    path: '/ActivityData/:status',
    name: 'ActivityData',
    component: () => import(/* webpackChunkName: "ActivityData" */ '../views/ActivityData/ActivityData.vue')
    // meta: {
    //   roles: [1, 2]
    // },
  },
  {
    path: '/ActivityDetail',
    name: 'ActivityDetail',
    component: () => import(/* webpackChunkName: "ActivityDetail" */ '../views/ActivityDetail/ActivityDetail.vue')
    // meta: {
    //   roles: [1, 2]
    // },
  },
  {
    path: '/PrivacyAgreement',
    name: 'PrivacyAgreement',
    component: () => import(/* webpackChunkName: "PrivacyAgreement" */ '../views/Agreement/PrivacyAgreement.vue'),
    meta: {
      isLogin: false
    }
  },
  {
    path: '/UserAgreement',
    name: 'UserAgreement',
    component: () => import(/* webpackChunkName: "UserAgreement" */ '../views/Agreement/UserAgreement.vue'),
    meta: {
      isLogin: false
    }
  },
  {
    path: '/activityMonitor',
    name: 'activityMonitor',
    component: () => import('../views/activityMonitor/activityMonitor.vue'),
    meta: {
      isLogin: false
    }
  },
  {
    path: '/marketingActivity',
    name: 'marketingActivity',
    component: () => import('../views/marketingActivity/marketingActivity.vue'),
    children: [
      {
        path: 'OwnActivity',
        name: 'OwnActivity',
        component: () => import(/* webpackChunkName: "OwnActivity" */ '../views/OwnWorkbench/OwnActivity/OwnActivity.vue')
      },
      {
        path: 'CollectedTemp',
        name: 'CollectedTemp',
        component: () => import(/* webpackChunkName: "CollectedTemp" */ '../views/OwnWorkbench/CollectedTemp/CollectedTemp.vue')
      },
      {
        path: 'PrizeResources',
        name: 'PrizeResources',
        component: () => import(/* webpackChunkName: "PrizeResources" */ '../views/OwnWorkbench/PrizeResources/PrizeResources.vue')
      }
    ]
  },
  {
    path: '/largeScreen',
    name: 'largeScreen',
    component: () => import('../views/largeScreen/largeScreen.vue')
  },
  {
    path: '*',
    redirect: { name: 'Index' }
  }
]
