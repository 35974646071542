import PositionUtil from '../../lib/PositionUtil'
import MathUtil from '../../lib/MathUtil'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
let layerInfo = {}
let groupParameter = {}
class EditUtil {
  /**
   * 添加新图层
   * @param {*} layer
   */
  static pushLayer (state, layer) {
    // 添加图片

    switch (layer.type) {
      case 'image':
        layer.imageId = uuidv4()
        state.postInfo.images.push(layer)
        break
      case 'code':
        layer.codeId = uuidv4()
        state.postInfo.codes.push(layer)
        break
      case 'text':
        layer.textId = uuidv4()
        state.postInfo.texts.push(layer)
        break
    }
  }

  /**
   * 添加顶级图层的层级
   * @param {*} state
   * @returns
   */
  static getNewZIndex (state) {
    return [...state.postInfo.images, ...state.postInfo.texts, ...state.postInfo.codes].length
  }

  /**
   * 得到所有图层信息
   * @param {} state
   * @returns
   */
  static getLayers (state) {
    layerInfo = {}
    const layers = [];
    [...state.postInfo.images, ...state.postInfo.texts, ...state.postInfo.codes].forEach(ele => {
      layers[ele.ZIndex] = ele
    })
    let index = layers.length - 1
    const newLayers = []
    const groupInfo = {}
    while (index >= 0) {
      const item = layers[index--]

      if (item) {
        layerInfo[item.id] = item
        if (item.groupId) {
          if (groupInfo[item.groupId]) {
            groupInfo[item.groupId].unshift(item)
          } else {
            const group = [item]
            layerInfo[item.groupId] = group
            groupInfo[item.groupId] = group
            newLayers.push(group)
          }
        } else {
          newLayers.push(item)
        }
      }
    }
    return newLayers.reverse()
  }

  /**
   * 获得所有单层图层
   */
  static getAllSingleLayer (state) {
    const layers = [];
    [...state.postInfo.images, ...state.postInfo.texts, ...state.postInfo.codes].forEach(ele => {
      layers[ele.ZIndex] = ele
    })
    return layers
  }

  /**
   * 重新调整图层
   * @param {} state
   */
  static adjustLayers (state) {
    const newLayers = EditUtil.getLayers(state)
    EditUtil.initLayer(newLayers)
  }

  /**
   * 初始化图层
   * @param {*} newLayers
   */
  static initLayer (newLayers) {
    newLayers.flat(Infinity).forEach((ele, index) => {
      if (ele) {
        ele.ZIndex = index
      }
    })
  }

  /**
   * 图层上移
   * @param {*} state
   * @param {*} id
   * @returns
   */
  static upper (state, id) {
    const newLayers = EditUtil.getLayers(state)
    const length = newLayers.length
    let editModel = layerInfo[id]
    if (editModel.groupId) {
      editModel = layerInfo[editModel.groupId]
    }
    const index = newLayers.findIndex(item => { return editModel === item })
    if (index === length - 1) {
      return
    }
    [newLayers[index], newLayers[index + 1]] = [newLayers[index + 1], newLayers[index]]
    EditUtil.initLayer(newLayers)
  }

  /**
 * 图层下移
 * @param {*} state
 * @param {*} id
 * @returns
 */
  static down (state, id) {
    const newLayers = EditUtil.getLayers(state)
    let editModel = layerInfo[id]
    if (editModel.groupId) {
      editModel = layerInfo[editModel.groupId]
    }

    const index = newLayers.findIndex(item => { return editModel === item })
    if (index === 0) {
      return
    }
    [newLayers[index], newLayers[index - 1]] = [newLayers[index - 1], newLayers[index]]
    EditUtil.initLayer(newLayers)
  }

  /**
 * 图层置顶
 * @param {*} state
 * @param {*} id
 * @returns
 */
  static top (state, id) {
    const newLayers = EditUtil.getLayers(state)
    let editModel = layerInfo[id]
    if (editModel.groupId) {
      editModel = layerInfo[editModel.groupId]
    }

    const length = newLayers.length
    const index = newLayers.findIndex(item => { return editModel === item })
    if (index === length - 1) {
      return
    }
    const layer = newLayers.splice(index, 1)
    newLayers.push(layer)
    EditUtil.initLayer(newLayers)
  }

  /**
 * 图层置底
 * @param {*} state
 * @param {*} id
 * @returns
 */
  static bottom (state, id) {
    const newLayers = EditUtil.getLayers(state)
    let editModel = layerInfo[id]
    if (editModel.groupId) {
      editModel = layerInfo[editModel.groupId]
    }
    const index = newLayers.findIndex(item => { return editModel === item })
    if (index === 0) {
      return
    }
    const layer = newLayers.splice(index, 1)
    newLayers.unshift(layer)
    EditUtil.initLayer(newLayers)
  }

  /**
 * 拆开组
 * @param {}} state
 */
  static clearGroup (state) {
    const groups = {};
    [...state.postInfo.images, ...state.postInfo.texts, ...state.postInfo.codes].forEach(layer => {
      if (layer.groupId) {
        groups[layer.groupId] = true
      }
    })
    state.postInfo.groups = state.postInfo.groups.filter(group => {
      return groups[group.id]
    })
  }

  /**
 * 初始化组合内图层信息
 * @param {*} state
 * @param {*} group
 */
  static initGroupParameter (state, group) {
    EditUtil.getLayers(state)
    groupParameter = {

    }

    const moduleCenerPosition = PositionUtil.getCenterPosition(
      group.left,
      group.top,
      group.width,
      group.height
    )
    group.layerIds.forEach(id => {
      const item = layerInfo[id]

      if (!item) {
        return
      }
      const itemLengthInfo = PositionUtil.getPositionInfoByTwoPoint(
        moduleCenerPosition,
        PositionUtil.getCenterPosition(
          item.left,
          item.top,
          item.width,
          item.height
        )
      )
      const innerAngle = itemLengthInfo.angle - group.rotate
      const itemInfo = {}
      itemInfo.width = item.width / group.width
      itemInfo.height = item.height / group.height
      itemInfo.centerLeft = (itemLengthInfo.length * MathUtil.cos(innerAngle) +
        group.width / 2) /
        group.width
      itemInfo.centerTop = (group.height / 2 +
        itemLengthInfo.length * MathUtil.sin(innerAngle)) /
        group.height
      itemInfo.rotate = item.rotate - group.rotate
      if (item.type === 'text') {
        itemInfo.fontSize = item.fontSize / group.width
        itemInfo.letterSpacing = item.letterSpacing / group.width
      }
      groupParameter[item.id] = itemInfo
    })
  }

  /**
 * 重置组合内图层位置，大小
 * @param {*} state
 * @param {*} group
 */
  static resetGroupItem (state, group) {
    group.layerIds.forEach(id => {
      const item = layerInfo[id]
      const orgItem = groupParameter[item.id]
      item.rotate = (orgItem.rotate + group.rotate) % 360
      const width = (orgItem.centerLeft - 0.5) * group.width
      const height = (0.5 - orgItem.centerTop) * group.height
      const hypotenuse = MathUtil.getHypotenuse(width, height)
      const innerAngle = MathUtil.atan(height / width) - 180
      let angle = innerAngle - group.rotate
      if (orgItem.centerLeft < 0.5) {
        angle += 180
      }
      const centerPosition = PositionUtil.getPositionbyCenter(angle, hypotenuse, {
        left: group.left + group.width / 2,
        top: group.top + group.height / 2
      })
      item.left = centerPosition.left - item.width / 2
      item.top = centerPosition.top - item.height / 2
      item.width = group.width * orgItem.width
      item.height = group.height * orgItem.height
      if (item.type === 'text') {
        item.fontSize = group.width * orgItem.fontSize
        item.letterSpacing = group.width * orgItem.letterSpacing
      }
    })
  }

  /**
   * 初始化组合大小，位置信息
   * @param {*} state
   * @param {*} group
   */
  static initGroupSize (state, group) {
    if (!group) {
      return
    }
    const pointList = []
    const layers = [...state.postInfo.images, ...state.postInfo.texts, ...state.postInfo.codes]
    group.layerIds.forEach(id => {
      const item = layers.find(layer => { return layer.id === id })
      const res = PositionUtil.getPosition(item.left + item.width / 2, item.top + item.height / 2, item.width, item.height, item.rotate)
      pointList.push(res.leftTop)
      pointList.push(res.leftBottom)
      pointList.push(res.rightTop)
      pointList.push(res.rightBottom)
    })
    const anglePositionInfo = PositionUtil.getGroupPositionInfo(pointList, state.group.rotate)
    group.width = anglePositionInfo.width
    group.height = anglePositionInfo.height
    group.top = anglePositionInfo.top
    group.left = anglePositionInfo.left
  }

  /**
   * 水平分布
   * @param {}} state
   * @param {*} layers
   */
  static horizontaldistribution (state, layers) {
    const groupWidth = state.group.width
    let allWidth = 0
    const positionArr = []
    const groupMap = {}
    layers.forEach(item => {
      if (item.groupId) {
        if (groupMap[item.groupId]) {
          return
        }
        groupMap[item.groupId] = item
        item = state.postInfo.groups.find(group => {
          return group.id === item.groupId
        })
        EditUtil.initGroupSize(state, item)
      }
      const res = PositionUtil.getPosition(item.left + item.width / 2, item.top + item.height / 2, item.width, item.height, item.rotate)
      allWidth += (res.most.maxLeft - res.most.minLeft)
      positionArr.push({
        top: res.most.minTop,
        left: res.most.minLeft,
        width: res.most.maxLeft - res.most.minLeft,
        height: res.most.maxTop - res.most.minTop,
        layer: item
      })
    })
    positionArr.sort((layero, layerw) => { return layero.left - layerw.left })
    const sizeRate = groupWidth / allWidth
    let left = 0
    positionArr.forEach((item, index) => {
      if (item.layer.type === 'group') {
        EditUtil.initGroupParameter(state, item.layer)
      }
      const otherLength = groupWidth - item.width * sizeRate
      item.layer.left = state.group.left + left - (1 - sizeRate) * item.width * left / otherLength + item.width / 2 - item.layer.width / 2
      left += item.width * sizeRate
      if (item.layer.type === 'group') {
        console.log(item)
        EditUtil.resetGroupItem(state, item.layer)
      }
    })
  }

  /**
   * 垂直分布
   * @param {}} state
   * @param {*} layers
   */
  static verticaldistribution (state, layers) {
    const groupHeight = state.group.height
    let allHeight = 0
    const positionArr = []
    const groupMap = {}
    layers.forEach(item => {
      if (item.groupId) {
        if (groupMap[item.groupId]) {
          return
        }
        groupMap[item.groupId] = item
        item = state.postInfo.groups.find(group => {
          return group.id === item.groupId
        })
        EditUtil.initGroupSize(state, item)
      }
      const res = PositionUtil.getPosition(item.left + item.width / 2, item.top + item.height / 2, item.width, item.height, item.rotate)
      allHeight += (res.most.maxTop - res.most.minTop)
      positionArr.push({
        top: res.most.minTop,
        left: res.most.minLeft,
        width: res.most.maxLeft - res.most.minLeft,
        height: res.most.maxTop - res.most.minTop,
        layer: item
      })
    })
    positionArr.sort((layero, layerw) => { return layero.top - layerw.top })
    const sizeRate = groupHeight / allHeight
    let top = 0
    positionArr.forEach((item, index) => {
      if (item.layer.type === 'group') {
        EditUtil.initGroupParameter(state, item.layer)
      }
      const otherLength = groupHeight - item.height * sizeRate
      item.layer.top = state.group.top + top - (1 - sizeRate) * item.height * top / otherLength + item.height / 2 - item.layer.height / 2
      top += item.height * sizeRate
      // console.log(item)
      if (item.layer.type === 'group') {
        // EditUtil.initGroupParameter(state, item.layer)
        EditUtil.resetGroupItem(state, item.layer)
      }
    })
  }

  /**
   * 得到组合中图层的副本
   * @param {}} state
   */
  static getCopyGroupLayers (state) {
    const layers = {};
    [...state.postInfo.images, ...state.postInfo.texts, ...state.postInfo.codes].forEach(ele => {
      layers[ele.id] = ele
    })
    if (!state.group) {
      return []
    }
    return state.group.layerIds.map(item => {
      return _.cloneDeep(layers[item])
    })
  }

  /**
   * 粘贴组成员
   * @param {*} state
   */
  static pasteGroup (state) {
    const copyElement = _.cloneDeep(state.copyElement)
    if (copyElement.type !== 'group') {
      return
    }
    let group
    if (copyElement.id) {
      group = _.cloneDeep(copyElement)
      group.id = uuidv4()
    }
    const layers = copyElement.layers
    const newZindex = EditUtil.getNewZIndex(state)
    const layerIds = []
    layers.forEach(item => {
      item.id = uuidv4()
      layerIds.push(item.id)
      item.ZIndex += newZindex
      item.groupId = group ? group.id : undefined
      EditUtil.pushLayer(state, item)
    })
    if (group) {
      if (!state.postInfo.groups) {
        state.postInfo.groups = []
      }
      group.layerIds = layerIds
      state.postInfo.groups.push(group)
    }

    EditUtil.adjustLayers(state)
  }

  /**
   * 通过ID查询组合
   * @param {*} state
   * @param {*} id
   */
  static findGroupBygroupId (state, id) {
    return state.postInfo.groups.find(item => {
      return item.id === id
    })
  }

  /**
   * 删除模块
   * @param {} state
   * @param {*} module
   */
  static deleteModule (state, editModule) {
    let modelIndex
    if (editModule.type === 'image') {
      state.postInfo.images.forEach((ele, index) => {
        // eslint-disable-next-line eqeqeq
        if (ele.id == state.editSelectedId) {
          modelIndex = index
        }
      })
      state.postInfo.images.splice(modelIndex, 1)
    }
    if (editModule.type === 'text') {
      state.postInfo.texts.forEach((ele, index) => {
        // eslint-disable-next-line eqeqeq
        if (ele.id == state.editSelectedId) {
          modelIndex = index
        }
      })
      state.postInfo.texts.splice(modelIndex, 1)
    }
    if (editModule.type === 'code') {
      state.postInfo.codes.forEach((ele, index) => {
        // eslint-disable-next-line eqeqeq
        if (ele.id == state.editSelectedId) {
          modelIndex = index
        }
      })
      state.postInfo.codes.splice(modelIndex, 1)
    }
  }
}

export default EditUtil
