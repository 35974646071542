<template>
  <transition name="el-zoom-in-top" @after-leave="doDestroy">
    <div class="el-color-dropdown" v-show="showPopper">
      <div class="el-color-dropdown__main-wrapper">
        <hue-slider
          ref="hue"
          :color="color"
          vertical
          style="float: right"
        ></hue-slider>
        <sv-panel ref="sl" :color="color"></sv-panel>
      </div>
      <alpha-slider v-if="showAlpha" ref="alpha" :color="color"></alpha-slider>
      <predefine
        v-if="predefine"
        :color="color"
        :colors="predefine"
      ></predefine>
      <div class="el-color-dropdown__btns">
        <span class="el-color-dropdown__value">
          <el-input
            v-model="customInput"
            @keyup.native.enter="handleConfirm"
            @keydown.stop
            @blur="handleConfirm"
            :validate-event="false"
            size="mini"
          >
          </el-input>
        </span>
      </div>
      <div class="pen-content" @click="openPenFunc">
        <img src="@/assets/img/colorsucker.png" alt="" />
        <div v-if="colorInfo.loading" class="loading">
          <i class="el-icon-loading"></i>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import SvPanel from './sv-panel.vue';
import HueSlider from './hue-slider.vue';
import AlphaSlider from './alpha-slider.vue';
import Predefine from './predefine.vue';
import Popper from 'element-ui/src/utils/vue-popper';
import Locale from 'element-ui/src/mixins/locale';
import ElInput from 'element-ui/packages/input';
import ElButton from 'element-ui/packages/button';
import { mapMutations } from 'vuex'
export default {
  name: 'el-color-picker-dropdown',

  mixins: [Popper, Locale],

  components: {
    SvPanel,
    HueSlider,
    AlphaSlider,
    ElInput,
    ElButton,
    Predefine,

  },

  props: {
    color: {
      required: true
    },
    showAlpha: Boolean,
    predefine: Array
  },

  data () {
    return {
      customInput: '',
      colorInfo: {
        color: this.color,
        loading: false
      },

    };
  },

  computed: {
    currentColor () {
      const parent = this.$parent;
      return !parent.value && !parent.showPanelColor ? '' : parent.color.value;
    }
  },

  methods: {
    ...mapMutations('edit', ['setColorPicker', 'setColorInfo']),
    /**
     * 取色笔操作
     */
    openPenFunc () {
      this.colorInfo.loading = true
      setTimeout(() => {
        this.setColorPicker(true)
        this.setColorInfo(this.colorInfo)
      }, 0)

    },
    confirmValue () {
      this.$emit('pick');
    },

    handleConfirm () {
      this.color.fromString(this.customInput);
    }
  },

  mounted () {
    this.$parent.popperElm = this.popperElm = this.$el;
    this.referenceElm = this.$parent.$el;
  },

  watch: {
    'colorInfo.color' (val) {
      this.customInput = val
      this.handleConfirm()
    },
    showPopper (val) {
      if (val === true) {
        this.$nextTick(() => {
          const { sl, hue, alpha } = this.$refs;
          sl && sl.update();
          hue && hue.update();
          alpha && alpha.update();
        });
      }
    },

    currentColor: {
      immediate: true,
      handler (val) {
        this.customInput = val;
      }
    },

  }
};
</script>
<style lang="scss" scoped>
.pen-content {
  width: 30px;
  height: 30px;
  float: right;
  cursor: pointer;
  border: 1px solid rgb(212, 212, 212);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative;
}
.loading {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(221, 221, 221);
  color: rgb(0, 225, 255);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
