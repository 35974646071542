import { getRoleIds } from '@/lib/util.js'

export default{
  setUserInfo(state) {
    state.userInfo.roleIds = getRoleIds().roleIds
    state.userInfo.headImgUrl = getRoleIds().headImgUrl
    state.userInfo.nickName = getRoleIds().nickName
  },
  setShow (state, payload) {
    state.show = payload
  }
}
