import Vue from 'vue'
import {
  Container,
  Header,
  Aside,
  Main,
  Input,
  Link,
  Select,
  Option,
  Button,
  Badge,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Pagination,
  Tabs,
  TabPane,
  Table,
  TableColumn,
  Tag,
  Radio,
  ColorPicker,
  Slider,
  Switch,
  Checkbox,
  CheckboxGroup,
  Form,
  FormItem,
  Col,
  Row,
  DatePicker,
  RadioGroup,
  Upload,
  Progress,
  Scrollbar,
  Dialog,
  Divider,
  Steps,
  Step,
  Message,
  MessageBox,
  Alert,
  Card,
  Popover,
  Loading,
  Cascader,
  InputNumber,
  Tooltip,
  Image,
  Carousel,
  CarouselItem,
  Collapse,
  CollapseItem,
  RadioButton
} from 'element-ui'
import { use } from 'echarts'
// Vue.component(Loading.name, Loading);
Vue.component(Container.name, Container)
Vue.component(Header.name, Header)
Vue.component(Aside.name, Aside)
Vue.component(Main.name, Main)
Vue.component(Input.name, Input)
Vue.component(Select.name, Select)
Vue.component(Option.name, Option)
Vue.component(Button.name, Button)
Vue.component(Dropdown.name, Dropdown)
Vue.component(DropdownMenu.name, DropdownMenu)
Vue.component(DropdownItem.name, DropdownItem)
Vue.component(Pagination.name, Pagination)
Vue.component(Tabs.name, Tabs)
Vue.component(TabPane.name, TabPane)
Vue.component(Table.name, Table)
Vue.component(TableColumn.name, TableColumn)
Vue.component(Radio.name, Radio)
Vue.component(ColorPicker.name, ColorPicker)
Vue.component(Slider.name, Slider)
Vue.component(Form.name, Form)
Vue.component(FormItem.name, FormItem)
Vue.component(Col.name, Col)
Vue.component(DatePicker.name, DatePicker)
Vue.component(RadioGroup.name, RadioGroup)
Vue.component(Upload.name, Upload)
Vue.component(Scrollbar.name, Scrollbar)
Vue.component(Dialog.name, Dialog)
Vue.component(Steps.name, Steps)
Vue.component(Step.name, Step)
Vue.component(Message.name, Message)
Vue.component(Checkbox.name, Checkbox)
Vue.component(CheckboxGroup.name, CheckboxGroup)
Vue.component(Alert.name, Alert)
Vue.component(Card.name, Card)
Vue.component(Popover.name, Popover)
Vue.component(MessageBox.name, MessageBox)
Vue.use(Tag)
Vue.use(Progress)
Vue.use(Link)
Vue.use(Switch)
Vue.use(Divider)
Vue.use(Badge)
Vue.use(Tooltip)
Vue.use(Loading)
Vue.use(Image)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(RadioButton)
Vue.component(Cascader.name, Cascader)
Vue.component(InputNumber.name, InputNumber)
Vue.component(Row.name, Row)

Vue.prototype.$message = Message
Vue.prototype.$loading = Loading.service
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
