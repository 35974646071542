var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "el-color-svpanel",
      style: {
        backgroundColor: _vm.background
      }
    },
    [
      _c("div", { staticClass: "el-color-svpanel__white" }),
      _c("div", { staticClass: "el-color-svpanel__black" }),
      _c(
        "div",
        {
          staticClass: "el-color-svpanel__cursor",
          style: {
            top: _vm.cursorTop + "px",
            left: _vm.cursorLeft + "px"
          }
        },
        [_c("div")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }