
export default [
  {
    path: '/',
    name: 'PostersEdit',
    component: () => import(/* webpackChunkName: "PostersEdit" */ '../views/DesignEditor/PostersEdit.vue')

  },
  {
    path: '/PostersEdit',
    name: 'PostersEdit',
    component: () => import(/* webpackChunkName: "PostersEdit" */ '../views/DesignEditor/PostersEdit.vue')
    // meta: {
    //   roles: [1, 2]
    // },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/login.vue'),
    meta: {
      title: '登录'
    }
  }

]
