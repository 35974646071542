import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './common/sass/index.scss'
import './common/font/font.scss'
import './common/editIconfont/iconfont.css'
import './common/iconfont/iconfont.css'
import './lib/elementUi'
import '@/directive/directive'
import ColorPicker from './views/DesignEditor/component/common/color-picker'
import VueClipboard  from 'vue-clipboard2'   // 复制
import Login from './modules/index'
import '@/filter/filter.js'
// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'


import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)
Vue.use(ColorPicker)
Vue.use( VueClipboard )
// Vue.use(Login)
Vue.config.productionTip = false
Vue.prototype.$bus = new Vue()
Vue.prototype.$url = process.env.VUE_APP_URL
Vue.prototype.$Login = Login

Vue.directive('down', {
  inserted: (el, binding) => {
    el.style.cssText = 'cursor: pointer;color:write;'
    el.addEventListener('click', () => {
      let link = document.createElement('a')
      let url = binding.value
      // 这里是将url转成blob地址，
      fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
        link.href = URL.createObjectURL(blob)
        link.download = ''
        document.body.appendChild(link)
        link.click()
      })
    })
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
