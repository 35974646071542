var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "null-box" }, [
    _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
      _c("img", { attrs: { src: _vm.follow, alt: "" } })
    ]),
    _c("div", { staticStyle: { color: "#dbdbdb" } }, [_vm._v(_vm._s(_vm.text))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }