import Request from '@/lib/Request'
// const http = new Request('https://cubewebapi.51ylk.com', 3000)
// const http = new Request('http://192.168.1.65:8090',3000)
const http = new Request(process.env.VUE_APP_URL)
// 获取二维码
export const getPublicUrl = params => {
  return http.get('/cube_web_api/auth/wx/wxLoginGetQrCode', params)
}
// 扫码登录
export const login = params => {
  return http.get('/cube_web_api/auth/wx/pollingGetWxInfo', params)
}
// 获取图形验证码
export const getVerificationCode = params => {
  return http.get('/cube_web_api/generate/captcha.jpg', params)
}
// 获取手机验证码
export const getPhoneCode = params => {
  return http.post('/cube_web_api/auth/send/sms', params)
}
// 手机登录
export const phoneLogin = params => {
  return http.post('/cube_web_api/auth/phone/login', params)
}
// qq登录
export const toqqLogin = params => {
  return http.get('/cube_web_api/auth/qqLogin/qqLoginByCode', params)
}
