import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import edit from './modules/edit'
import market from './modules/market'
import { createForm } from './modules/createForm'
Vue.use(Vuex)

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    edit,
    market,
    createForm
  }
})
