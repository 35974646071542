import Request from '@/lib/Request'
// const http = new Request('https://cubewebapi.51ylk.com', 3000)
// const http = new Request('http://192.168.1.65:8090', 3000)
const http = new Request(process.env.VUE_APP_URL)

// 查询用户信息
export const getUserInfo = params => {
  return http.get('/cube_web_api/login/user/queryBaseInfo', params)
}
// 手机号绑定
export const bindPhone = params => {
  return http.post('/cube_web_api/login/user/bindPhone', params)
}
// QQ绑定
export const bindQq = params => {
  return http.get('/cube_web_api/login/user/bindQq', params)
}
// 微信绑定
export const bindWx = params => {
  return http.get('/cube_web_api/login/user/bindWx', params)
}
// 收货地址查询
export const queryAddressByPage = params => {
  return http.post('/cube_web_api/login/user/queryAddressByPage', params)
}
// 新增收货地址
export const saveAddress = params => {
  return http.post('/cube_web_api/login/user/saveAddress', params)
}
// 设为默认收货地址
export const setDefaultAddress = params => {
  return http.get('/cube_web_api/login/user/setDefaultAddress', params)
}
// 获取编辑信息
export const getEditAddress = params => {
  return http.get('/cube_web_api/login/user/queryOneAddress', params)
}
// 更新收货地址
export const updateAddress = params => {
  return http.post('/cube_web_api/login/user/updateAddress', params)
}
// 删除地址
export const deleteAddress = params => {
  return http.get('/cube_web_api/login/user/delAddress', params)
}

// 发票信息查询
export const getInvoiceInfo = params => {
  return http.post('/cube_web_api/sys/invoice/listByPage', params)
}
// 新增发票
export const saveInvoiceInfo = params => {
  return http.post('/cube_web_api/sys/invoice/save', params)
}
// 设为默认发票
export const setDefault = params => {
  return http.post('/cube_web_api/sys/invoice/setDefault', params)
}
// 编辑发票
export const updateInvoice = params => {
  return http.post('/cube_web_api/sys/invoice/update', params)
}
// 删除发票
export const deleteInvoice = params => {
  return http.post('/cube_web_api/sys/invoice/delete', params)
}
// 获取编辑信息
export const getEditInfo = params => {
  return http.get('/cube_web_api/sys/invoice/info', params)
}

// 企业信息查询
export const getCompanyInfo = params => {
  return http.post('/cube_web_api/login/user/findEnterpriseInfo', params)
}
// 企业信息保存
export const saveCompanyInfo = params => {
  return http.post('/cube_web_api/login/user/updateEnterpriseInfo', params)
}
