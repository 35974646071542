var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.bindVisible, width: "508px" },
      on: {
        "update:visible": function($event) {
          _vm.bindVisible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "main bind" }, [
        _c(
          "div",
          { staticClass: "login-type" },
          [
            _c("div", { staticClass: "title-text" }, [
              _c("h2", { staticClass: "title" }, [_vm._v(" 绑定手机号码 ")]),
              _c("p", [_vm._v("根据国家相关规定，您需要绑定手机号码")])
            ]),
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  model: _vm.form,
                  rules: _vm.rules,
                  "label-position": "right",
                  "label-width": "95px"
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { prop: "phone", label: "手机号码：" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入手机号码" },
                      model: {
                        value: _vm.form.phone,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "phone", $$v)
                        },
                        expression: "form.phone"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "验证码：", prop: "code" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 16 } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入短信验证码" },
                          nativeOn: {
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.toBind($event)
                            }
                          },
                          model: {
                            value: _vm.form.code,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "code", $$v)
                            },
                            expression: "form.code"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _vm.isSend
                          ? _c(
                              "el-button",
                              {
                                staticClass: "sendCode",
                                attrs: { type: "primary" },
                                on: { click: _vm.sendCode }
                              },
                              [_vm._v("发送验证码")]
                            )
                          : _c(
                              "el-button",
                              {
                                staticClass: "sendCode",
                                attrs: { type: "primary", disabled: "" }
                              },
                              [_vm._v(_vm._s(this.countdown + "s"))]
                            )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "btn" },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "35%" },
                        attrs: { type: "primary" },
                        on: { click: _vm.toBind }
                      },
                      [_vm._v("确认")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "35%" },
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            _vm.bindVisible = false
                          }
                        }
                      },
                      [_vm._v("取消")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }