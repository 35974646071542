class Tool {
  constructor() {

  }

  static isPhoneNumber (tel) {
    var reg = /^0?1[3|4|5|6|7|8][0-9]\d{8}$/
    return reg.test(tel)
  }

  static getFontFullName (family, style) {
    if (!family) {
      return ''
    }
    if (!style) {
      return family
    }
    return `${family}-${style}`
  }

  static isEmpty (obj) {
    return obj === null || obj === undefined || obj === '';
  }
}

export default Tool
