import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import fontList from '@/lib/fontList'
import { updateBatch } from '@/api/cubeclient'
import config from '@/config'
import canvas from '@/config/canvas'
import postToImage from '@/lib/postToImage'
import PositionUtil from '@/lib/PositionUtil'
import EditUtil from './editUtil'
const fontMap = {}
fontList.forEach(e => {
  // fontMap[Tool.getFontFullName(e.fontFamily,e.fontStyle)] = e
  fontMap[e.fontFamily] = e
})
/**
 * 腾讯艳图片地址转为CDN地址
 * @param {*} src
 * @returns
 */
function getFormatImageSrc (src) {
  src = src.replace('https://cube-test-1257418739.cos.ap-guangzhou.myqcloud.com', 'https://cube-test-1257418739.file.myqcloud.com')
  src = src.replace('http://cube-test-1257418739.cos.ap-guangzhou.myqcloud.com', 'https://cube-test-1257418739.file.myqcloud.com')
  src = src.replace('https://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com', 'https://cube-prod-1257418739.file.myqcloud.com')
  src = src.replace('http://cube-prod-1257418739.cos.ap-guangzhou.myqcloud.com', 'https://cube-prod-1257418739.file.myqcloud.com')
  return src
}
/**
 * 编辑器公共内容
 */
export default {
  namespaced: true,
  state: {
    editSelectedId: undefined, // 被选择模块ID
    editModule: {}, // 当前编辑模块
    scale: 100, // 放大情况
    canvas: {}, // 画布信息
    historyRecord: [], // 历史记录集合
    nextRecord: [], // 下一步
    postInfo: {}, // 总海报信息
    postList: [], // 海报集合
    editModuleList: [], // 多项编辑
    index: 0,
    cropImage: false, // 是否裁剪操作
    postJsonId: undefined,
    editSize: { // 编辑面板大小，下面是默认值
      editWidth: 1400,
      editHeight: 873
    },
    editPosition: { // 编辑位置，下面是默认值
      left: 75
    },
    colorPicker: false, // 取色器操作
    colorPickerInfo: {},
    dragEvent: false, // 拖拽事件
    group: undefined, // 组合
    copyElement: undefined, // 复制的内容,
    water: false
  },
  mutations: {
    setWater (state, value) {
      state.water = value
    },
    // 粘贴
    paste (state) {
      const copyElement = state.copyElement
      if (copyElement.type === 'group') {
        EditUtil.pasteGroup(state)
      } else {
        const module = _.cloneDeep(state.copyElement)
        module.id = uuidv4()
        module.ZIndex = EditUtil.getNewZIndex(state)
        EditUtil.pushLayer(state, module)
      }
    },
    // 复制
    copy (state) {
      if (state.group) {
        state.copyElement = _.cloneDeep(state.group)
        state.copyElement.layers = EditUtil.getCopyGroupLayers(state)
      } else {
        state.copyElement = _.cloneDeep(state.editModule)
      }
    },
    /**
       * 拖拽事件
       */
    setDragEvent (state, val) {
      state.dragEvent = val
    },
    /**
       * 取色器信息
       */
    setColorInfo (state, info) {
      state.colorPickerInfo = info
    },
    /**
       *
       * 设置取色器操作
       */
    setColorPicker (state, val) {
      state.colorPicker = val
    },
    /**
       *
       * 编辑框大小
       */
    setEditSize (state, size) {
      state.editSize.editWidth = size.width
      state.editSize.editHeight = size.height
      canvas.editWidth = size.width
      canvas.editHeight = size.height
    },
    /**
       *
       * 设置JSONID
       */
    setPostJsonId (state, id) {
      state.postJsonId = id
    },
    /**
       *
       * 加载字体
       */
    loadFont (state) {
      state.postList.forEach(postInfo => {
        postInfo.texts.forEach(e => {
          const fullname = e.fontFamily
          if (fontMap[fullname]) {
            const item = fontMap[fullname]
            e.fontFamily = item.fontFamily
            // e.fontStyle = item.fontStyle
            e.fontUrl = item.url
            if (item.load) {
              return
            }
            const style = document.createElement('style')
            style.type = 'text/css'
            style.innerText = '@font-face {font-family:' + fullname + ';src:url(' + item.url + ')};font-display: swap'
            document.getElementsByTagName('head')[0].appendChild(style)
            item.load = true
          } else {
            const item = fontMap['SourceHanSansCN-Regular']
            e.fontFamily = item.fontFamily
            // e.fontStyle = item.fontStyle
            e.fontUrl = item.url
            if (item.load) {
              return
            }
            const style = document.createElement('style')
            style.type = 'text/css'
            style.innerText = '@font-face {font-family:' + fullname + ';src:url(' + item.url + ')};font-display: swap'
            document.getElementsByTagName('head')[0].appendChild(style)
            item.load = true
          }
        })
      })
    },
    /**
         * 选择背景
         */
    setBackImage (state, url) {
      const img = new Image()
      img.src = url
      img.src = getFormatImageSrc(img.src)

      img.onload = function () {
        const rateY = state.canvas.height / img.naturalHeight
        const rateX = state.canvas.width / img.naturalWidth
        const rate = rateX > rateY ? rateX : rateY
        state.postInfo.background.backgroundImage = img.src
        const width = Math.ceil(img.naturalWidth * rate)
        const height = Math.ceil(img.naturalHeight * rate)
        state.postInfo.background.width = width
        state.postInfo.background.height = height
        const backgroundPositionX = (width - state.canvas.width) / 2
        const backgroundPositionY = (height - state.canvas.height) / 2
        state.postInfo.background.imageInfo = { // 展示信息
          backgroundPositionX: backgroundPositionX, // 展示坐标X轴
          backgroundPositionY: backgroundPositionY // 展示坐标Y轴
        }
      }
    },
    // 截图
    setCropImage (state, val) {
      state.cropImage = val
    },
    // 初始化
    initEdit (state) {
      state.editSelectedId = undefined// 被选择模块ID
      state.editModule = {}// 当前编辑模块
      state.scale = 100// 放大情况
      state.canvas = {}// 画布信息
      state.historyRecord = []// 历史记录集合
      state.nextRecord = []// 下一步
      state.postInfo = {}// 总海报信息
      state.postList = []// 海报集合
      state.editModuleList = []// 多项编辑
      state.index = 0
      state.scrollLeft = 0// 滚动位置
      state.scrollTop = 0// 滚动位置
      state.cropImage = false// 是否裁剪操作
      state.postJsonId = undefined
      state.editSize = { // 编辑面板大小，下面是默认值
        editWidth: 1400,
        editHeight: 873
      }
      state.editPosition = { // 编辑位置，下面是默认值
        left: 75
      }
      state.colorPicker = false// 取色器操作
    },
    // 设置被选ID
    setEditSelectedId (state, id) {
      state.editSelectedId = id
    },
    // 设置编辑模块
    setEditModule (state, editModule) {
      if (state.cropImage) {
        return
      }
      state.group = undefined
      state.editSelectedId = editModule.id
      state.editModule = editModule
      state.editModuleList = []
      if (editModule.groupId && state.postInfo.groups) {
        state.group = state.postInfo.groups.find(group => {
          return editModule.groupId === group.id
        })
      }
    },
    // 设置组合模块
    setGroupModule (state, groupId) {
      state.group = state.postInfo.groups.find(group => {
        return groupId === group.id
      })
    },
    // 设置编辑模块为背景
    setBackEditModule (state) {
      const editModule = state.postInfo.background
      state.editSelectedId = editModule.id
      state.editModule = editModule
      state.editModuleList = []
      state.group = undefined
    },
    // 设置编辑模块集合
    setEditModuleList (state, editModule) {
      if (state.editModule == editModule || state.editModule.type == 'background') {
        if (state.cropImage) {
          return
        }
        state.group = undefined
        state.editSelectedId = editModule.id
        state.editModule = editModule
        state.editModuleList = []
        if (editModule.groupId && state.postInfo.groups) {
          state.group = state.postInfo.groups.find(group => {
            return editModule.groupId === group.id
          })
        }
        return
      }
      let minLeft = Number.MAX_VALUE
      let maxLeft = Number.MIN_VALUE
      let minTop = Number.MAX_VALUE
      let maxTop = Number.MIN_VALUE
      state.editSelectedId = editModule.id
      let preSelectedId = [state.editModule.id]
      if (state.editModule.groupId) {
        const preGroup = EditUtil.findGroupBygroupId(state, state.editModule.groupId)
        preSelectedId = [...preGroup.layerIds]
      }
      state.editModule = editModule
      if (state.group && !state.group.id) {
        if (editModule.groupId) {
          const group = EditUtil.findGroupBygroupId(state, editModule.groupId)
          state.group.layerIds = [...new Set([...state.group.layerIds, ...group.layerIds])]
        } else {
          state.group.layerIds = [...new Set([...state.group.layerIds, editModule.id])]
        }
      } else {
        if (editModule.groupId) {
          const group = state.postInfo.groups.find(item => {
            return item.id === editModule.groupId
          })
          state.group = {
            left: 0,
            top: 0,
            width: 0,
            height: 0,
            rotate: 0,
            layerIds: [...group.layerIds, ...preSelectedId],
            moduleIds: {}
          }
        } else {
          state.group = {
            left: 0,
            top: 0,
            width: 0,
            height: 0,
            rotate: 0,
            layerIds: [editModule.id, ...preSelectedId],
            moduleIds: {}
          }
        }
      }
      const layers = [...state.postInfo.codes, ...state.postInfo.images, ...state.postInfo.texts]
      state.group.layerIds.forEach(id => {
        const item = layers.find(layer => {
          return layer.id === id
        })
        if (!item) {
          return
        }
        const res = PositionUtil.getPosition(item.left + item.width / 2, item.top + item.height / 2, item.width, item.height, item.rotate)
        const itemPositionInfo = {
          top: res.most.minTop,
          left: res.most.minLeft,
          width: res.most.maxLeft - res.most.minLeft,
          height: res.most.maxTop - res.most.minTop
        }
        if (minLeft > itemPositionInfo.left) {
          minLeft = itemPositionInfo.left
        }
        if (minTop > itemPositionInfo.top) {
          minTop = itemPositionInfo.top
        }
        if (maxLeft < itemPositionInfo.left + itemPositionInfo.width) {
          maxLeft = itemPositionInfo.left + itemPositionInfo.width
        }
        if (maxTop < itemPositionInfo.top + itemPositionInfo.height) {
          maxTop = itemPositionInfo.top + itemPositionInfo.height
        }
      })
      state.group = {
        id: undefined,
        type: 'group',
        left: minLeft,
        top: minTop,
        width: maxLeft - minLeft,
        height: maxTop - minTop,
        rotate: 0,
        layerIds: state.group.layerIds
      }
    },

    // 对齐操作
    alignOper (state, type) {
      function align (Module) {
        let editModule = Module
        if (editModule.groupId) {
          editModule = state.postInfo.groups.find(group => {
            return group.id === editModule.groupId
          })
          EditUtil.initGroupParameter(state, editModule)
        }
        const res = PositionUtil.getPosition(editModule.left + editModule.width / 2, editModule.top + editModule.height / 2, editModule.width, editModule.height, editModule.rotate)
        const itemPositionInfo = {
          top: res.most.minTop,
          left: res.most.minLeft,
          width: res.most.maxLeft - res.most.minLeft,
          height: res.most.maxTop - res.most.minTop
        }
        switch (type) {
          case 'top':
            editModule.top -= (itemPositionInfo.top - range.top)
            break
          case 'left':
            editModule.left -= (itemPositionInfo.left - range.left)
            break
          case 'right':
            editModule.left -= (itemPositionInfo.left + itemPositionInfo.width - range.left - range.width)
            break
          case 'bottom':
            editModule.top -= (itemPositionInfo.top + itemPositionInfo.height - range.top - range.height)
            break
          case 'vcenter':
            editModule.left -= (itemPositionInfo.left + itemPositionInfo.width / 2 - range.left - range.width / 2)
            break
          case 'hcenter':
            editModule.top -= (itemPositionInfo.top + itemPositionInfo.height / 2 - range.top - range.height / 2)
            break
          case 'horizontaldistribution':
            break
          case 'verticaldistribution':
            break
        }
        if (editModule.type === 'group') {
          EditUtil.resetGroupItem(state, editModule)
        }
      }
      const canvas = state.postInfo.canvas
      const layeyinfo = {}
      const layers = [...state.postInfo.images, ...state.postInfo.texts, ...state.postInfo.codes]
      layers.forEach(item => {
        layeyinfo[item.id] = item
      })
      let range = {
        top: 0,
        left: 0,
        width: canvas.width,
        height: canvas.height
      }
      const editModuleList = []
      if (state.group && !state.group.id) {
        range = {
          top: state.group.top,
          left: state.group.left,
          width: state.group.width,
          height: state.group.height
        }
        state.group.layerIds.forEach(item => {
          editModuleList.push(layeyinfo[item])
        })
      } else {
        editModuleList.push(state.editModule)
      }
      if (type === 'horizontaldistribution') {
        EditUtil.horizontaldistribution(state, editModuleList)
      } else if (type === 'verticaldistribution') {
        EditUtil.verticaldistribution(state, editModuleList)
      } else {
        editModuleList.forEach(item => {
          align(item)
        })
      }
    },
    // 删除模块
    removeModule (state) {
      if (state.group) {
        const layers = [...state.postInfo.images, ...state.postInfo.texts, ...state.postInfo.codes]
        state.group.layerIds.forEach(id => {
          const editModule = layers.find(layer => {
            return layer.id === id
          })
          if (editModule) {
            EditUtil.deleteModule(state, editModule)
          }
        })

        const id = state.group.id
        if (id) {
          state.postInfo.groups = state.postInfo.groups.filter(group => {
            return group.id !== id
          })
        }
        state.group = undefined
      } else {
        EditUtil.deleteModule(state, state.editModule)
      }

      // const editModule = state.editModule
      //

      state.editModule = {}
      state.editSelectedId = undefined
    },
    // 设置放大倍数
    setScale (state, scale) {
      if (scale > 400) {
        state.scale = 400
        return
      }
      if (scale < 5) {
        state.scale = 5
        return
      }
      state.scale = scale
    },
    // 设置画布信息
    setCanvas (state, canvas) {
      state.canvas = canvas
    },
    // 设置海报信息
    setPostInfo (state, postInfo) {
      if (!postInfo.background.imageInfo) {
        postInfo.background.imageInfo = { // 展示信息
          backgroundPositionX: 0, // 展示坐标X轴
          backgroundPositionY: 0 // 展示坐标Y轴
        }
      }
      postInfo.background.backgroundImage = getFormatImageSrc(postInfo.background.backgroundImage)
      postInfo.images.forEach(e => {
        e.src = getFormatImageSrc(e.src)
        if (!e.rotate) {
          e.rotate = 0
        }
      })
      postInfo.texts.forEach(e => {
        const fullname = e.fontFamily
        if (!e.rotate) {
          e.rotate = 0
        }
        if (!e.html) {
          e.html = e.text.replace(new RegExp(' ', 'gm'), '&nbsp;')
        }
        if (!fontMap[fullname]) {
          const item = fontMap['SourceHanSansCN-Regular']
          e.fontFamily = item.fontFamily
          // e.fontStyle = item.fontStyle
          e.fontUrl = item.url
        }
      })
      postInfo.codes.forEach(e => {
        if (!e.rotate) {
          e.rotate = 0
        }
      })
      state.postInfo = postInfo
      state.canvas = postInfo.canvas
      const scaleWidth = Math.floor(720 / postInfo.canvas.width * 10) * 10
      const scaleHeight = Math.floor(800 / postInfo.canvas.height * 10) * 10
      state.scale = scaleWidth > scaleHeight ? scaleHeight : scaleWidth
      state.postList = [postInfo]

      state.index = 0
    },
    // 设置海报信息
    setPostInfoList (state, postInfoList) {
      postInfoList.forEach(postInfo => {
        postInfo.images.forEach(e => {
          e.src = getFormatImageSrc(e.src)
          if (!e.rotate) {
            e.rotate = 0
          }
        })
        postInfo.texts.forEach(e => {
          // let fullname = Tool.getFontFullName(e.fontFamily,e.fontStyle)
          const fullname = e.fontFamily
          if (!e.rotate) {
            e.rotate = 0
          }
          if (!e.html) {
            e.html = e.text.replace(new RegExp(' ', 'gm'), '&nbsp;')
          }

          if (!fontMap[fullname]) {
            const item = fontMap['SourceHanSansCN-Regular']
            e.fontFamily = item.fontFamily
            // e.fontStyle = item.fontStyle
            e.fontUrl = item.url
          }
        })
        postInfo.codes.forEach(e => {
          if (!e.rotate) {
            e.rotate = 0
          }
        })
      })
      state.postInfo = postInfoList[0]
      state.canvas = postInfoList[0].canvas
      const scaleWidth = Math.floor(720 / postInfoList[0].canvas.width * 10) * 10
      const scaleHeight = Math.floor(800 / postInfoList[0].canvas.height * 10) * 10
      state.scale = scaleWidth > scaleHeight ? scaleHeight : scaleWidth
      state.postList = postInfoList
      state.index = 0
    },
    // 添加图层
    pushLayers (state) {
      const layer = state.editModule
      layer.ZIndex = state.postInfo.images.length + state.postInfo.texts.length - 1
    },
    // 重新调整图层
    adjustLayers (state, type) {
      // eslint-disable-next-line no-array-constructor

      const editModule = state.editModule
      if (type === 'up') { // 上移一层
        EditUtil.upper(state, editModule.id)
      } else if (type === 'down') { // 下移一层
        EditUtil.down(state, editModule.id)
      } else if (type === 'top') { // 置顶
        EditUtil.top(state, editModule.id)
      } else if (type === 'bottom') { // 置底
        EditUtil.bottom(state, editModule.id)
      }
    },
    // 添加记录
    pushHistoryRecord (state) {
      const postInfo = _.cloneDeep(state.postInfo)
      const postList = _.cloneDeep(state.postList)
      state.historyRecord.push({
        postInfo: postInfo,
        postList: postList,
        index: state.index
      })
      state.nextRecord = []
      if (!config.admin) {
        updateBatch([{
          id: state.postJsonId,
          psdJson: JSON.stringify(postList)
        }])
      }
      postToImage.init()
    },
    // 上一步
    back (state) {
      // eslint-disable-next-line eqeqeq
      if (state.historyRecord.length == 1) {
        return
      }
      const record = _.cloneDeep(state.historyRecord.pop())
      const lastRecord = _.cloneDeep(_.last(state.historyRecord))
      state.nextRecord.push(record)
      state.postInfo = lastRecord.postList[lastRecord.index]
      state.postList = lastRecord.postList
      state.index = lastRecord.index
      state.editModule = {}
      state.editModuleList = []
      state.group = undefined
      // state.postInfo=state.historyRecord[0]
    },
    // 下一步
    next (state) {
      // eslint-disable-next-line eqeqeq
      if (state.nextRecord.length == 0) {
        return
      }
      const nextRecord = state.nextRecord.pop()
      const record = _.cloneDeep(nextRecord)
      state.historyRecord.push(record)
      state.postInfo = record.postList[record.index]
      state.postList = record.postList
      state.index = record.index
      state.group = undefined
    },
    // 锁操作
    lock (state) {
      function lockModule (editModule) {
        if (editModule.type === 'background') {
          return
        }
        editModule.lock = !editModule.lock
      }
      if (state.group) {
        const layers = [...state.postInfo.images, ...state.postInfo.texts, ...state.postInfo.codes]
        state.group.layerIds.forEach(id => {
          const editModule = layers.find(layer => {
            return layer.id === id
          })
          if (editModule) {
            lockModule(editModule)
          }
        })
        const id = state.group.id
        if (id) {
          state.group.lock = state.group.lock ? !state.group.lock : true
        }
        state.group = undefined
      } else {
        lockModule(state.editModule)
      }
      // state.editModule.lock = !state.editModule.lock
    },
    // 添加图片
    addImage (state, imgInfo) {
      imgInfo.src = getFormatImageSrc(imgInfo.src)
      state.postInfo.images.push(imgInfo)
      imgInfo.ZIndex = EditUtil.getNewZIndex(state)
    },
    // 添加文字
    addText (state, textInfo) {
      state.postInfo.texts.push(textInfo)
      textInfo.ZIndex = EditUtil.getNewZIndex(state)
    },
    // 添加e二维码
    addCode (state, codeInfo) {
      state.postInfo.codes.push(codeInfo)
      codeInfo.ZIndex = EditUtil.getNewZIndex(state)
    },
    // 添加组
    addGroup (state) {
      const newGroup = {}
      const id = uuidv4()
      state.group.id = id
      newGroup.type = 'group'
      newGroup.id = id
      newGroup.layerIds = state.group.layerIds
      newGroup.rotate = state.group.rotate
      newGroup.width = state.group.width
      newGroup.height = state.group.height
      newGroup.top = state.group.top
      newGroup.left = state.group.left
      if (!state.postInfo.groups) {
        state.postInfo.groups = []
      }
      const layers = [...state.postInfo.codes, ...state.postInfo.images, ...state.postInfo.texts]
      newGroup.layerIds.forEach(id => {
        const item = layers.find(layer => {
          return layer.id === id
        })
        item.groupId = newGroup.id
      })
      state.postInfo.groups.push(newGroup)
      state.group = newGroup
      EditUtil.adjustLayers(state)
      EditUtil.clearGroup(state)
    },
    /**
 * 拆分组
 * @param {*} state
 */
    splitGroup (state) {
      const id = state.group.id
      state.group.id = undefined
      if (!state.postInfo.groups) {
        state.postInfo.groups = []
      }
      const layers = [...state.postInfo.codes, ...state.postInfo.images, ...state.postInfo.texts]
      state.group.layerIds.forEach(id => {
        const item = layers.find(layer => {
          return layer.id === id
        })
        item.groupId = undefined
      })
      state.postInfo.groups = state.postInfo.groups.filter(group => {
        return group.id !== id
      })
    },
    // 新建海报
    createPost (state) {
      let canvas = { ...state.canvas }
      // eslint-disable-next-line eqeqeq
      if (state.postList.length != 0) {
        canvas = { ...state.postList[0].canvas }
      }
      state.postInfo = {
        canvas,
        images: [],
        background: {
          id: uuidv4(),
          backgroundId: uuidv4(),
          type: 'background',
          backgroundColor: '',
          backgroundImage: '',
          width: canvas.width,
          height: canvas.height,
          imageInfo: { // 展示信息
            backgroundPositionX: 0, // 展示坐标X轴
            backgroundPositionY: 0 // 展示坐标Y轴
          }
        },
        texts: [],
        codes: []
      }
      state.canvas = state.postInfo.canvas
      state.postList.push(state.postInfo)
      state.index = state.postList.length - 1
    },
    // 添加海报
    pushPost (state, postInfo) {
      if (!postInfo.background.imageInfo) {
        postInfo.background.imageInfo = { // 展示信息
          backgroundPositionX: 0, // 展示坐标X轴
          backgroundPositionY: 0 // 展示坐标Y轴
        }
      }
      postInfo.background.backgroundImage = getFormatImageSrc(postInfo.background.backgroundImage)
      postInfo.images.forEach(e => {
        e.src = getFormatImageSrc(e.src)
        if (!e.rotate) {
          e.rotate = 0
        }
      })
      postInfo.texts.forEach(e => {
        const fullname = e.fontFamily
        if (!e.rotate) {
          e.rotate = 0
        }
        if (!e.html) {
          e.html = e.text.replace(new RegExp(' ', 'gm'), '&nbsp;')
        }
        if (!fontMap[fullname]) {
          const item = fontMap['SourceHanSansCN-Regular']
          e.fontFamily = item.fontFamily
          // e.fontStyle = item.fontStyle
          e.fontUrl = item.url
        }
      })
      postInfo.codes.forEach(e => {
        if (!e.rotate) {
          e.rotate = 0
        }
      })

      state.postList.push(postInfo)
    },
    // 根据index选择海报
    selectPostByIndex (state, index) {
      state.index = index
      state.postInfo = state.postList[index]
      state.canvas = state.postInfo.canvas
    },
    /**
* 选择最后一页海报
* @param {} state
*/
    selectLastPost (state) {
      state.index = state.postList.length - 1
      state.postInfo = state.postList[state.index]
      state.canvas = state.postInfo.canvas
    },
    // 根据index删除面板
    deletePostByIndex (state, index) {
      state.postList.splice(index, 1)

      // eslint-disable-next-line eqeqeq
      if (index == 0) {
        state.postInfo = state.postList[0]
        state.canvas = state.postInfo.canvas
      } else {
        state.index = index - 1
        state.postInfo = state.postList[index - 1]
        state.canvas = state.postInfo.canvas
      }
    },
    /**
 * 初始化图层位置，大小
 * @param {*} state
 */
    initGroupSize (state) {
      EditUtil.initGroupSize(state, state.group)
    },
    /**
 * 初始化对齐操作图层位置，大小
 * @param {*} state
 */
    initAlignGroupSize (state) {
      const pointList = []
      const layers = [...state.postInfo.images, ...state.postInfo.texts, ...state.postInfo.codes]

      state.group.layerIds.forEach(id => {
        let item = layers.find(layer => { return layer.id === id })
        if (item.groupId) {
          item = state.postInfo.groups.find(group => {
            return group.id === item.groupId
          })
          EditUtil.initGroupSize(state, item)
        }
        const res = PositionUtil.getPosition(item.left + item.width / 2, item.top + item.height / 2, item.width, item.height, item.rotate)
        pointList.push(res.leftTop)
        pointList.push(res.leftBottom)
        pointList.push(res.rightTop)
        pointList.push(res.rightBottom)
      })
      const anglePositionInfo = PositionUtil.getGroupPositionInfo(pointList, state.group.rotate)
      state.group.width = anglePositionInfo.width
      state.group.height = anglePositionInfo.height
      state.group.top = anglePositionInfo.top
      state.group.left = anglePositionInfo.left
      // state.group.operItems = undefined
    },

    /**
  * 横轴移动
  * @param state
  */
    moveLeft (state, step) {
      if (state.group) {
        if ((state.group && state.group.lock == true)) {
          return
        }
        state.group.left += step
      } else {
        if (state.editModule.type === 'back' || state.editModule.lock) {
          return
        }
        state.editModule.left += step
      }
    },
    /**
   * 纵轴移动
   * @param state
   */
    moveTop (state, step) {
      if (state.group) {
        if ((state.group && state.group.lock == true)) {
          return
        }
        state.group.top += step
      } else {
        if (state.editModule.type === 'back' || state.editModule.lock) {
          return
        }
        state.editModule.top += step
      }
    },
    /**
  *
  * @param state 剪切
  */
    shear (state) {
      if (state.group) {
        state.copyElement = _.cloneDeep(state.group)
        state.copyElement.layers = EditUtil.getCopyGroupLayers(state)

        const layers = [...state.postInfo.images, ...state.postInfo.texts, ...state.postInfo.codes]
        state.group.layerIds.forEach(id => {
          const editModule = layers.find(layer => {
            return layer.id === id
          })
          if (editModule) {
            EditUtil.deleteModule(state, editModule)
          }
        })
        state.group = undefined
        state.editModule = state.postInfo.background
      } else {
        const editModule = state.editModule
        state.copyElement = _.cloneDeep(state.editModule)
        state.editModule = state.postInfo.background
        EditUtil.deleteModule(state, editModule)
      }
    },
    /**
   * 全选
   * @param state
   */
    selectAll (state) {
      const group = { layerIds: [], rotate: 0, type: 'group' }

      let minLeft = Number.MAX_SAFE_INTEGER
      let maxLeft = Number.MIN_SAFE_INTEGER
      let minTop = Number.MAX_SAFE_INTEGER
      let maxTop = Number.MIN_SAFE_INTEGER
      const layers = EditUtil.getAllSingleLayer(state)
      // let pointList: { left: number, top: number }[] = []
      layers.forEach(item => {
        if (item.lock) {
          return
        }
        const res = PositionUtil.getPosition(item.left + item.width / 2, item.top + item.height / 2, item.width, item.height, item.rotate)
        const itemPositionInfo = {
          top: res.most.minTop,
          left: res.most.minLeft,
          width: res.most.maxLeft - res.most.minLeft,
          height: res.most.maxTop - res.most.minTop
        }

        if (minLeft > itemPositionInfo.left) {
          minLeft = itemPositionInfo.left
        }
        if (minTop > itemPositionInfo.top) {
          minTop = itemPositionInfo.top
        }
        if (maxLeft < itemPositionInfo.left + itemPositionInfo.width) {
          maxLeft = itemPositionInfo.left + itemPositionInfo.width
        }
        if (maxTop < itemPositionInfo.top + itemPositionInfo.height) {
          maxTop = itemPositionInfo.top + itemPositionInfo.height
        }

        group.layerIds.push(item.id)
      })

      if (group.layerIds.length != 0) {
        group.left = minLeft
        group.top = minTop
        group.width = maxLeft - minLeft
        group.height = maxTop - minTop
        state.group = group
      }
    }

  },
  getters: {
    // 多项编辑模块范围位置
    editModuleListPosition (state) {
      let minLeft = Number.MAX_VALUE
      let maxLeft = Number.MIN_VALUE
      let minTop = Number.MAX_VALUE
      let maxTop = Number.MIN_VALUE
      state.editModuleList.forEach(item => {
        const res = PositionUtil.getPosition(item.left + item.width / 2, item.top + item.height / 2, item.width, item.height, item.rotate)
        const itemPositionInfo = {
          top: res.most.minTop,
          left: res.most.minLeft,
          width: res.most.maxLeft - res.most.minLeft,
          height: res.most.maxTop - res.most.minTop
        }

        if (minLeft > itemPositionInfo.left) {
          minLeft = itemPositionInfo.left
        }
        if (minTop > itemPositionInfo.top) {
          minTop = itemPositionInfo.top
        }
        if (maxLeft < itemPositionInfo.left + itemPositionInfo.width) {
          maxLeft = itemPositionInfo.left + itemPositionInfo.width
        }
        if (maxTop < itemPositionInfo.top + itemPositionInfo.height) {
          maxTop = itemPositionInfo.top + itemPositionInfo.height
        }
      })

      return {
        left: minLeft,
        top: minTop,
        width: maxLeft - minLeft,
        height: maxTop - minTop
      }
    },
    // 是否能够编辑
    revokedAbled (state) {
      return state.historyRecord.length > 1
    },
    // 能否重做
    redoAbled (state) {
      return state.nextRecord.length > 0
    },
    // 可拖拽元素
    drapAbled (state) {
      if (state.group && state.group.lock !== true) {
        return true
      }
      if (state.group && state.group.lock === true) {
        return false
      }
      return (state.editModule.type == 'text' || state.editModule.type == 'image' || state.editModule.type == 'code') && !state.editModule.lock
    },
    // 能否锁操作
    lockAbled (state) {
      if (state.group && !state.group.lock) {
        return true
      }
      return state.editModule.lock != undefined
    },
    /**
   * 所有图层
   * @param {*} state
   * @returns
   */
    layers (state) {
      return [...state.postInfo.images, ...state.postInfo.texts, ...state.postInfo.codes]
    },
    /**
   * 组合信息
   * @param {*} state
   * @returns
   */
    groups (state) {
      return state.postInfo.groups
    },
    /**
     * 是否能进行分布操作
     * @param {} state
     */
    distribution (state) {
      const layerInfo = {}
      const moduleIds = []
      const layers = [...state.postInfo.images, ...state.postInfo.texts, ...state.postInfo.codes]
      layers.forEach(item => {
        layerInfo[item.id] = item
      })
      if (!state.group || state.group.id) {
        return false
      }
      state.group.layerIds.forEach(item => {
        if (layerInfo[item].groupId) {
          moduleIds.push(layerInfo[item].groupId)
        } else {
          moduleIds.push(item)
        }
      })
      return [...new Set(moduleIds)].length > 2
    },
    /**
     * 可以进行粘贴操作
     * @param {} state
     */
    canPaste (state) {
      if (state.copyElement) {
        return true
      } else {
        return false
      }
    },
    /**
     * 可以进行复制操作
     * @param {} state
     */
    canCopy (state) {
      if (state.editModule && state.editModule.lock) {
        return false
      }
      if (state.group && state.group.lock) {
        return false
      }
      if (state.group || (state.editModule && state.editModule.type !== 'background')) {
        return true
      }
      return false
    },
    /**
     * 能够删除
     * @param {} state
     * @returns
     */
    canDelete (state) {
      if (state.editModule && state.editModule.lock) {
        return false
      }
      if (state.group && state.group.lock) {
        return false
      }
      if (state.group || (state.editModule && state.editModule.type !== 'background')) {
        return true
      }
      return false
    }
  }
}
