var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "clickoutside",
          rawName: "v-clickoutside",
          value: _vm.hide,
          expression: "hide"
        }
      ],
      class: [
        "el-color-picker",
        _vm.colorDisabled ? "is-disabled" : "",
        _vm.colorSize ? "el-color-picker--" + _vm.colorSize : ""
      ]
    },
    [
      _vm.colorDisabled
        ? _c("div", { staticClass: "el-color-picker__mask" })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "el-color-picker__trigger",
          on: { click: _vm.handleTrigger }
        },
        [
          _c(
            "span",
            {
              staticClass: "el-color-picker__color",
              class: { "is-alpha": _vm.showAlpha }
            },
            [
              _c("span", {
                staticClass: "el-color-picker__color-inner",
                style: {
                  backgroundColor: _vm.displayedColor
                }
              }),
              !_vm.value && !_vm.showPanelColor
                ? _c("span", {
                    staticClass: "el-color-picker__empty el-icon-close"
                  })
                : _vm._e()
            ]
          )
        ]
      ),
      _c("picker-dropdown", {
        ref: "dropdown",
        class: ["el-color-picker__panel", _vm.popperClass || ""],
        attrs: {
          color: _vm.color,
          "show-alpha": _vm.showAlpha,
          predefine: _vm.predefine
        },
        on: { pick: _vm.confirmValue, clear: _vm.clearValue },
        model: {
          value: _vm.showPicker,
          callback: function($$v) {
            _vm.showPicker = $$v
          },
          expression: "showPicker"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }