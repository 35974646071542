// 日期格式化
function add0 (m) {
  return m < 10 ? '0' + m : m
}
export function rTime (t, length) { // 时间标准化
  if (!t) {
    return ''
  }
  var time = new Date(t)
  var y = time.getFullYear()
  var m = time.getMonth() + 1
  var d = time.getDate()
  var h = time.getHours()
  var mm = time.getMinutes()
  var s = time.getSeconds()
  if (length === 3) {
    return y + '-' + add0(m) + '-' + add0(d)
  } else {
    return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s)
  }
}
// 手机分割
export function rPhone (tel) {
  const tel0_2 = tel.substring(0, 3)
  const tel3_6 = tel.substring(3, 7)
  const tel7_10 = tel.substring(7)
  return tel0_2 + '-' + tel3_6 + '-' + tel7_10
}
