var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "el-zoom-in-top" }, on: { "after-leave": _vm.doDestroy } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showPopper,
              expression: "showPopper"
            }
          ],
          staticClass: "el-color-dropdown"
        },
        [
          _c(
            "div",
            { staticClass: "el-color-dropdown__main-wrapper" },
            [
              _c("hue-slider", {
                ref: "hue",
                staticStyle: { float: "right" },
                attrs: { color: _vm.color, vertical: "" }
              }),
              _c("sv-panel", { ref: "sl", attrs: { color: _vm.color } })
            ],
            1
          ),
          _vm.showAlpha
            ? _c("alpha-slider", { ref: "alpha", attrs: { color: _vm.color } })
            : _vm._e(),
          _vm.predefine
            ? _c("predefine", {
                attrs: { color: _vm.color, colors: _vm.predefine }
              })
            : _vm._e(),
          _c("div", { staticClass: "el-color-dropdown__btns" }, [
            _c(
              "span",
              { staticClass: "el-color-dropdown__value" },
              [
                _c("el-input", {
                  attrs: { "validate-event": false, size: "mini" },
                  on: {
                    keydown: function($event) {
                      $event.stopPropagation()
                    },
                    blur: _vm.handleConfirm
                  },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleConfirm($event)
                    }
                  },
                  model: {
                    value: _vm.customInput,
                    callback: function($$v) {
                      _vm.customInput = $$v
                    },
                    expression: "customInput"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "pen-content", on: { click: _vm.openPenFunc } },
            [
              _c("img", {
                attrs: { src: require("@/assets/img/colorsucker.png"), alt: "" }
              }),
              _vm.colorInfo.loading
                ? _c("div", { staticClass: "loading" }, [
                    _c("i", { staticClass: "el-icon-loading" })
                  ])
                : _vm._e()
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }