<template>
  <el-dialog :visible.sync="bindVisible" width="508px">
    <div class="main bind">
      <div class="login-type">
        <div class="title-text">
          <h2 class="title">
            绑定手机号码
          </h2>
          <p>根据国家相关规定，您需要绑定手机号码</p>
        </div>
        <el-form :model="form" ref="form" :rules="rules" label-position="right" label-width="95px">
          <el-form-item prop="phone" label="手机号码：">
            <el-input v-model="form.phone" placeholder="请输入手机号码"></el-input>
          </el-form-item>
          <el-form-item label="验证码：" prop="code">
            <el-col :span="16">
              <el-input v-model="form.code" placeholder="请输入短信验证码" @keydown.enter.native="toBind"></el-input>
            </el-col>
            <el-col :span="8">
              <el-button type="primary" @click="sendCode" v-if="isSend" class="sendCode">发送验证码</el-button>
              <el-button type="primary" class="sendCode" disabled v-else>{{this.countdown + 's'}}</el-button>
            </el-col>
          </el-form-item>
          <!-- <el-form-item> -->
            <div class="btn">
              <el-button type="primary" style="width: 35%" @click="toBind">确认</el-button>
              <el-button type="primary" style="width: 35%" @click="bindVisible = false">取消</el-button>
            </div>
          <!-- </el-form-item> -->
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getPhoneCode } from '@/api/login'
import { getUserInfo, bindPhone } from '@/api/userInfo'

export default {
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else {
        var reg=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (reg.test(value)) {
            callback();
        } else {
            callback(new Error('请输入正确的手机号'));
        }
      }
    }
    var validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入短信验证码'));
      } else {
        var reg=/^\d{6}$/;
        if (reg.test(value)) {
            callback();
        } else {
            callback(new Error('请输入正确的短信验证码'));
        }
      }
    }
    return {
      bindVisible: false,
      isSend: true,
      countdown: '',
      form: {
        phone: '',
        code: '',
      },
      rules: {
        phone: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        code: [
          { required: true, validator: validateCode, trigger: 'blur' }
        ],
      }
    }
  },
  created () {
    this.$bus.$on('getInfo', () => {
      this.getInfo()
    })
  },
  watch: {
    bindVisible(val) {
      if(!val) location.reload()
      if(this.$refs.form) this.$refs.form.resetFields()
    }
  },
  methods: {
    // 发送验证码
    async sendCode () {
      let params = JSON.stringify(this.form)
      if(!this.form.phone) {
        this.$message('请输入正确的手机号')
      }else {
        const res = await getPhoneCode(params)
        if(res && res.code === 0) {
          this.isSend = false
          this.countdown = 60
          let codeId = setInterval(() => {
            if(this.countdown === 0) {
              clearInterval(codeId)
              this.isSend = true
            }
            this.countdown--
          }, 1000);
        }
      }
    },
    // 获取用户信息
    async getInfo() {
      const res = await getUserInfo();
      if (res.code == 0) {
        this.userInfo = res.data;
        this.getBindType(this.userInfo.userAuths);
      }
    },
    // 获取绑定信息
    getBindType(userAuths) {
      const identityArr = [];
      userAuths.forEach((item) => {
        identityArr.push(item.identityType);
      });
      if (!identityArr.includes(1)) {
        this.bindVisible = true;
      }else {
        location.reload()
        this.bindVisible = false
      }
    },
    // 绑定手机号
    toBind () {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const res = await bindPhone(this.form)
          if(res.code === 0) {
            this.$message.success('绑定成功')
            location.reload()
          } else {
            this.$message.error(res.msg)
            this.form.code = ''
          }
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.main {
    // width: 500px;
    // height: 500px;
    border-radius: 10px;
    background-color: #fff;
    text-align: center;
    padding: 10px 10px;
    .imgCode {
      height: 100%;
    }
    /deep/ .el-form-item__content {
      height: 40px;
    }
    .login-type {
      height: 66%;
    }
    .title-text {
      text-align: left;
    }
    .title {
      font-weight: 400;
      .wx-icon {
        width: 30px;
        height: 30px;
        margin-right: 5px;
      }
    }
    .title-wx {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    p {
      margin: 10px 0 20px;
      color: #666;
    }
    .qrcode {
      width: 170px;
      height: 170px;
    }
    .code {
      height: 170px;
      text-align: center;
      cursor: pointer;
    }
    .other {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0;
      li:first-child,
      li:last-child {
        width: 125px;
        height: 1px;
        border-bottom: 1px solid #ccc;
      }
      .other-text {
        font-size: 14px;
        color: #666;
        margin: 0 10px;
      }
    }
    .login-way {
      display: flex;
      justify-content: center;
      li {
        text-align: center;
        cursor: pointer;
        position: relative;
        &:first-child {
          margin-right: 50px;
        }
        &:hover p {
          color: #ff6700;
        }
        &:hover .phone,
        &:hover .wx,
        &:hover .qq {
          display: inline-block;
        }
        i {
          display: inline-block;
          width: 40px;
          height: 40px;
          text-align: 40px;
          line-height: 40px;
          border: 1px solid #ccc;
          border-radius: 50%;
        }
      }
    }
    .agreement {
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      input {
        margin-right: 3px;
      }
    }
    .tips {
      color: red;
      font-size: 12px;
      margin: 0;
      margin-left: 56px;
      text-align: left;
    }
    .toPhoneLogin {
      width: 100%;
    }
    .sendCode {
      width: 92%;
      margin-left: 10px;
    }
  }
  .wx-icon,
  .qq-icon {
    width: 40px;
    height: 40px;
  }
  .phone,
  .wx,
  .qq {
    position: absolute;
    top: 0;
    left: 8px;
    display: none;
  }
  .qq {
    left: 6px;
  }
  .bind {
    .title-text {
      text-align: center;
      margin-bottom: 40px;
    }
    /deep/ .el-form-item__content {
      margin-left: 95px;
    }
    .btn {
      display: flex;
      justify-content: space-around;
      margin: 40px 30px 0;
    }
  }
  /deep/ .el-dialog__header {
    padding: 0;
  }
  /deep/ .el-dialog__body {
    width: 508px;
    height: 416px;
    padding: 50px 30px 125px;
    border-radius: 16px;
  }
  /deep/ .el-dialog {
    border-radius: 16px;
  }
</style>