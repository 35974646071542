var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "el-color-hue-slider",
      class: { "is-vertical": _vm.vertical }
    },
    [
      _c("div", {
        ref: "bar",
        staticClass: "el-color-hue-slider__bar",
        on: { click: _vm.handleClick }
      }),
      _c("div", {
        ref: "thumb",
        staticClass: "el-color-hue-slider__thumb",
        style: {
          left: _vm.thumbLeft + "px",
          top: _vm.thumbTop + "px"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }